import {Autocomplete, MenuItem, Stack, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import Dropdown from "./FormComponents/Dropdown";
import {providerSectors} from "placementt-core";

export function SectorSelector({sector, subsector, required}:{sector?: string, subsector?: string, required?: boolean}) {
    const [query, setQuery] = useState("");

    const [fSector, setFSector] = useState(sector || "");
    const [sectorOptions, setSectorOptions] = useState<string[]>(Object.keys(providerSectors));

    const [fSubsector, setFSubsector] = useState(subsector || "");
    const [subsectorOptions, setSubsectorOptions] = useState<string[]>([]);

    useEffect(() => {
        if (!fSector) {
            setFSubsector("");
        }
        setSubsectorOptions(providerSectors[fSector as keyof typeof providerSectors] || []);
    }, [fSector]);

    useEffect(() => {
        if (!query) {
            setSectorOptions(Object.keys(providerSectors));
            return;
        }
        const lowerQuery = query.toLowerCase();
        setSectorOptions(
            [...Object.keys(providerSectors).filter((sector) => sector.toLowerCase().includes(lowerQuery)),
                ...Object.entries(providerSectors).reduce((acc, [section, subsections]) => {
                    acc.push(...subsections.filter((subsection) => `${section}: ${subsection}`.toLowerCase().includes(lowerQuery)).map((subsection) => `${section}: ${subsection}`));
                    return acc;
                }, [] as string[])]
        );
    }, [query]);

    return (
        <Stack spacing={2} textAlign={"left"}>
            <Autocomplete
                disablePortal
                options={sectorOptions}
                renderInput={(params) => <TextField required={required} name='sector' onChange={(e) => setQuery(e.target.value)} {...params} label="Sector"/>}
                value={fSector}
                onChange={(e, value) => {
                    if (!value) {
                        setQuery("");
                    }
                    if (value?.includes(": ")) {
                        setFSector(value.split(": ")[0]);
                        setFSubsector(value.split(": ")[1]);
                    } else {
                        setFSector(value || "");
                    }
                }}
            />
            <Dropdown id="subsector-dropdown" required={required} label='Subsector' key='subsector' name='subsector' value={fSubsector} onChange={(e) => setFSubsector(e.target.value)}>
                {subsectorOptions.map((option) => <MenuItem key={`subsection ${option}`} value={option}>{option}</MenuItem>)}
            </Dropdown>
        </Stack>
    );
}
