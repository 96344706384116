/* eslint-disable @typescript-eslint/no-unused-vars */
import {Button, CardContent, CardHeader, Checkbox, Chip, FormControlLabel, FormGroup, Grid, List, MenuItem, Stack, Typography} from "@mui/material";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {styled} from "@mui/system";
import {CustomFormSchema, CustomFormSchemaField, CustomFormSchemaSection, PRIMARY_COLOUR, camelCase, editNestedObject, getUniqueId} from "placementt-core";
import {OrganisationContext} from "../App";
import TutorialPage from "../Pages/Shared/TutorialPage";
import InputGroup from "./FormComponents/InputGroup";
import IconButtonPop from "./IconButtonPop";
import {ClearRounded} from "@mui/icons-material";
import {Popup} from "./Popup";
import Dropdown from "./FormComponents/Dropdown";
import Form, {FormButton} from "./Form";
import Card from "./Card";
import {Helmet} from "react-helmet";


const FieldContainer = styled("div")`
    position:relative;
    border: 1px solid lightgrey;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 15px;
`;

export const renderFieldJSON = {
    short: <ShortConfig/>,
    long: <LongConfig/>,
    number: <NumberConfig/>,
    date: <DateConfig/>,
    dropdown: <DropdownConfig/>,
    checkbox: <CheckboxConfig/>,
    rating: <RatingConfig/>,
};


type Params = {
    formData?: CustomFormSchema,
    onSubmit?: (data: CustomFormSchema) => void
    id?: string,
    noTitle?: boolean,
    noSections?: boolean,
    title?: string
}

export default function CustomFormBuilder({formData, onSubmit, id, noTitle, noSections, title}:Params) {
    const [data, setData] = useState<CustomFormSchema>(noSections ? {form: [{id: 1, title: "", fields: []}]} : {});
    const [importFormPopup, setImportFormPopup] = useState(false);

    useEffect(() => {
        console.log("render", formData);
        if (!formData || !Object.keys(formData).length) return;
        setData(formData);
    }, [formData]);

    const org = useContext(OrganisationContext);

    const addSection = () => {
        setData((p) => ({...p, form: [...(p.form ? p.form : []) as CustomFormSchemaSection[], {id: getUniqueId(p?.form || {}), title: "", fields: [] as CustomFormSchemaField[]}] as unknown as CustomFormSchema} as CustomFormSchema));
    };
    const addField = (section: number) => {
        setData((p) => {
            const form = [...(p.form || [])];
            form[section] = {...form[section], fields: [...(form[section].fields ? form[section].fields : []), {id: getUniqueId(form[section]?.fields)}] as CustomFormSchemaField[]};
            return ({...p, form: [...form]} as CustomFormSchema);
        });
    };
    /*
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        setData((p) => {
            const nodes = [...(p.form || [])];
            const location = result.draggableId.split("_");

            let nodeFields: CustomFormSchemaField[]|undefined;

            // If a field has been moved, extract fields at specified index
            if (result.type !== "sections") {
                nodeFields = nodes[parseInt(location[0])].fields;
            }
            // Reorder list
            const [removed] = nodes.splice(result.source.index, 1);
            if (!result.destination) return p;
            nodes.splice(result.destination.index, 0, removed);

            // Insert back into list
            if (result.type !== "sections") {
                const form = [...(p.form || [])];
                if (!nodeFields) return ({...p, form: nodes}) as CustomFormSchema;
                form[parseInt(location[0])] = {...form[parseInt(location[0])], fields: nodeFields};
                return ({...p, form: [...form]}) as CustomFormSchema;
            }
            return {...p, form: nodes} as CustomFormSchema;
        });
    };
*/
    console.log("FORMDATA", data);
    return (
        <Grid container key={id} width={"100%"}>
            <Helmet title={`${id ? "Edit" : "Create"} Custom Form | Placementt`}/>
            <TutorialPage pageId="formBuilder"/>
            <Grid item md={12} lg={6} sx={{position: "relative"}}>
                <Form button={false} functionType="sync" onSubmit={() => onSubmit && onSubmit(data)}>
                    <Stack sx={{width: "600px", maxWidth: "100%"}}>
                        <Typography variant='h5'>{title || "Custom form builder"}</Typography>
                        {noTitle || <Card title={"Basic details"}>
                            <Stack spacing={2}>
                                <InputGroup label="Name" name="name" value={data?.name} onChange={(e: ChangeEvent<HTMLInputElement>) => setData((prev) => ({...prev, name: e.target.value}))} required={true}/>
                                <InputGroup label="Description" name="description" value={data?.description} onChange={(e: ChangeEvent<HTMLInputElement>) => setData((prev) => ({...prev, description: e.target.value}))}/>
                            </Stack>
                        </Card>}
                        {data?.form && data.form.map((section, index) =>

                            <Card sx={{width: "100%", margin: "20px auto"}}>
                                <CardHeader title={noSections ? undefined : [
                                    <InputGroup label={"Title"} placeholder={"Add if you have multiple sections."} value={section.title} onChange={(e: ChangeEvent<HTMLInputElement>) => setData((prev) => (editNestedObject(["form", index, "title"], prev, e.target.value)) as CustomFormSchema)} sx={{width: "100%"}}/>,
                                    <IconButtonPop responsive={false} onClick={() => setData((prev) => (editNestedObject(["form", index], prev, undefined)) as CustomFormSchema)} title='Remove'><ClearRounded/></IconButtonPop>]}/>
                                <CardContent sx={{display: "flex", flexDirection: "column"}}>
                                    {noSections || <InputGroup label={"Description"} placeholder={"Optional: Describe this form section."} value={section.description} onChange={(e: ChangeEvent<HTMLInputElement>) => setData((prev) => (editNestedObject(["form", index, "description"], prev, e.target.value)) as CustomFormSchema)} sx={{width: "100%", maxWidth: "100%"}}/>}
                                    <Grid container>
                                        <Grid item xs={12} margin={"auto"}>
                                            {<List>
                                                {section.fields && section.fields.length ? section.fields.map((field, eIndex) => {
                                                    return (<FieldContainer>
                                                        <div style={{float: "right"}}>
                                                            <IconButtonPop responsive={false} onClick={() => setData((prev) => (editNestedObject(["form", index, "fields", eIndex], prev, undefined)) as CustomFormSchema)} title='Remove'><ClearRounded/></IconButtonPop>
                                                        </div>
                                                        <Field field={field} onChange={(name, value) => setData((prev) => (editNestedObject(["form", index, "fields", eIndex, ...(Array.isArray(name) ? name : [name])], prev, value)) as CustomFormSchema)}/>
                                                    </FieldContainer>

                                                    );
                                                }) : <Typography>Click below to add a field.</Typography>}
                                            </List>}
                                            <Button onClick={() => addField(index)}>Add field</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        )}
                        <Card>
                            <Stack sx={{justifyContent: noSections ? "center" : "space-between"}} direction="row" spacing={2}>
                                {noSections || <Button onClick={addSection}>Add section</Button>}
                                <Button onClick={() => setImportFormPopup(true)}>Import form</Button>
                                <FormButton noFlexGrow variant='contained' text="Submit"/>
                            </Stack>
                        </Card>
                    </Stack>
                </Form>

            </Grid>
            <Grid item md={12} lg={6}>
                <Stack sx={{width: "600px", maxWidth: "100%"}}>
                    <Typography variant='h5' mt={1} style={{width: "600px", maxWidth: "100%", margin: "auto"}}>Live preview</Typography>
                    {noTitle || <Card title={data?.name}>{data?.description}</Card>}
                    {data?.form && data?.form.length ? data.form.map((section) => {
                        return (

                            <Card title={section.title}>
                                <Typography p={1} fontSize={18}>{section.description}</Typography>
                                <Grid container>
                                    <Grid item xs={12} margin={"auto"}>
                                        <Grid container>
                                            {section.fields && section.fields.map((field) => {
                                                return React.isValidElement(renderFieldJSON[field.type]) && React.cloneElement(renderFieldJSON[field.type], {field: field});
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>

                        );
                    }) : <Card sx={{width: "100%", margin: "auto", p: 2}}><Typography textAlign={"center"}>Add a form section to get started.</Typography></Card>}
                </Stack>
            </Grid>
            <Popup open={importFormPopup} onClose={() => setImportFormPopup(false)} fullWidth title={"Import form"}>
                <Typography>Import an existing form.</Typography>
                <Form functionType="sync" onSubmit={(e) => {
                    setData({...org.forms[e.form as string], id: e.form});
                    setImportFormPopup(false);
                }}>
                    <Dropdown name={"form"} label="Select form" required>
                        {Object.entries((org.forms || {}) as {[k: string]: CustomFormSchema}).filter(([fId]) => fId !== id).map(([id, form]) => <MenuItem value={id}>{form?.name}</MenuItem>)}
                    </Dropdown>
                </Form>
            </Popup>
        </Grid>

    );
}

function Field({field, onChange}:{field?: CustomFormSchemaField, onChange?: (location: unknown|unknown[], value: unknown) => void}) {
    if (!field || !onChange) return null;
    return (
        <Grid container sx={{paddingRight: "10px"}}>
            <Grid item xs={12} md={6}>
                <InputGroup key={`${field.id}Label`} required name={"label"} label={"Question"} value={field.label} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange("label", e.target.value)}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <Dropdown key={`${field.id}Type`} name={"type"} label={"Question type"} required value={field.type} onChange={(e) => {
                    onChange("type", e.target.value); onChange("extra", {});
                }}>
                    <MenuItem value={"short"}>Short text</MenuItem>
                    <MenuItem value={"long"}>Long text</MenuItem>
                    <MenuItem value={"number"}>Number</MenuItem>
                    <MenuItem value={"date"}>Date</MenuItem>
                    <MenuItem value={"dropdown"}>Dropdown</MenuItem>
                    <MenuItem value={"checkbox"}>Checkbox</MenuItem>
                    <MenuItem value={"rating"}>Rating</MenuItem>
                </Dropdown>
            </Grid>
            {/* false && <Grid item xs={12} md={4}>
                <InputGroup key={`${field.id}Width`} name={"width"} label={"Width"} value={field.width } type={"number"} min={1} max={12} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange("width", parseInt(e.target.value) || undefined)}/>
            </Grid> */}
            <Grid item xs={12} md={6}>
                <label><Checkbox key={`${field.id}Required`} name={"required"} checked={field.required} onChange={(e) => onChange("required", e.target.checked)}/>Required</label>
            </Grid>
            {React.isValidElement(renderFieldJSON[field.type]) && React.cloneElement(renderFieldJSON[field.type], {field: field, onChange: (name:string, value:unknown) => onChange(["extra", name], value)})}
        </Grid>
    );
}


export function ShortConfig({field, onChange, value, disabled, readOnly}:{field?: CustomFormSchemaField, onChange?: (location: unknown|unknown[], value: unknown) => void, value?: string, disabled?: boolean, readOnly?: boolean}) {
    if (!field) return null;

    if (onChange) return null;

    return (
        <>
            <Grid item xs={12} md={field.width || 12}>
                <Typography mb={2}>{field.label}{field.required ? " *" : ""}</Typography>
                <InputGroup disabled={disabled} readOnly={readOnly} key={`${field.id}`} name={camelCase(field.label)} label={"Type answer"} value={value || field.extra?.default} required={field.required}/>
            </Grid>
        </>
    );
}

export function LongConfig({field, onChange, value, disabled, readOnly}:{field?: CustomFormSchemaField, onChange?: (location: unknown|unknown[], value: unknown) => void, value?: string, disabled?: boolean, readOnly?: boolean}) {
    if (!field) return null;

    if (onChange) {
        return (
            <>
                <Grid item xs={12} md={4}>
                    <InputGroup key={`${field.id}MinRows`} name={"minRows"} label={"minRows"} value={field.extra?.minRows || 0} type={"number"} min={1} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange("minRows", e.target.value)}/>
                </Grid>
            </>
        );
    }

    return (
        <>
            <Grid item xs={12} md={field.width || 12}>
                <Typography mb={2}>{field.label}{field.required ? " *" : ""}</Typography>
                <InputGroup disabled={disabled} readOnly={readOnly} multiline minRows={field?.extra.minRows} key={`${field.id}`} name={camelCase(field.label)} label={"Type answer"} value={value || field.extra?.default} required={field.required}/>
            </Grid>
        </>
    );
}

export function NumberConfig({field, onChange, value, disabled, readOnly}:{field?: CustomFormSchemaField, onChange?: (location: unknown|unknown[], value: unknown) => void, value?: string, disabled?: boolean, readOnly?: boolean}) {
    if (!field) return null;

    if (onChange) {
        return (
            <>
                <Grid item xs={12} md={4}>
                    <InputGroup key={`${field.id}Min`} name={"min"} label={"Minimum number"} value={field.extra?.min} type={"number"} max={field.extra?.max} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange("min", e.target.value)}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputGroup key={`${field.id}Min`} name={"min"} label={"Maximum number"} value={field.extra?.max} type={"number"} min={field.extra?.min} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange("max", e.target.value)}/>
                </Grid>
            </>
        );
    }

    return (
        <>
            <Grid item xs={12} md={field.width || 12}>
                <Typography mb={2}>{field.label}{field.required ? " *" : ""}</Typography>
                <InputGroup disabled={disabled} readOnly={readOnly} key={`${field.id}`} type={"number"} name={camelCase(field.label)} label={"Enter number"} min={field.extra?.min} max={field.extra?.max} value={value || field.extra?.default} required={field.required}/>
            </Grid>
        </>
    );
}

export function DateConfig({field, onChange, value, disabled, readOnly}:{field?: CustomFormSchemaField, onChange?: (location: unknown|unknown[], value: unknown) => void, value?: string, disabled?: boolean, readOnly?: boolean}) {
    if (!field) return null;

    if (onChange) {
        return (
            <>
                <Grid item xs={12} md={4}>
                    <InputGroup InputLabelProps={{shrink: true}} label={"Minimum date"} key={`${field.id}Min`} name={"min"} value={field.extra?.min} type={"date"} max={field.extra?.min} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange("min", e.target.value)}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputGroup InputLabelProps={{shrink: true}} label={"Maximum date"} key={`${field.id}Max`} name={"max"} value={field.extra?.max} type={"date"} min={field.extra?.max} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange("max", e.target.value)}/>
                </Grid>
            </>
        );
    }

    return (
        <>
            <Grid item xs={12} md={field.width || 12}>
                <Typography mb={2}>{field.label}{field.required ? " *" : ""}</Typography>
                <InputGroup disabled={disabled} readOnly={readOnly} InputLabelProps={{shrink: true}} label={"Select date"} required={field.required} key={`${field.id}Dropdown`} name={camelCase(field.label)} value={value || field?.extra.default} type={"date"} min={field.extra?.min || 0} max={field.extra?.max || 0}/>
            </Grid>
        </>
    );
}

export function DropdownConfig({field, onChange, value, disabled, readOnly}:{field?: CustomFormSchemaField, onChange?: (location: unknown|unknown[], value: unknown) => void, value?: string, disabled?: boolean, readOnly?: boolean}) {
    const [input, setInput] = useState("");

    const handleKeystroke = (e: KeyboardEvent) => {
        if (!["Enter", "Backspace"].includes(e.key)) {
            return;
        }

        if (e.key === "Backspace") {
            if (input.length > 0) {
                return;
            }
            deleteOption();
        }
        if (e.key === "Enter") {
            const trimmedInput = input.trim();
            if (trimmedInput.length === 0 || field?.extra.options?.includes(trimmedInput)) {
                setInput("");
                return;
            }
            onChange && onChange("options", [...(field?.extra.options || []), trimmedInput]);
            setInput("");
        }
    };

    const deleteOption = (name?: string) => {
        const spreadOptions = [...(field?.extra.options || [])];

        const newName = name || spreadOptions[spreadOptions.length - 1];
        onChange && onChange("options", spreadOptions.filter((option) => option !== newName));
    };
    if (!field) return null;

    if (onChange) {
        return (
            <>
                <Grid item xs={12} md={8}>
                    <InputGroup
                        label={"Options"}
                        onKeyDown={handleKeystroke}
                        name={"options"}
                        value={input}
                        onChange={(e:ChangeEvent<HTMLInputElement>) => {
                            setInput(e.target.value);
                        }}

                        InputProps={{
                            startAdornment: field?.extra.options && field?.extra.options.map((option) => {
                                return (
                                    <Chip
                                        key={option}
                                        tabIndex={-1}
                                        label={option}
                                        color={"primary"}
                                        onDelete={() => deleteOption(option)}
                                        sx={{marginRight: "5px"}}
                                    />
                                );
                            }),
                        }}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Dropdown key={`${field.id}Default`} name={"default"} label={"Default"} value={field.extra.default as string | string[]} onChange={(e) => {
                        onChange("default", e.target.value);
                    }}>
                        {field?.extra.options && field?.extra.options.map((option) => {
                            return (<MenuItem value={option}>{option}</MenuItem>);
                        })}
                    </Dropdown>
                </Grid>
            </>
        );
    }

    // Fix readOnly on render
    return (
        <>
            <Grid item xs={12} md={field.width || 12}>
                <Typography mb={2}>{field.label}{field.required ? " *" : ""}</Typography>
                <Dropdown disabled={disabled || readOnly} required={field.required} key={`${field.id}Dropdown`} name={camelCase(field.label)} label={"Select option"} value={value || field.extra.default as string | string[] | undefined}>
                    {field?.extra.options && field?.extra.options.map((option) => {
                        return (<MenuItem value={option}>{option}</MenuItem>);
                    })}
                </Dropdown>
            </Grid>
        </>
    );
}

export function CheckboxConfig({field, onChange, value, disabled, readOnly}:{field?: CustomFormSchemaField, onChange?: (location: unknown|unknown[], value: unknown) => void, value?: string, disabled?: boolean, readOnly?: boolean}) {
    if (!field) return null;

    if (onChange) {
        return (
            <>
                <Grid item xs={12} md={4}>
                    <label><Checkbox key={`${field.id}Default`} name={"default"} checked={field?.extra?.default as boolean} onChange={(e) => onChange("default", e.target.checked)}/>Default checked</label>
                </Grid>
            </>
        );
    }

    return (
        <Grid item xs={12} md={field.width || 12}>
            <FormGroup>
                <FormControlLabel control={<Checkbox disabled={disabled} readOnly={readOnly} key={`${field.id}Default`} name={camelCase(field.label)} defaultChecked={(value === undefined ? field?.extra?.default : value) as boolean}/>} label={field.label} />
            </FormGroup>
        </Grid>
    );
}

export function RatingConfig({field, onChange, value, disabled, readOnly}:{field?: CustomFormSchemaField, onChange?: (location: unknown|unknown[], value: unknown) => void, value?: string, disabled?: boolean, readOnly?: boolean}) {
    const [rating, setRating] = useState<number|undefined>();
    const [hover, setHover] = useState<number|undefined>();
    const [error, setError] = useState(false);

    useEffect(() => {
        setRating(value ? parseInt(value) : undefined);
    }, [value]);

    if (!field) return null;

    if (onChange) {
        return (
            <>
                <Grid item xs={12} md={4}>
                    <InputGroup key={`${field.id}Levels`} name={"levels"} value={field?.extra?.levels} required label={"Levels"} min={0} max={10} type={"number"} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange("levels", parseInt(e.target.value))}/>
                </Grid>
            </>
        );
    }

    return (
        <>
            <Grid item xs={12}>
                <input hidden disabled={disabled} readOnly={readOnly} key={`${field.id}`} name={camelCase(field.label)} value={rating} onChange={(e) => {
                    console.log("ee", parseInt(e.target.value)); setError(() => field.required && !parseInt(e.target.value));
                }} required={field.required}/>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <Typography color={error ? "error" : "inherit"} sx={{marginTop: "5px"}}>{field.label}{field.required ? " *" : ""}</Typography>
                    {[...Array(field.extra.levels || 1)].map((elementInArray, i) => (
                        <div key={i} className='fas fa-star' onClick={() => (disabled || readOnly) || setRating((r) => r === i+1 ? undefined : i+1)} style={{color: (hover || 0) < (rating || 0) ? i < (hover || 0) ? "#b380f9ff" : i < (rating || 0) ? PRIMARY_COLOUR : "lightgrey" : i < (rating || 0) ? PRIMARY_COLOUR : i < (hover || 0) ? "#b380f9ff" : "lightgrey", fontSize: "25px", transition: "all 150ms ease-in-out"}} onMouseEnter={() => setHover(i+1)} onMouseLeave={() => setHover(undefined)}/>
                    ))}
                </Stack>
            </Grid>
        </>
    );
}
