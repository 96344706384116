import {
    Route,
    Routes,
    Link,
    useLocation,
    Navigate,
} from "react-router-dom";

import CookieConsent from "react-cookie-consent";
import Institutes from "./Pages/Institutes/Institutes";
import Admin from "./Pages/Admin/Admin";
import {createContext, useEffect, useRef, useState} from "react";
import NavBar, {NAVBARHEIGHT} from "./Components/NavBar/NavBar";
import Register from "./Pages/Public/Register";
import PublicRoutes from "./Pages/Public/Routes";
import Login from "./Pages/Public/Login";
import {Box, Grid, Stack, Typography} from "@mui/material";
import ResetPassword from "./Pages/Public/ResetPassword";
import {FlexBox} from "./Util/styledComponents";
import Students from "./Pages/Students/Students";
import ActivateProduct from "./Pages/Public/ActivateProduct";
import Providers from "./Pages/Providers/Providers";
import {PublicMap} from "./Pages/Public/PublicMap";
import {PRIMARY_COLOUR, UserData, authListener} from "placementt-core";
import Help from "./Pages/Public/Help";
import DeleteAccount from "./Pages/Public/HelpCenter/DeleteAccount";
import styled from "styled-components";
import RequestUserAcces from "./Pages/Public/RequestUserAccess";
import ProviderPage from "./Pages/Public/ProviderPage";

export const UserContext = createContext<UserData>({
    details: {
        forename: "",
        surname: "",
        parentEmail: "",
    },
    renewalMonth: 0,
    email: "",
    status: "active",
    oId: "",
    id: "",
    product: "students",
    userType: "Students",
    created: "",
    activated: "",
    staffRoles: [],
    cohortRequests: {},
    cohorts: {},
    activationCode: 0,
    algoliaKey: "",
});

export const OrganisationContext = createContext<any>(undefined);

const SiteMapWrapper = styled(Box)`
border-top: 1px solid lightgrey;
padding: 50px;
margin-top: 64px;
display: flex;
justify-content: center;

a {
    color: #919191
}

p {
    font-weight: 600;
}
`;

export default function App() {
    const location = useLocation();

    const bottomLinks = () => {
        const path = location.pathname.split("/");
        if (["institutes", "providers", "students", "admin"].includes(path[1])) {
            return;
        }

        return (
            <SiteMapWrapper>
                <Grid container sx={{maxWidth: "700px"}}>
                    <Grid item xs={12} sm={4}>
                        <Stack alignItems={"center"}>
                            <Typography>Products</Typography>
                            <Link to={"/institute"}>Institutes</Link>
                            <Link to={"/provider"}>Providers</Link>
                            <Link to={"/student"}>Students</Link>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Stack alignItems={"center"}>
                            <Typography>Navigate</Typography>
                            <Link to={"/"}>Home</Link>
                            <Link to={"/register"}>Register</Link>
                            <Link to={"/login"}>Login</Link>
                            <Link to={"/help"}>Help center</Link>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Stack alignItems={"center"}>
                            <Typography>Company</Typography>
                            <Link to={"/terms"}>Terms and Conditions</Link>
                            <Link to={"/privacy"}>Privacy Policy</Link>
                        </Stack>
                    </Grid>
                </Grid>
            </SiteMapWrapper>);
    };

    const [user, setUser] = useState<UserData|false|null>(null);

    const containerRef = useRef<HTMLDivElement>();

    useEffect(() => {
        return authListener(setUser);
    }, []);

    useEffect(() => {
        containerRef.current?.scrollTo(0, 0);
    }, [location]);

    if (user === null) {
        return null;
    }


    const product = user && user?.product;

    console.log("Render app");

    const routes = (
        <FlexBox flexDirection={"column"} flex={1}>
            <NavBar/>
            <Box id="flex" sx={{scrollBehavior: "smooth"}} ref={containerRef} mt={NAVBARHEIGHT} overflow={"auto"}>
                <Routes>
                    <Route path="/institutes/*" element={product === "institutes" ? <Institutes /> : <Navigate to={"/login"} />} />
                    <Route path="/students/*" element={product === "students" ? <Students/> : <Navigate to={"/login"} />} />
                    <Route path="/providers/*" element={product === "providers" ? <Providers /> : <Navigate to={"/login"} />} />
                    <Route path="/admin/*" element={product === "admin" ? <Admin /> : <Navigate to={"/login"} />} />

                    <Route path="/reset/:type/:uid" element={<ResetPassword />}/>
                    <Route path="/reset/:type" element={<ResetPassword />} />
                    <Route path="/activate/:uid" element={<ActivateProduct />}/>
                    <Route path="/help" element={<Help/>}/>
                    <Route path="/help/delete-account" element={<DeleteAccount/>}/>

                    <Route path="/map" element={<PublicMap/>} />

                    <Route path="register" element={product ? <Navigate to={`/${product}/home`} /> : <Register />} />
                    <Route path="register/:product" element={product ? <Navigate to={`/${product}/home`} /> : <Register />} />
                    <Route path="register/:product/:id" element={product ? <Navigate to={`/${product}/home`} /> : <Register />} />
                    <Route path="register/request/:oId/:key" element={product ? <Navigate to={`/${product}/home`} /> : <RequestUserAcces />} />

                    <Route path="provider/:providerId" element={<ProviderPage/>}/>
                    <Route path="provider/:providerId/:viewType/:itemId" element={<ProviderPage/>}/>
                    <Route path="provider/:providerId/:viewType/:itemId/:apply" element={<ProviderPage/>}/>

                    <Route path="login" element={product ? <Navigate to={`/${product}/home`} /> : <Login />} />
                    <Route path='*' element={<PublicRoutes />} />
                </Routes>
                {bottomLinks()}
            </Box>
            <CookieConsent style={{textAlign: "left", background: "white", color: "black", boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 5px 0px"}} buttonStyle={{background: PRIMARY_COLOUR, width: "max-content", padding: "10px", borderRadius: "20px", color: "white"}}>
                This website uses cookies to enhance the user experience.
            </CookieConsent>
        </FlexBox>
    );

    if (!user) {
        return routes;
    }
    return (
        <UserContext.Provider value={user}>
            {routes}
        </UserContext.Provider>
    );
}
