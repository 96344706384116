import {PRIMARY_COLOUR, TaskQueryReturnObject, dismissTip, getTips} from "placementt-core";
import {useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../App";
import {Popup} from "./Popup";
import FilterList from "./FilterList";
import {renderTipsAndTasksList} from "./Tasks";
import Card from "./Card";
import {useNavigate} from "react-router-dom";
import {Box, Button, Link, Stack, Typography} from "@mui/material";
import {ArrowForward} from "@mui/icons-material";


export default function TipsBox() {
    const user = useContext(UserContext);
    const [tips, setTips] = useState<TaskQueryReturnObject[]>();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const institute = useContext(OrganisationContext);

    useEffect(() => {
        getTips(user, institute.details).then(setTips);
    }, []);

    if (!tips) return null;

    const primaryTip = Object.values(tips)[0];

    if (!primaryTip) return null;

    return (
        <>
            <Card key={"tipsCard"} sx={{borderLeft: `5px solid ${PRIMARY_COLOUR}`}} title={
                <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                    <Typography color={PRIMARY_COLOUR}>Tips</Typography>
                    <Typography><Link color={PRIMARY_COLOUR} onClick={() => setOpen(true)} alignSelf={"end"}>View all</Link></Typography>
                </Stack>
            }>
                <Typography variant="h5">{primaryTip.title}</Typography>
                <Stack spacing={1}>
                    <Typography>{primaryTip?.message}</Typography>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        {primaryTip?.dismissible ? <Button variant="text" size="large" color={primaryTip.severity} onClick={() => dismissTip(user, tips[0]?.itemName).then(() => setTips((t) => t?.filter((tip) => tip?.itemName !== tips[0]?.itemName)))}>Dismiss</Button> : <Box/>}
                        {primaryTip?.link && <Button color={primaryTip.severity} onClick={() => navigate(primaryTip.link as string)} endIcon={<ArrowForward/>}>{primaryTip.buttonTitle}</Button>}
                    </Stack>
                </Stack>
            </Card>
            <Popup key={"tipsPopup"} title={"All tips"} open={open} onClose={() => setOpen(false)}>
                <FilterList data={Object.fromEntries(tips.map((t) => [t?.itemName, t]))} title="" card={false}>
                    {renderTipsAndTasksList(navigate, false, tips)}
                </FilterList>
            </Popup>
        </>
    );
}
