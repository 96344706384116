import {ContentCopy, Delete, Edit, Send} from "@mui/icons-material";
import {ListItemText, Grid, Button, List, ListItem, ListItemIcon, Typography, ListSubheader, Chip} from "@mui/material";
import {where} from "firebase/firestore";
import {getAccess, deleteStorageItem, executeCallable, checkPlacementConflicts, UserGroupData} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {useContext, useState, ChangeEvent} from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {UserContext, OrganisationContext} from "../../App";
import DeletePopup from "../../Components/DeletePopup";
import InputGroup from "../../Components/FormComponents/InputGroup";
import IconButtonPop from "../../Components/IconButtonPop";
import Page from "../../Components/Page";
import {Popup} from "../../Components/Popup";
import Card from "../../Components/Card";
import Files from "./Files";
import EmailTemplates from "./EmailTemplates";

const OverflowListItemText = styled(ListItemText)`
    & span {
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

export default function Resources() {
    const user = useContext(UserContext);
    const orgContext = useContext(OrganisationContext);
    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();

    const [delPopupActive, setDelPopupActive] = useState(false);
    const [delPopupError, setDelPopupError] = useState("");
    const [toDelete, setToDelete] = useState<{type:"forms"|"files", title: string, item: {name: string, id?: string}}>();
    const [addFormDialog, setAddFormDialog] = useState(false);

    const {addForms, editForms, deleteForms} = getAccess(user, "addForms", "editForms", "deleteForms");

    const deleteWorkflowItem = async (toDelete?: {type:"forms"|"files", title: string, item: {name: string, id?: string}}) => {
        if (!toDelete) return;
        const itemName = toDelete.type === "forms" ? toDelete.item.id : toDelete.item.name;

        const searchFilters = [
            where("oId", "==", user.oId),
            where("product", "==", user.product),
            where(toDelete.type, "array-contains", itemName),
        ];
        const cohortsUsingItem = await firebaseQuery.getCount(["cohorts"], searchFilters);
        const applicantWorkflowsUsingItem = await firebaseQuery.getCount(["applicantWorkflows"], searchFilters);

        [cohortsUsingItem, applicantWorkflowsUsingItem].forEach((items, i) => {
            const workflowsUsingForm = Object.keys(items || {}).length;
            if (workflowsUsingForm > 0) {
                console.log("Using!");
                throw new Error(`${workflowsUsingForm} ${i === 1 ? "applicant " : ""}workflow${workflowsUsingForm > 1 ? "s are " : " is "}`+
                    `using this ${toDelete.type === "forms" ? "form" : "file"}. Remove from each to continue. Workflows: `+
                    Object.entries(items || {}).map(([, v]) => ` ${v.name}`));
            }
        });


        if (toDelete.type === "files") {
            await deleteStorageItem(`${user.product}/${user.oId}/files/${itemName}`);
            setDelPopupActive(false);
            setToDelete(undefined);
            return;
        }

        if (itemName) {
            await firebaseQuery.delete([toDelete.type, itemName]);
        }
        setDelPopupActive(false);
        setToDelete(undefined);
    };

    /**
     * For this page to work, in the SideNavBar, the following entries must be added:
     *
     *  {
            route: "organisation/resources/f",
            element: <FormPage title='Add custom form' path='forms' editable={editForms}><CustomFormBuilder/></FormPage>,
        },
        {
            route: "organisation/resources/f/:id",
            element: <FormPage title='Edit custom form' path='forms' editable={editForms}><CustomFormBuilder/></FormPage>,
        },
     */

    return (
        <Page
            metaTitle="Placementt | Resources"
            grid>
            <Card grid md={4} sm={12} title={[
                "Forms",
                addForms && <Button onClick={() => {
                    setAddFormDialog(true);
                }}>Create</Button>,
            ]}>
                <List>
                    {orgContext.forms && Object.keys(orgContext.forms).map((key) => {
                        const form = orgContext.forms[key];
                        return (
                            <ListItem divider key={`form_${form.name}`}
                                button
                                onClick={() => {
                                    navigate(`/${user.product}/organisation/resources/f/`+form.id, {state: {formData: form}});
                                }}
                                secondaryAction={[
                                    addForms && editForms && <ListItemIcon><IconButtonPop responsive={false} title={"Copy"} onClick={(e) => {
                                        e.stopPropagation(); firebaseQuery.copy("forms", form.id, user.oId, user.product);
                                    }}><ContentCopy/></IconButtonPop></ListItemIcon>,
                                    deleteForms && <ListItemIcon><IconButtonPop title={"Delete"} responsive={false} onClick={(e) => {
                                        e.stopPropagation(); setDelPopupActive(true); setToDelete({type: "forms", title: "Form", item: form});
                                    }}><Delete/></IconButtonPop></ListItemIcon>,
                                ]}>
                                <OverflowListItemText primary={form.name} sx={{marginRight: "50px"}} />
                            </ListItem>
                        );
                    })}
                </List>
            </Card>
            {user.product !== "students" && <Files md={4} type="org"/>}
            {user.product !== "students" && user.product !== "providers" && <EmailTemplates md={4}/>}
            {user.product === "institutes" && <Grid item xs={12} md={4}>
                <UserGroups/>
            </Grid>}
            <DeletePopup
                open={delPopupActive}
                onClose={() => {
                    setDelPopupActive(false); setToDelete(undefined); setDelPopupError("");
                }}
                error={delPopupError}
                title={toDelete?.title?.toLowerCase() || ""}
                itemName={toDelete?.item?.name}
                // For deleting workflow form or attachment, need to make sure no workflow is using hte attachment. Just add a popup for this as nonessential.
                onDelete={() => deleteWorkflowItem(toDelete)}/>

            <FormUploadDialog open={addFormDialog} onClose={() => setAddFormDialog(false)}/>

        </Page>);
}


function FormUploadDialog({open, onClose}:{open: boolean, onClose: () => void}) {
    const [uploadType, setUploadType] = useState("");
    const [url, setUrl] = useState("");
    const [submitting, setSubmitting] = useState(false);

    const user = useContext(UserContext);
    const orgDetails = useContext(OrganisationContext).details;

    const submitUrl = async () => {
        setSubmitting(true);
        executeCallable("parseEternalForm", {oId: orgDetails.id, url: url, type: uploadType});
    };

    const navigate = useNavigate();

    return (
        <Popup title={"Create a form"} open={open} onClose={() => {
            setUrl(""); setUploadType(""); onClose();
        }}>
            <Grid container>
                <Grid item xs={12}>
                    <Button onClick={() => {
                        navigate(`/${user.product}/organisation/resources/f`);
                    }}>New</Button>
                    <Button disabled variant={uploadType === "Microsoft" ? "contained" : "outlined"} onClick={() => {
                        setUploadType("Microsoft");
                    }}>Microsoft form</Button>
                </Grid>
                <Grid item xs={12} sx={{opacity: uploadType ? 1 : 0, pointerEvents: uploadType ? "all" : " none", transition: "all 300ms ease-in-out"}}>
                    <InputGroup value={url} onChange={(e: ChangeEvent<HTMLInputElement>) => setUrl(e.target.value)} required label={"Form URL"}/>
                    <IconButtonPop disabled={submitting} sx={{position: "absolute", right: "25px"}} title={"Create"} onClick={submitUrl}><Send/></IconButtonPop>
                    {uploadType === "Microsoft" && <Typography>Form must be set to "Anyone can respond" during this action.</Typography>}
                </Grid>
            </Grid>
        </Popup>
    );
}


function UserGroups() {
    const [delPopupActive, setDelPopupActive] = useState(false);
    const [deleteGroup, setDeleteGroup] = useState<UserGroupData>();
    const [delPopupError, setDelPopupError] = useState("");

    const navigate = useNavigate();
    const firebaseQuery = new FirebaseQuery();

    const instituteData = useContext(OrganisationContext);
    const user = useContext(UserContext);

    const institute = instituteData.details;
    const userGroups = instituteData.userGroups as {[key: string]: UserGroupData};

    // Manage user groups permissions
    const {addStaffGroups, editStaffGroups, deleteStaffGroups} = getAccess(user, "addStaffGroups", "editStaffGroups", "deleteStaffGroups");

    if (Object.keys(userGroups).length === 0) {
        return null;
    }

    const deleteUserGroup = async () => {
        if (!deleteGroup) return;
        const conflicts = await checkPlacementConflicts(institute.id, deleteGroup?.id);
        if (conflicts > 0) {
            setDelPopupError(`${conflicts} active placements are using this workflow. Complete these placements to delete workflow.`);
            return;
        }

        await firebaseQuery.delete(["userGroups", deleteGroup?.id]);
        setDelPopupActive(false);
        setDeleteGroup(undefined);
    };


    const renderUserGroupList = (type: "Students"|"Staff") => {
        const groups = Object.entries(userGroups).filter(([, data]) => data.template === type && !data.default);
        const defGroup = Object.entries(userGroups).find(([, data]) => data.template === type && data.default);
        return (
            <li key={`section-${type}`}>
                <ul style={{padding: 0}}>
                    <ListSubheader>{type}</ListSubheader>
                    {([defGroup, ...groups] as [[string, UserGroupData]]).filter(([k, v]) => k && v).map(([id, group]) =>
                        <ListItem button divider key={`group_${id}`}
                            onClick={() => {
                                navigate("/institutes/userGroups/"+id, {state: {group: group}});
                            }}
                            secondaryAction={[
                                group.default && <Chip color='primary' label={"Default"}/>,
                                editStaffGroups && <ListItemIcon><IconButtonPop responsive={false} title="Edit" onClick={(e) => {
                                    e.stopPropagation(); navigate("/institutes/userGroups/"+group.id, {state: {group: group}});
                                }}><Edit/></IconButtonPop></ListItemIcon>,
                                addStaffGroups && editStaffGroups && <ListItemIcon><IconButtonPop responsive={false} title="Copy" onClick={(e) => {
                                    e.stopPropagation(); firebaseQuery.copy("userGroups", group.id, user.oId, user.product);
                                }}><ContentCopy/></IconButtonPop></ListItemIcon>,
                                !group.default && deleteStaffGroups && <ListItemIcon><IconButtonPop responsive={false} title="Delete" onClick={(e) => {
                                    e.stopPropagation(); setDelPopupActive(true); setDeleteGroup(group);
                                }}><Delete/></IconButtonPop></ListItemIcon>,
                            ]}>
                            <ListItemText primary={group.name} />
                        </ListItem>
                    )}
                </ul>
            </li>
        );
    };

    return (
        <>
            <Card sx={{maxHeight: "300px"}} title={["User permissions", addStaffGroups && <Button onClick={() => {
                navigate("/institutes/userGroups");
            }}>Add group</Button>]}>
                <List>
                    {renderUserGroupList("Students")}
                    {renderUserGroupList("Staff")}
                </List>
            </Card>

            <DeletePopup
                open={delPopupActive}
                onClose={() => {
                    setDelPopupActive(false); setDelPopupError("");
                }}
                error={delPopupError}
                title={"the user group"}
                onDelete={() => deleteUserGroup()}
                itemName={deleteGroup?.name}/>
        </>
    );
}
