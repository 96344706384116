/* eslint-disable @typescript-eslint/no-unused-vars */
import {Grid, Stack, Typography, Collapse, Alert, Table, TableRow, TableCell, Radio, Divider, Icon} from "@mui/material";
import {BillingPackage, INSTITUTE_COLOUR, PRIMARY_COLOUR, PROVIDER_COLOUR, RegistrationData, STUDENT_COLOUR, camelCaseToNormal, capitaliseWords, executeCallable, register} from "placementt-core";
import {ChangeEvent, MutableRefObject, ReactNode, useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import Form from "../../Components/Form";
import Dropdown from "../../Components/FormComponents/Dropdown";
import InputGroup from "../../Components/FormComponents/InputGroup";
import {GradientText} from "../../Util/styledComponents";
import Preloader from "../../Components/Preloader";
import FadeInBox from "../../Components/FadeInBox";
import Page from "../../Components/Page";
import Card from "../../Components/Card";
import {useCheckMobileScreen} from "../../Util/util";
import instituteIcon from "../../Images/Institute_Icon.png";
import providerIcon from "../../Images/Business_Icon.png";
import studentIcon from "../../Images/Student_Icon.png";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {where} from "firebase/firestore";
import {CheckRounded, CloseRounded} from "@mui/icons-material";

type RegistrationStages = "selectType"|"institutesOne"|"institutesTwo"|"providersOne"|"providersTwo"|"providersThree"|"studentsOne"|"loading"|"invalid";

export default function Register() {
    const {product, id}:{product?: "institutes"|"providers"|"students", id?: string} = useParams();

    const [email, setEmail] = useState<string|undefined>(id ? "loading" : undefined);
    const [error, setError] = useState<string>();

    const [registrationStage, setRegistrationStage] = useState<RegistrationStages>("loading");

    const isMobile = useCheckMobileScreen();

    const navigate = useNavigate();
    const location = useLocation();

    const redirect = location.state?.redirect;

    console.log("REDIRECT", redirect);
    useEffect(() => {
        console.log("product", product, id);

        // if (!id) return;
        console.log("product", product);
        const getEmail = async () => {
            if (product && id) {
                setRegistrationStage("loading");
                executeCallable("registration-getUnregisteredUserEmail", {userType: product, uid: id}).then((res) => {
                    setEmail(res.data as string);
                    setRegistrationStage(`${product}One`);
                }).catch((e) => {
                    setRegistrationStage("invalid");
                    setError("Invalid referral link");
                });
            } else setRegistrationStage("selectType");
        };

        getEmail();
    }, []);


    const onRegister = async (data:{[key:string]: unknown}, type:"Institute"|"Provider"|"Student") => {
        const res = await register(({...(data as RegistrationData)}) as RegistrationData, type)
            .then((uid) => {
                navigate(`/activate/${uid}`);
                return {severity: "success", message: "Check your email for an activation email."};
            }).catch((error) => {
                console.error(error);
                throw error;
            });
        console.log("res", res);
        return res;
    };


    return (
        <Page
            metaTitle="Placementt | Register"
            metaDesc="Register a Placementt account"
            fullHeight>
            <FadeInBox visible={registrationStage === "loading"} card cardTitle={<GradientText variant={"h4"} sx={{width: "100%", textAlign: "center"}}>Register</GradientText>}>
                <Typography variant="h5">Checking registration link</Typography>
                <Preloader visible/>
            </FadeInBox>
            <FadeInBox visible={registrationStage === "invalid"} card cardTitle={<GradientText variant={"h4"} sx={{width: "100%", textAlign: "center"}}>Register</GradientText>}>
                <Typography variant="h5">Invalid registration link</Typography>
                <Alert severity="error">{error}</Alert>
            </FadeInBox>
            <FadeInBox visible={registrationStage === "selectType"} card cardTitle={<GradientText variant={"h4"} sx={{width: "100%", textAlign: "center"}}>Register</GradientText>}>
                <Typography>
                    Already have an account? <Link to="/login">Sign in</Link>
                </Typography>
                <Stack direction={isMobile ? "column" : "row"} mt={5}>
                    <Card sx={{flex: 1}} onClick={() => setRegistrationStage("providersOne")}>
                        <Stack sx={{alignItems: "center"}}>
                            <img src={providerIcon} style={{width: "100%"}} alt={"Business icon."}/>
                            <Typography variant="h6" color={PROVIDER_COLOUR}>Business</Typography>
                        </Stack>
                    </Card>
                    <Card sx={{flex: 1}} onClick={() => setRegistrationStage("studentsOne")}>
                        <Stack sx={{alignItems: "center"}}>
                            <img src={studentIcon} style={{width: "100%"}} alt={"Institute icon."}/>
                            <Typography variant="h6" color={STUDENT_COLOUR}>Student</Typography>
                        </Stack>
                    </Card>
                    <Card sx={{flex: 1}} onClick={() => setRegistrationStage("institutesOne")}>
                        <Stack sx={{alignItems: "center"}}>
                            <img src={instituteIcon} style={{width: "100%"}} alt={"Student icon."}/>
                            <Typography variant="h6" color={INSTITUTE_COLOUR}>Institute</Typography>
                        </Stack>
                    </Card>
                </Stack>
            </FadeInBox>
            <RegisterInstitute {...{onRegister}} email={product === "institutes" ? email : undefined} stage={registrationStage} onStageChange={(s) => setRegistrationStage(s)}/>
            <RegisterProvider {...{onRegister}} email={product === "providers" ? email : undefined} stage={registrationStage} onStageChange={(s) => setRegistrationStage(s)}/>
            <RegisterStudent {...{onRegister}} email={product === "students" ? email : undefined} stage={registrationStage} onStageChange={(s) => setRegistrationStage(s)}/>
        </Page>
    );
}


function RegisterInstitute({email, onRegister, stage, onStageChange}:{email?:string, stage: RegistrationStages, onStageChange: (s: RegistrationStages) => void, onRegister: (data:{[key:string]: unknown}, type:"Institute"|"Provider"|"Student") => void}) {
    const [formData, setFormData] = useState<{[key: string]: unknown}>({});

    const [passwordOne, setPasswordOne] = useState<number|string>(1);
    const [passwordTwo, setPasswordTwo] = useState<number|string>(1);

    const addressRef = useRef();
    const userRef = useRef();

    const stages:RegistrationStages[] = ["selectType", "institutesOne", "institutesTwo"];

    const forward = async (formDataSegment: {[key:string]: unknown}) => {
        if (passwordOne !== passwordTwo && stage === "institutesOne") {
            throw new Error("Passwords don't match.");
        }
        onStageChange(stages[stages.indexOf(stage) + 1]);
        setFormData({...formData, ...formDataSegment});
        return;
    };

    const back = () => {
        onStageChange(stages[stages.indexOf(stage) - 1]);
        (addressRef.current as {resetButton: () => void}|undefined)?.resetButton();
        (userRef.current as {resetButton: () => void}|undefined)?.resetButton();
    };

    return (
        <>
            <RegistrationCard title="Institute" key={"institutesOne"} visible={stage === "institutesOne"} onSubmit={forward} ref={addressRef} submitText={"Continue"} onBackPress={() => onStageChange("selectType")}>
                <Grid item xs={12} sm={6}>
                    <InputGroup required id="forename" label="Forename" name="first_name" value={formData?.first_name}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputGroup required id="surname" label="Surname" name="family_name" value={formData?.family_name}/>
                </Grid>
                <Grid item xs={12}>
                    <InputGroup required id="email" label="Email Address" type="email" name="email" value={formData?.email}/>
                </Grid>
                <Grid item xs={12}>
                    <InputGroup required name="passwordOne" autoComplete="new-password" value={formData?.passwordOne} onChange={(e: ChangeEvent<HTMLInputElement>) => setPasswordOne(e.target.value)} label="Password" type="password" id="password"/>
                </Grid>
                <Grid item xs={12}>
                    <InputGroup required name="password" autoComplete="new-password" label="Confirm password" value={formData?.password} onChange={(e: ChangeEvent<HTMLInputElement>) => setPasswordTwo(e.target.value)} type="password" id="passwordTwo"/>
                    <Typography variant="caption" sx={{opacity: 0.7}}>Passwords must be at least 8 characters in length and contain upper and lower case characters, a number and a special character.</Typography>
                </Grid>
            </RegistrationCard>

            <RegistrationCard title="Institute" key={"institutesTwo"} visible={stage === "institutesTwo"} onSubmit={(e) => onRegister({...e, ...formData}, "Institute")} ref={userRef} submitText={"Create account"} onBackPress={back}>
                <Grid item xs={12}>
                    <InputGroup required id="institute" label="Institute name" name="instituteName" value={formData?.instituteName}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputGroup required id="address-line1" label="Address line one" name="address-line1" value={formData?.["address-line1"]}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputGroup id="address-line2" label="Address line two" name="address-line2" value={formData?.["address-line2"]}/>
                </Grid>
                <Grid item xs={12}>
                    <InputGroup required id="locality" label="City" name="locality" value={formData?.locality}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputGroup required id="postcode" label="Postcode" name="postal_code" value={formData?.postal_code}/>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Dropdown required id="country" label="Country" name="country" value={formData?.country as string}/>
                </Grid>
            </RegistrationCard>
        </>
    );

    {/* <PageItem>
            <CardContent>
                <Form onSubmit={forward}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel id="package">Package</InputLabel>
                                <Select name="package" label="Package" defaultValue="">
                                    <MenuItem value="free">Free</MenuItem>
                                    <MenuItem value="basic">Basic</MenuItem>
                                    <MenuItem value="advanced">Advanced</MenuItem>
                                    <MenuItem value="professional">Professional</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Box sx={{marginTop:'10px'}} position={'relative'} width={'100%'}>
                        <MuiLink sx={{position:'absolute', top:'20%', left:0}} onClick={back}>Back</MuiLink>
                        <Button type="submit">Continue</Button>
                    </Box>
                </Form>
            </CardContent>
  </PageItem>*/}
}

function RegisterProvider({email, onRegister, stage, onStageChange}:{email?:string, stage: RegistrationStages, onStageChange: (s: RegistrationStages) => void, onRegister: (data:{[key:string]: unknown}, type:"Institute"|"Provider"|"Student") => void}) {
    const [formData, setFormData] = useState<{[key: string]: unknown}>({package: "providers-two"});

    const [passwordOne, setPasswordOne] = useState<number|string>(1);
    const [passwordTwo, setPasswordTwo] = useState<number|string>(1);

    const addressRef = useRef();
    const userRef = useRef();

    const firebaseQuery = new FirebaseQuery();

    const stages:RegistrationStages[] = ["selectType", "providersOne", "providersTwo", "providersThree"];

    const [billingPackages, setBillingPackages] = useState<{[key: string]: BillingPackage}>();
    const [features, setFeatures] = useState<string[]>();


    useEffect(() => {
        firebaseQuery.getDocsWhere("billing", where("product", "==", "providers")).then((items) => {
            console.log("PACKGAGES", items);
            const entries = Object.entries(items as {[key: string]: BillingPackage});

            entries.sort(([, a], [, b]) => a.priority - b.priority);

            const featureKeys: string[] = [];

            // Step 3: Iterate over the sorted entries and collect unique feature keys
            for (const [, billingPackage] of entries) {
                for (const featureKey of Object.keys(billingPackage.features)) {
                    if (!featureKeys.includes(featureKey)) {
                        featureKeys.push(featureKey);
                    }
                }
            }
            setFeatures(featureKeys);
            const sortedBillingPackages = Object.fromEntries(entries);

            setBillingPackages(sortedBillingPackages);
        });
    }, []);

    const forward = async (formDataSegment: {[key:string]: unknown}) => {
        if (passwordOne !== passwordTwo && stage === "providersOne") {
            throw new Error("Passwords don't match.");
        }
        onStageChange(stages[stages.indexOf(stage) + 1]);
        setFormData((f) => ({...f, ...formDataSegment}));
        return;
    };

    const back = () => {
        onStageChange(stages[stages.indexOf(stage) - 1]);
        (addressRef.current as {resetButton: () => void}|undefined)?.resetButton();
        (userRef.current as {resetButton: () => void}|undefined)?.resetButton();
    };

    console.log("form data", formData);

    return (
        <>
            <RegistrationCard title="Business" key={"providersOne"} visible={stage === "providersOne"} onSubmit={forward} ref={addressRef} submitText={"Continue"} onBackPress={() => onStageChange("selectType")}>
                <Grid item xs={12} sm={6}>
                    <InputGroup required id="forename" label="Forename" name="first_name" onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, first_name: e.target.value}))} value={formData?.first_name}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputGroup required id="surname" label="Surname" name="family_name" onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, family_name: e.target.value}))} value={formData?.family_name}/>
                </Grid>
                <Grid item xs={12}>
                    <InputGroup required id="email" label="Email Address" type="email" onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, email: e.target.value}))} name="email" value={formData?.email}/>
                </Grid>
                <Grid item xs={12}>
                    <InputGroup required name="passwordOne" autoComplete="new-password" value={formData?.passwordOne} onChange={(e: ChangeEvent<HTMLInputElement>) => setPasswordOne(e.target.value)} label="Password" type="password" id="password"/>
                </Grid>
                <Grid item xs={12}>
                    <InputGroup required name="password" autoComplete="new-password" label="Confirm password" value={formData?.password} onChange={(e: ChangeEvent<HTMLInputElement>) => setPasswordTwo(e.target.value)} type="password" id="passwordTwo"/>
                    <Typography variant="caption" sx={{opacity: 0.7}}>Passwords must be at least 8 characters in length and contain upper and lower case characters, a number and a special character.</Typography>
                </Grid>
            </RegistrationCard>

            <RegistrationCard title="Business" key={"providersTwo"} visible={stage === "providersTwo"} onSubmit={forward} ref={userRef} submitText={"Continue"} onBackPress={back}>
                <Grid item xs={12}>
                    <InputGroup required id="provider" label="Organisation name" onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, providerName: e.target.value}))} name="providerName" value={formData?.providerName}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputGroup required id="address-line1" label="Address line one" name="address-line1" onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, ["address-line1"]: e.target.value}))} value={formData?.["address-line1"]}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputGroup id="address-line2" label="Address line two" name="address-line2" onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, ["address-line2"]: e.target.value}))} value={formData?.["address-line2"]}/>
                </Grid>
                <Grid item xs={12}>
                    <InputGroup required id="locality" label="City" name="locality" onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, locality: e.target.value}))} value={formData?.locality}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputGroup required id="postcode" label="Postcode" name="postal_code" onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((f) => ({...f, postal_code: e.target.value}))} value={formData?.postal_code}/>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Dropdown required id="country" label="Country" name="country" onChange={(e) => setFormData((f) => ({...f, country: e.target.value}))} value={formData?.country as string}/>
                </Grid>
            </RegistrationCard>
            <RegistrationCard title="Business" key={"providersThree"} visible={stage === "providersThree"} onSubmit={(e) => onRegister({...e, ...formData}, "Provider")} submitText={"Create account"} onBackPress={back}>
                <Table>
                    <TableRow sx={{verticalAlign: "top"}}>
                        <TableCell sx={{fontWeight: "bold", color: PRIMARY_COLOUR, verticalAlign: "middle"}}>Feature</TableCell>
                        {Object.entries(billingPackages || {}).map(([k, v]) => <TableCell sx={{"padding": 2, "position": "relative", ":after": {position: "absolute", content: "''", left: 0, top: "25%", width: 0, height: "50%", borderLeft: "1px solid lightgrey"}, "fontWeight": "bold", "color": PRIMARY_COLOUR}} key={`${k}_title`}>
                            <Stack direction={"row"} alignItems={"center"}>
                                <Radio
                                    checked={formData.package === k}
                                    onChange={() => setFormData((f) => ({...f, package: k}))}
                                    name="radio-buttons"
                                    inputProps={{"aria-label": k}}
                                />
                                {v.name}
                            </Stack>
                            <Typography variant="caption" color={"CaptionText"}>{v.description}</Typography>
                        </TableCell>)}
                    </TableRow>
                    {features?.map((feature) =>
                        <TableRow>
                            <TableCell sx={{fontWeight: "bold"}}>{capitaliseWords(camelCaseToNormal(feature))}</TableCell>
                            {Object.entries(billingPackages || {}).map(([k, v]) => <TableCell sx={{textAlign: "center"}} key={`${k}_title`}>{(v.features[feature] === 1000000 ? "Unlimited" : v.features[feature] === true ? <Icon><CheckRounded color="success"/></Icon> : v.features[feature]) || <Icon><CloseRounded color="error"/></Icon>}</TableCell>)}
                        </TableRow>)}
                    <TableRow>
                        <TableCell colSpan={4}>
                            <Divider/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{fontWeight: "bold"}}>£ / User / Month</TableCell>
                        {Object.entries(billingPackages || {}).map(([k, v]) => <TableCell sx={{textAlign: "center", fontWeight: "bold"}} key={`${k}_staffCost`}>£{v.staffUnitCost}</TableCell>)}
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{fontWeight: "bold"}}>£ / Placement</TableCell>
                        {Object.entries(billingPackages || {}).map(([k, v]) => <TableCell sx={{textAlign: "center", fontWeight: "bold"}} key={`${k}_placementCost`}>£{v.placementUnitCost}</TableCell>)}
                    </TableRow>
                </Table>
            </RegistrationCard>
        </>
    );
}

export function RegisterStudent({email, onRegister, stage, onStageChange}:{email?:string, stage: RegistrationStages, onStageChange: (s: RegistrationStages) => void, onRegister: (data:{[key:string]: unknown}, type:"Institute"|"Provider"|"Student") => Promise<{[key: string]: unknown}>}) {
    const [passwordError, setPasswordError] = useState("");

    const [passwordOne, setPasswordOne] = useState<string|number>(1);
    const [passwordTwo, setPasswordTwo] = useState<string|number>(1);

    const onClickRegister = async (formData: {[key:string]: unknown}) => {
        if (passwordOne !== passwordTwo) {
            throw new Error("Passwords don't match.");
        }
        setPasswordError("");
        return await onRegister(formData, "Student");
    };
    return (
        <RegistrationCard title="Student" key={"studentsOne"} visible={stage === "studentsOne"} onSubmit={onClickRegister} onBackPress={() => onStageChange("selectType")} submitText={"Create account"}>
            <Grid item xs={12} sm={6}>
                <InputGroup required id="forename" label="Forename" name="first_name" />
            </Grid>
            <Grid item xs={12} sm={6}>
                <InputGroup required id="surname" label="Surname" name="family_name" />
            </Grid>
            <Grid item xs={12}>
                <InputGroup required id="email" value={email} label="Email Address" type="email" name="email" disabled={email}/>
            </Grid>
            <Grid item xs={12}>
                <InputGroup required name="passwordOne" autoComplete="new-password" onChange={(e: ChangeEvent<HTMLInputElement>) => setPasswordOne(e.target.value)} label="Password" type="password" id="password"/>
            </Grid>
            <Grid item xs={12}>
                <InputGroup required name="password" autoComplete="new-password" label="Confirm password" onChange={(e: ChangeEvent<HTMLInputElement>) => setPasswordTwo(e.target.value)} type="password" id="passwordTwo"/>
                <Typography variant="caption" sx={{opacity: 0.7}}>Passwords must be at least 8 characters in length and contain upper and lower case characters, a number and a special character.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={Boolean(passwordError)}>
                    <Alert style={{marginTop: "10px"}} severity="error">
                        {passwordError}
                    </Alert>
                </Collapse>
            </Grid>
        </RegistrationCard>
    );
}

const placementtColours = {
    Business: PROVIDER_COLOUR,
    Institute: INSTITUTE_COLOUR,
    Student: STUDENT_COLOUR,
};

function RegistrationCard({visible, onSubmit, submitText, children, ref, onBackPress, title}:{title: string, visible: boolean, ref?: MutableRefObject<undefined>, onBackPress?: () => void, onSubmit: (e: any) => void, submitText?: string, children: ReactNode}) {
    return (
        <FadeInBox visible={visible} card cardTitle={<Stack direction={"row"} justifyContent={"space-around"} spacing={1} margin={"auto"}><GradientText variant={"h4"} sx={{textAlign: "center"}}>Register - </GradientText><Typography variant="h4" sx={{display: "inline-block"}} color={placementtColours[title as keyof typeof placementtColours]}>{title}</Typography></Stack>}>
            <Typography>
                Already have an account? <Link to="/login">Sign in</Link>
            </Typography>
            <Form {...{onBackPress, submitText, onSubmit, ref}}>
                <Grid container spacing={2}>
                    {children}
                </Grid>
            </Form>
        </FadeInBox>
    );
}
