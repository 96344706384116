import {useContext, useEffect, useState} from "react";
import Card from "../../Components/Card";
import {UserContext} from "../../App";
import {FileItem, PRIMARY_COLOUR, arraysEqual, convertDate, getRandomNumber, storage, uploadFiles} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {where} from "firebase/firestore";
import {deleteObject, getDownloadURL, ref} from "firebase/storage";
import {Box, Button, Checkbox, List, ListItem, ListItemSecondaryAction, ListItemText, Stack, Typography} from "@mui/material";
import IconButtonPop from "../../Components/IconButtonPop";
import {Delete, RemoveRedEye} from "@mui/icons-material";
import FileUploaderPopup from "../../Components/FileUploaderPopup";
import DeletePopup from "../../Components/DeletePopup";
import {Popup} from "../../Components/Popup";


// Selectable turns this into a list of files that can be selected by the user. Multiple allows multiple selections.

export default function Files({type, popup, open, onClose, onSubmitSelection, md=6, xs=12, selectable, defaultSelected}:{type: "user"|"org", popup?: boolean, md?: number, xs?: number, open?: boolean, onClose?: () => void, onSubmitSelection?: (e: string[]) => any, selectable?: boolean|"multiple", defaultSelected?: string[]}) {
    const user = useContext(UserContext);

    const [files, setFiles] = useState<[string, FileItem][]>();
    const [addFilePopup, setAddFilePopup] = useState(false);
    const [deleteFile, setDeleteFile] = useState<string>();
    const [selected, setSelected] = useState<string[]>();

    const firebaseQuery = new FirebaseQuery();

    const oId = (user.product === "students" || type === "user") ? user.id : user.oId;

    useEffect(() => {
        console.log("set selectedd", defaultSelected);

        if (!defaultSelected) return;
        if (arraysEqual(defaultSelected, selected || [])) return;
        console.log("set selectedd", defaultSelected);
        setSelected(defaultSelected);
    }, [defaultSelected, open]);

    const uploadFile = async (e: any) => {
        console.log("files", e);

        const fileName = e.name;
        const file = e.file[0] as File;

        const newFileName = oId+"_"+getRandomNumber(0, 1000000)+"_"+file.name;

        await firebaseQuery.add("files", {
            product: user.product,
            oId: oId,
            fileName: newFileName,
            name: fileName,
            added: convertDate(new Date, "visual"),
        } as FileItem);

        await uploadFiles(file, type === "user" ? `userFiles/${newFileName}` : `${user.product}/${user.oId}/${newFileName}`);

        setAddFilePopup(false);
        getFiles();
    };

    useEffect(() => {
        if (open) return;
        setSelected(undefined);
    }, [open]);

    const onDeleteFile = async () => {
        if (!deleteFile) return;

        const fileToDelete = files?.find(([id]) => id === deleteFile)?.[1];
        await firebaseQuery.delete(["files", deleteFile]);
        await deleteObject(ref(storage, type === "user" ? `userFiles/${fileToDelete?.fileName}` : `${user.product}/${user.oId}/${fileToDelete?.fileName}`));
        setDeleteFile(undefined);
        getFiles();
        return;
    };

    const getFiles = () => {
        firebaseQuery.getDocsWhere("files", [where("product", "==", user.product), where("oId", "==", oId)]).then(async (f) => {
            setFiles(await Promise.all(Object.entries(f as {[key: string]: FileItem}).map(async ([id, file]) => {
                const url = await getDownloadURL(ref(storage, type === "user" ? `userFiles/${file.fileName}` : `${user.product}/${user.oId}/${file.fileName}`));
                return [id, {...file, url: url}];
            })));
        });
    };

    useEffect(() => {
        getFiles();
    }, [user]);

    const FileListComponent = <>
        <List>
            {files?.length ? files?.map(([id, file]) =>
                <ListItem id={id} key={id}>
                    <ListItemText primary={file.name} secondary={`Uploaded: ${file.added}`}/>
                    <ListItemSecondaryAction>
                        {selectable ?
                            <>
                                <IconButtonPop onClick={() => window.open(file.url, "_blank")} responsive={false} title="View"><RemoveRedEye/></IconButtonPop>
                                <Checkbox onChange={() => setSelected((s) => {
                                    return s?.includes(id) ? s.filter((i) => id !== i) : [...(s || []), id];
                                })} checked={Boolean(selected?.includes(id))} disabled={Boolean(selectable === true && selected?.length && !selected.includes(id))}/>
                            </> :
                            <>
                                <IconButtonPop onClick={() => window.open(file.url, "_blank")} responsive={false} title="View"><RemoveRedEye/></IconButtonPop>
                                <IconButtonPop responsive={false} onClick={() => setDeleteFile(id)} title="Delete"><Delete/></IconButtonPop>
                            </>
                        }
                    </ListItemSecondaryAction>
                </ListItem>) : <Typography variant="subtitle1" textAlign={"center"} color={"#0000008e"}>No files uploaded</Typography>}
        </List>
        <FileUploaderPopup name={true} onClose={() => setAddFilePopup(false)} open={addFilePopup} onSubmit={uploadFile}/>
        {Boolean(selectable) || <DeletePopup title="file" itemName={files?.find(([id]) => id === deleteFile)?.[1].name} open={Boolean(deleteFile)} onDelete={onDeleteFile} onClose={() => setDeleteFile(undefined)}/>}
    </>;

    if (popup) {
        return (
            <Popup fullWidth title={selectable ? "Select files to use" : "Your files"} open={Boolean(open)} onClose={() => onClose && onClose()} actions={
                <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                    <Button onClick={() => setAddFilePopup(true)}>Add file</Button>
                    {selectable && <Button variant="contained" onClick={() => selected && onSubmitSelection && onSubmitSelection(selected)}>Submit</Button>}
                </Stack>
            }>
                {FileListComponent}
            </Popup>
        );
    }

    return (
        <Card title={"Files"} grid {...{xs, md}} secondaryTitle={<Button onClick={() => setAddFilePopup(true)}>Add file</Button>}>
            {FileListComponent}
        </Card>);
}

export function FileSelectorInput({files, onChange}:{files?: string[], onChange?: (e: string[]) => void}) {
    const [filePopupActive, setFilePopupActive] = useState(false);
    return (

        <Stack direction={"row"} alignItems={"stretch"}>
            <Box border={`1px solid ${PRIMARY_COLOUR}`} borderRadius={1} display="flex" alignItems="center" pl={1} flex={1}>
                {files ? `${files.length} file${files?.length > 1 ? "s" : ""} selected` : "No files selected"}
            </Box>
            <Button onClick={() => setFilePopupActive(true)}>Select files</Button>
            <Files selectable="multiple" defaultSelected={files} type="org" popup open={filePopupActive} onClose={() => setFilePopupActive(false)} onSubmitSelection={(s) => {
                onChange && onChange(s);
                setFilePopupActive(false);
            }}/>
        </Stack>
    );
}
