import {Stack} from "@mui/material";
import {useContext} from "react";
import {OrganisationContext} from "../../../../../App";
import UserUploadSpreadsheet from "../../../../../Components/UserUploadSpreadsheet";
import {Popup} from "../../../../../Components/Popup";
import {Products} from "placementt-core";

type Params = {
    key: string,
    userType: "Students"|"Staff",
    oId?: string,
    product?: Products,
    cohortId?: string,
    fields?: string[],
    onToggle: (active:boolean) => void,
    active: boolean,
    onComplete?: (jobId?: string) => void
};

export default function UserUploadPopup({oId, product, fields, key, userType, cohortId, onToggle, active, onComplete}:Params) {
    const institute = useContext(OrganisationContext).details;

    const handleClose = (jobId?: string) => {
        onComplete && jobId && onComplete(jobId);
        onToggle(false);
    };

    return (
        <Popup key={key} title={`Add ${userType}`} onClose={() => handleClose()} open={active} cardSx={{minWidth: "400px", maxWidth: "100%"}}>
            <Stack alignContent={"center"} textAlign={"center"} spacing={2}>
                <UserUploadSpreadsheet
                    oId={oId}
                    product={product}
                    onComplete={handleClose}
                    userType={userType}
                    cohortId={cohortId}
                    defaultCols={[...["forename", "surname", "email"], ...(fields || institute[`${userType.toLowerCase()}Fields`] || [])]}/>
            </Stack>
        </Popup>
    );
}
