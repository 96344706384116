import {Box, Button, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Stack, Tooltip, Typography} from "@mui/material";
import Card from "../../../Components/Card";
import Page from "../../../Components/Page";
import Tasks from "../../../Components/Tasks";
import {CohortData, InstituteData, PRIMARY_COLOUR, Report, UserData, camelCaseToNormal, capitaliseWords, getAccess, getUserById} from "placementt-core";
import {useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../../../App";
import TipsBox from "../../../Components/TipsBox";
import {useNavigate} from "react-router-dom";
import TutorialPage from "../../Shared/TutorialPage";
import {where} from "@firebase/firestore";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import officeBuilding from "./../../../Images/Office building.png";
import {CameraAlt} from "@mui/icons-material";
import CriticalAlertPopup, {AlertObject} from "../../../Components/CriticalAlertPopup";
import {LoadingButton} from "../../../Components/LoadingButton";

export default function Home() {
    const [inWorkflow, setInWorkflow] = useState<number>(0);
    const [active, setActive] = useState<number>(0);
    const [staff, setStaff] = useState<number>(0);
    const [students, setStudents] = useState<number>(0);
    const [listings, setListings] = useState<number>(0);
    const [activeListings, setActiveListings] = useState<number>(0);
    const [admin, setAdmin] = useState<{email: string}>();
    const [reportedPlacements, setReportedPlacements] = useState<AlertObject|AlertObject[]>();

    const user = useContext(UserContext);
    const institute = useContext(OrganisationContext);
    const instituteData = institute.details as InstituteData;

    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();
    const viewAccess = getAccess(user, "viewStudents");

    const whereFilters = [where("oId", "==", user.oId)];

    const cohorts = Object.entries(institute.cohorts as {[key:string]: CohortData}).filter(([key, c]) => c.stage === "created" && (user.userGroup === "admin" || user.viewCohorts === "all" || (user.viewCohorts === "some") && ((user.visibleCohorts || "").split(",").includes(key)))).length;

    const {viewStaff, viewStudents, addListings} = getAccess(user, "viewStaff", "viewStudents", "addListings");

    useEffect(() => {
        firebaseQuery.getCount("placements", [...whereFilters, where("inProgress", "==", true),
            ...(viewAccess === "roles" ? [where("staffRoles", "array-contains-any", user.staffRoles)] : [])]).then(setInWorkflow);

        firebaseQuery.getCount("placements", [...whereFilters, where("active", "==", true),
            ...(viewAccess === "roles" ? [where("staffRoles", "array-contains-any", user.staffRoles)] : [])]).then(setActive);

        viewStaff && firebaseQuery.getCount("users", [...whereFilters, where("userType", "==", "Staff")]).then(setStaff);

        viewStudents && firebaseQuery.getCount("users", [...whereFilters, where("userType", "==", "Students")]).then(setStudents);

        addListings && firebaseQuery.getCount("savedPlacements", [where("savedById", "==", user.oId)]).then(setListings);
        addListings && firebaseQuery.getCount("savedPlacements", [where("status", "==", "Accepted")]).then(setActiveListings);

        getUserById(instituteData.admin).then(setAdmin);

        firebaseQuery.getDocsWhere("reports", [where("product", "==", "institutes"), where("oId", "==", user.oId), where("status", "==", "open")]).then(async (data) => {
            console.log("data", data);
            let allReports:AlertObject|AlertObject[] = await Promise.all(Object.entries(data as {[key: string]: Report}).map(async ([k, v]) => {
                const user = await firebaseQuery.getDocData(["users", v.uid]) as UserData;

                return {
                    title: `${user.details.forename} ${user.details.surname}`,
                    description: v.reason,
                    actions:
                        <Stack direction={"row"}>
                            <Button color="inherit" onClick={() => navigate("/institutes/cohorts/placements/"+v.pId)}>View placement</Button>
                            <LoadingButton noFlexGrow color="inherit" onClick={async () => await firebaseQuery.update(["reports", k], {status: "closed"})} text="Close case"/>
                        </Stack>,
                } as AlertObject;
            }));

            if (!allReports.length) return;

            if (allReports.length === 1) {
                allReports = allReports[0];
            }

            setReportedPlacements(allReports);
        });
    }, []);

    return (
        <Page
            metaTitle="Placementt | Home"
            metaDesc="An overview of your students and their placements"
            title={`Welcome back, ${user.details.forename}`}
            grid>
            <TutorialPage pageId="instituteStaffHome"/>
            {reportedPlacements && <Grid item xs={12} height={"max-content"}>
                <CriticalAlertPopup items={reportedPlacements}/>
            </Grid>}

            <Grid xs={12} md={8} item container height={"max-content"}>
                <Grid xs={12} md={6} item>
                    <Card>
                        <Stack direction={"row"}>
                            {false && <Tooltip title="Upload a profile image"><Box bgcolor={"lightgrey"} height={100} flex={"none"} display={"flex"} alignItems={"center"} justifyContent={"center"} width={100} borderRadius={100}><CameraAlt sx={{"height": "50%", "width": "50%", "opacity": 0.3, "cursor": "pointer", "transition": "all, ease-in-out 150ms", "&:hover": {opacity: 0.8}}}/></Box></Tooltip>}
                            <Stack p={2}>
                                <Typography variant="h5">{instituteData.name}</Typography>
                                <Typography>{instituteData["address-line1"]} • {instituteData.locality} • {capitaliseWords(camelCaseToNormal(instituteData.country))}</Typography>
                                <Typography>Admin email: {admin?.email}</Typography>
                            </Stack>
                        </Stack>
                    </Card>
                </Grid>
                <Grid xs={12} md={6} item>
                    <TipsBox key={"tipsBox"}/>
                </Grid>
                <Grid item xs={12}>
                    {cohorts ? <Tasks/> :
                        <Card sx={{padding: 5}}>
                            <Stack alignItems={"center"} justifyContent={"center"} flex={1}>
                                <img src={officeBuilding} style={{maxHeight: 150}}/>
                                <Typography variant="h5" fontWeight={"bold"} textAlign={"center"} mb={5}>Create your first cohort</Typography>
                                <Typography variant="subtitle1" textAlign={"center"} width={"500px"} maxWidth={"95%"}>Your students and their placements are structured into cohorts. These can be year groups, forms or entire schools. Click to create one to start managing your placements.</Typography>
                                <Button onClick={() => navigate("../cohorts/new")} variant="contained">Create a cohort</Button>
                            </Stack>
                        </Card>}
                </Grid>
            </Grid>
            <Grid xs={12} md={4} item container direction={"column"}>
                <Grid item>
                    <Card title={[<Typography variant={"h5"}>Overview</Typography>, active > 0 && <Button onClick={() => navigate(`/${user.product}/cohorts/all/active`)}>View map</Button>]}>
                        <List>
                            <ListItem style={{background: PRIMARY_COLOUR, borderRadius: "20px", marginBottom: "10px", color: "white"}}>
                                <ListItemText primaryTypographyProps={{fontWeight: "bold"}} primary="Active placements"/>
                                <ListItemSecondaryAction style={{fontWeight: "bold", color: "white"}}>
                                    {active}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                                <ListItemText primary="Placements in workflow"/>
                                <ListItemSecondaryAction>
                                    {inWorkflow}
                                </ListItemSecondaryAction>
                            </ListItem>
                            {viewStaff && <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                                <ListItemText primary="Staff"/>
                                <ListItemSecondaryAction>
                                    {staff}
                                </ListItemSecondaryAction>
                            </ListItem>}
                            {viewStudents && <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                                <ListItemText primary="Students"/>
                                <ListItemSecondaryAction>
                                    {students}
                                </ListItemSecondaryAction>
                            </ListItem>}
                        </List>
                    </Card>
                </Grid>
                <Grid item>
                    <Stack>
                        <Card title={[<Typography variant={"h5"}>Your placement database</Typography>, listings > 0 && <Button onClick={() => navigate("../placements/map")}>View map</Button>]}>
                            {listings > 0 ?
                                <List>
                                    <ListItem style={{background: PRIMARY_COLOUR, borderRadius: "20px", marginBottom: "10px", color: "white"}}>
                                        <ListItemText primaryTypographyProps={{fontWeight: "bold"}} primary="Active placement listings"/>
                                        <ListItemSecondaryAction style={{fontWeight: "bold", color: "white"}}>
                                            {activeListings}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                                        <ListItemText primary="Total placement listings"/>
                                        <ListItemSecondaryAction>
                                            {listings}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List> :
                                <Stack alignItems={"center"} justifyContent={"center"} flex={1}>
                                    <Typography variant="h6" fontWeight={"bold"} textAlign={"center"} mb={5}>Build your placement database</Typography>
                                    <Typography variant="subtitle1" textAlign={"center"}>Build a database of placements from across your region with our database tools.</Typography>
                                    <Button onClick={() => navigate("../placements")} variant="contained">Get started</Button>
                                </Stack>}
                        </Card>
                    </Stack>
                </Grid>
            </Grid>
        </Page>
    );
}

