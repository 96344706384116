import React, {useContext, useEffect, useState} from "react";
import {Button, Stack, Typography, Radio, FormControlLabel, RadioGroup, Chip, Snackbar, IconButton, DialogContent, DialogTitle, Box, Dialog, Grid, ToggleButton, MenuItem} from "@mui/material";
import UserUploadPopup from "./Cohorts/Popups/UserUploadPopup";
import FilterTable, {FilterObject} from "../../../Components/FilterTable";
import ActivateUsersPopup from "./Cohorts/Popups/ActivateUsersPopup";
import {CheckCircleOutline, Close, Delete, Group, School} from "@mui/icons-material";
import Form from "../../../Components/Form";
import IconButtonPop from "../../../Components/IconButtonPop";
import {OrganisationContext, UserContext} from "../../../App";
import {CohortData, Job, QueryObject, UserData, UserGroupData, camelCaseToNormal, capitalise, executeCallable, getAccess, getRandomNumber, useAppSelector, useCohortUserPaginator, useExecuteCallableJob} from "placementt-core";
import {Popup} from "../../../Components/Popup";
import Dropdown from "../../../Components/FormComponents/Dropdown";
import {where} from "firebase/firestore";
import ControlledToggleGroup from "../../../Components/ControlledToggleGroup";
import {ToggleFormItem} from "../../../Util/styledComponents";
import ListMaker from "../../../Components/FormComponents/ListMaker";
import InputGroup from "../../../Components/FormComponents/InputGroup";

type UserTableParams = {
    userType: "Staff"|"Students",
    cohortId?: string|"all",
    queries: QueryObject[],
    onItemClick?: (key: string) => void,
    noButtons?: boolean,
    onUserCountChange?: () => void
}

export function UserTable({userType, noButtons, cohortId="", queries, onItemClick, onUserCountChange}: UserTableParams) {
    const [selectedUsers, setSelectedUsers] = useState<"All"| {[key: string]: UserData}>({});
    const [addUsersPopup, setAddUsersPopup] = useState(false);
    const [activateAccountPopup, setActivateAccountPopup] = useState(false);
    const [userGroupPopup, setUserGroupPopup] = useState(false);
    const [cohortPopup, setCohortPopup] = useState(false);
    const [showCohorts, setShowCohorts] = useState<"all"|"some"|"none">("none");
    const [showUserFilters, setShowUserFilters] = useState(false);
    const [deleteUsersPopup, setDeleteUsersPopup] = useState(false);
    const [selectedUserGroup, setSelectedUserGroup] = useState<string>();
    const [selectedField, setSelectedField] = useState<string>();

    const [snackbarText, setSnackbarText] = useState("");
    const [tableFilters, setTableFilters] = useState<{[key:string]: unknown}>();
    const [uploadJob, setUploadJob] = useState<string>();
    const [userTableKey, setUserTableKey] = useState(getRandomNumber(0, 100000));

    const jobs = useAppSelector((state) => state.jobs.values) as {[key: string]: Job}|undefined;
    const org = useContext(OrganisationContext);
    const cohorts = org.cohorts;

    const user = useContext(UserContext);
    const {execute} = useExecuteCallableJob({user: user});

    const access = getAccess(user, `add${userType}`, `edit${userType}`, `delete${userType}`);

    useEffect(() => {
        if (!cohortPopup) {
            setShowCohorts("none");
            setShowUserFilters(false);
            setSelectedField(undefined);
        }
    }, [cohortPopup]);

    useEffect(() => {
        if (!uploadJob || !jobs) return;

        const job = jobs[uploadJob];
        if (job.status !== "processing") {
            setUserTableKey(getRandomNumber(0, 100000));
            setUploadJob(undefined);
            onUserCountChange && onUserCountChange();
        }
    }, [jobs]);

    if (user.product === "providers" && (userType === "Students" || cohortId)) {
        throw new Error("Providers cannot upload students or cohorts.");
    }


    const editUserGroup = () => {
        executeCallable("userManagement-editUserGroup", {users: selectedUsers === "All" ? queries : selectedUsers, filters: tableFilters, userGroup: selectedUserGroup, userType: userType});
        setUserGroupPopup(false);
        setSelectedUserGroup(undefined);
        setSnackbarText("Processing user group change.");
    };

    const editCohort = (e: {[key:string]: unknown}) => {
        if (userType === "Students") return;
        console.log("e", e);
        executeCallable("userManagement-editCohortVisibility", {users: selectedUsers === "All" ? queries : selectedUsers, filters: tableFilters, cohortData: e});
        setCohortPopup(false);
        setSnackbarText("Processing cohort change.");
    };

    const columns = user.product === "providers" ? ["forename", "surname", "email", "status", "userGroup", "visibleAddresses", "visibleGroups"] :
        userType === "Staff" ? ["forename", "surname", "email", "status", "userGroup", "visibleCohorts", "viewUsers"] : ["forename", "surname", "email", "status", ...(cohortId === "all" ? ["cohort"] : [])];

    const columnTemplate = {
        "userGroup": (groups: string) => groups.split(", ").map((group) => <Chip color={"primary"} label={group}/>),
        "cohort": (c: string) => c.split(", ").map((cohort) => <Chip color={"primary"} label={cohorts[cohort]?.name || "Unknown cohort"}/>),
        "visibleCohorts": (c: string, row: {[key:string]: unknown}) => <Chip color={"primary"} label={row.viewCohorts ? row.viewCohorts === "all" ? "All" : row.viewCohorts === "none" ? "None" : c?.split(",").length === 1 ? cohorts[c.split(",")[0]].name : c?.split(",").length + " cohorts" : "None"}/>,
        "visibleAddresses": (c: string, row: {[key:string]: unknown}) => <Chip color={"primary"} label={row.viewAddresses ? row.viewAddresses === "all" ? "All" : row.viewAddresses === "none" ? "None" : c?.split(",").length === 1 ? cohorts[c.split(",")[0]].name : c?.split(",").length + " addresses" : "None"}/>,
        "visibleGroups": (c: string, row: {[key:string]: unknown}) => <Chip color={"primary"} label={row.viewGroups ? row.viewGroups === "all" ? "All" : row.viewGroups === "none" ? "None" : c?.split(",").length === 1 ? cohorts[c.split(",")[0]].name : c?.split(",").length + " groups" : "None"}/>,
        "viewUsers": (c: string, row: {[key:string]: unknown}) => <Chip sx={{maxWidth: "100px"}} color={"primary"} label={row.viewUsers ? row.viewUsers === "all" ? "All" : `${camelCaseToNormal(row.studentFilter as string)}: ${row.studentFilterValues}` : "None"}/>,
        "status": (s: string) => capitalise(s),
    };
    const filters:FilterObject = {
        ["details.forename"]: {
            label: "Forename",
            type: "string",
        },
        ["details.surname"]: {
            label: "Surname",
            type: "string",
        },
        ["details.email"]: {
            label: "Email",
            type: "string",
        },
        status: {
            label: "Status",
            values: {active: "Active", inactive: "Inactive"},
            type: "dropdown",
        },
        userGroup: {
            label: "User group",
            values: Object.fromEntries(Object.entries(org.userGroups as {[key:string]: UserGroupData}).filter(([, group]) => group.template === userType).map(([id, group]) => [id, group.name])),
            type: "dropdown",
        },
    };

    if (cohortId === "all") {
        filters.cohort = {
            label: "Cohort",
            values: Object.fromEntries(Object.entries(org.cohorts as {[key:string]: CohortData}).map(([id, group]) => [id, group.name])),
            type: "dropdown",
        };
    }

    if (userType === "Students") {
        filters.parentEmail = {
            label: "Parent email",
            values: {false: {label: "Doesn't exist", test: where("details.parentEmail", "==", "null")}},
            type: "dropdown",
        };
        filters.active = {
            label: "Activated",
            values: {true: {label: "True", test: where("active", "==", true)}, false: {label: "False", test: where("active", "==", false)}},
            type: "dropdown",
        };
    }

    if (!userType) {
        return null;
    }

    console.log("RENDER FGENAUIGIB");

    return (
        <Box style={{minHeight: "400px", padding: "16px", width: "100%"}}>
            <FilterTable
                key={userTableKey}
                search
                viewRows
                filters={filters}
                {...{columns, columnTemplate, onItemClick}}
                setTableFilters={setTableFilters}
                access={Boolean(queries.length)}
                data={queries}
                setSelectedRows={(e) => setSelectedUsers(e as "All"|{[key:string]: UserData})}
                hook={(data, query) => useCohortUserPaginator({user: user, cohort: cohortId, data: data as QueryObject[], userType: userType, search: query})}
                formatQueryData={(data) =>
                    Object.entries(data as {[key:string]: UserData&{userGroupId: string}}).filter(([, user]) =>
                        user.userGroup !== "admin").reduce((acc, [uid, user]) => {
                        if (acc[uid]) return acc;

                        if (user.userGroupId) {
                            acc[uid] = user;
                            return acc;
                        }

                        return {...acc, [uid]: {...user, ...user.details, "userGroup": user.userGroup === "admin" ? "admin" : org.userGroups[user.userGroup || ""].name, "userGroupId": user.userGroup}};
                    }, {} as {[key:string]: unknown})
                }
                actionButtonText={!noButtons && cohortId !== "all" && access[`add${userType}`] && `Add ${userType.toLowerCase()}`}
                onActionButtonClick={() => setAddUsersPopup(true)}>
                {!noButtons && access[`edit${userType}`] && cohortId !== "all" && <IconButtonPop variant="contained" title={`Activate ${userType.toLowerCase()}`} onClick={() => setActivateAccountPopup(true)}><CheckCircleOutline/></IconButtonPop>}
                {!noButtons && userType === "Staff" && access[`edit${userType}`] && <IconButtonPop title={"Set user group"} onClick={() => {
                    setUserGroupPopup(true);
                }}><Group/></IconButtonPop>}
                {!noButtons && userType === "Staff" && access[`edit${userType}`] && <IconButtonPop title={"Change student visibility"} onClick={() => {
                    setCohortPopup(true);
                }}><School/></IconButtonPop>}
                {!noButtons && access[`delete${userType}`] && <IconButtonPop color="error" title={"Delete users"} onClick={() => {
                    setDeleteUsersPopup(true);
                }}><Delete/></IconButtonPop>}
            </FilterTable>
            {cohortId !== "all" && (cohortId || userType === "Staff") && <UserUploadPopup onComplete={setUploadJob} key={`${userType}UploadPopup`} userType={userType} cohortId={cohortId} active={addUsersPopup} onToggle={setAddUsersPopup}/>}
            {cohortId !== "all" && <ActivateUsersPopup userType={userType} cohortId={cohortId} active={activateAccountPopup} filters={tableFilters} users={selectedUsers === "All" ? queries : selectedUsers} onToggle={setActivateAccountPopup}/>}

            <Dialog
                open={deleteUsersPopup}
                onClose={() => setDeleteUsersPopup(false)}>
                <DialogTitle>Delete {userType.toLowerCase()}?</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography variant='body1'>You can permanently delete all user data or disable the user accounts.</Typography>
                        <Typography variant='body1'>User data will be unretrievable once deleted. Users may be disabled to preserve data.</Typography>
                        <Stack direction={"row"} justifyContent="space-evenly">
                            <Button color="error" onClick={async () => {
                                const jobId = await execute("userManagement-deleteUsers", {filters: tableFilters, users: selectedUsers === "All" ? queries : selectedUsers, userType: userType}); setDeleteUsersPopup(false);
                                setUploadJob(jobId);
                            }}>Delete</Button>
                            <Button onClick={() => {
                                execute("userManagement-deactivateUsers", {filters: tableFilters, users: selectedUsers === "All" ? queries : selectedUsers, userType: userType}); setDeleteUsersPopup(false);
                            }}>Deactivate</Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>

            <Popup key="changeUserGroup" open={userGroupPopup} onClose={() => setUserGroupPopup(false)} title={"Set User groups for selected users"}>
                <Form onSubmit={editUserGroup}>
                    <RadioGroup
                        aria-labelledby="Select user radio buttons"
                        name="userGroup"
                        sx={{margin: "10px"}}
                        onChange={(e) => setSelectedUserGroup(e.target.value)}
                    >
                        {Object.entries(org.userGroups as {[key:string]: UserGroupData}).map(([id, group]) => {
                            if (group.template !== userType) {
                                return null;
                            }
                            return (<FormControlLabel value={id} control={<Radio />} label={group.name} />);
                        })}
                    </RadioGroup>
                </Form>
            </Popup>
            <Popup key="changeCohort" open={cohortPopup} onClose={() => setCohortPopup(false)} title={"Set student visibility for selected users"}>
                <Form onSubmit={editCohort}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography>Visible cohorts</Typography>
                                <ToggleFormItem>
                                    <ControlledToggleGroup name='viewCohorts' defaultValue={"none"} disabled={!access}>
                                        <ToggleButton onClick={() => setShowCohorts("all")} value="all">All</ToggleButton>
                                        <ToggleButton onClick={() => setShowCohorts("some")} value="some">Select cohorts</ToggleButton>
                                        <ToggleButton onClick={() => setShowCohorts("none")} value="none">None</ToggleButton>
                                    </ControlledToggleGroup>
                                </ToggleFormItem>
                            </Stack>
                        </Grid>
                        {showCohorts === "some" && <Grid item xs={12}>
                            <Dropdown label="Select cohorts" disabled={!access} required multiple path={[["cohorts"], [where("oId", "==", user.oId), where("product", "==", user.product)]]} name="visibleCohorts"/>
                        </Grid>}
                        {showCohorts !== "none" && <>
                            <Grid item xs={12}>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography>Filter students by field</Typography>
                                    <ToggleFormItem>
                                        <ControlledToggleGroup name='viewUsers' defaultValue={"all"} disabled={!access}>
                                            <ToggleButton onClick={() => setShowUserFilters(false)} value="all">All</ToggleButton>
                                            <ToggleButton onClick={() => setShowUserFilters(true)} value="some">Select students</ToggleButton>
                                        </ControlledToggleGroup>
                                    </ToggleFormItem>
                                </Stack>
                            </Grid>
                            {showUserFilters && <Grid item xs={12}>
                                <Dropdown label="Select fields" onChange={(e) => setSelectedField(e.target.value)} id={"Select field dropdown"} key={"Select field dropdown"} disabled={!access} required name="studentFilter">
                                    {(org.details.studentsFields as string[]).map((field) => <MenuItem value={field}>{camelCaseToNormal(field)}</MenuItem>)}
                                </Dropdown>
                            </Grid>}
                            {selectedField && <Grid item xs={12}>
                                <ListMaker label="Input field values" name="studentFilterValues">
                                    <InputGroup name="studentFilterValues" placeholder={"Start typing..."} />
                                </ListMaker>
                            </Grid>}
                        </>}
                    </Grid>
                </Form>
            </Popup>
            <Snackbar
                open={Boolean(snackbarText)}
                autoHideDuration={6000}
                onClose={() => setSnackbarText("")}
                message={snackbarText}
                action={
                    <IconButton
                        size="small"
                        title='Close notification'
                        aria-label="close"
                        color="inherit"
                        onClick={() => setSnackbarText("")}>
                        <Close fontSize="small" />
                    </IconButton>
                }/>
        </Box>
    );
}
