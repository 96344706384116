import {Grid, Stack, Typography} from "@mui/material";

type Params = {
    title: string,
    sectionOne: string,
    sectionTwo: string,
    titleColor?: string
};

export default function TwoColumnSection({title, sectionOne, sectionTwo, titleColor="primary"}: Params) {
    return (
        <Stack>
            {title && <Typography textAlign={"start"} color={titleColor} variant="h5">{title}</Typography>}
            <Grid container>
                <Grid item xs={12} md={6} borderRight={"1px dashed lightgrey"} p={2}>
                    <Typography fontSize={"1.2em"}>{sectionOne}</Typography>
                </Grid>
                <Grid item xs={12} md={6} p={2}>
                    <Typography fontSize={"1.2em"}>{sectionTwo}</Typography>
                </Grid>
            </Grid>
        </Stack>
    );
}
