import {ArrowBack, ArrowForward, CheckCircleOutline, Handshake, Lightbulb} from "@mui/icons-material";
import IconButtonPop from "../../../Components/IconButtonPop";
import {Alert, Box, Button, Checkbox, FormControlLabel, Grid, Icon, List, ListItem, ListItemIcon, ListItemText, MenuItem, Stack, Typography} from "@mui/material";
import {LoadingButton} from "../../../Components/LoadingButton";
import {GradientText} from "../../../Util/styledComponents";
import {PRIMARY_COLOUR, PlacementQuestions, PlacementReviewDetails, SUCCESSTEXTCOLOR, executeCallable, uploadFiles} from "placementt-core";
import FadeInBox from "../../../Components/FadeInBox";
import Form from "../../../Components/Form";
import Dropdown from "../../../Components/FormComponents/Dropdown";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import {useEffect, useState} from "react";
import ListMaker from "../../../Components/FormComponents/ListMaker";
import {Popup} from "../../../Components/Popup";
import UploadProviderRiskAssessment from "./UploadProviderRiskAssessment/UploadProviderRiskAssessment";

import Preloader from "../../../Components/Preloader";
import UploadProviderDbsCheck from "./UploadProviderDbsCheck/UploadProviderDbsCheck";

type Params = {
    visible: boolean,
    onComplete?: (e: {[key:string]: unknown}) => any,
    back?: () => void,
    uid?: string,
    providerId?: string,
    pId?: string,
    placement?: PlacementReviewDetails,
    instituteName?: string,
    completedSections?: {details?: boolean, eli?: boolean, forms?: boolean, questions?: boolean, riskAssessment?: boolean, dbsCheck?: boolean},
    onCompleteSection?: (section: "details" | "eli" | "forms" | "questions" | "riskAssessment" | "dbsCheck", data?: {
        [key: string]: unknown;
    }) => void
}

export default function UploadPlacementQuestions({visible, onComplete, back, placement, uid, pId, instituteName, completedSections, onCompleteSection}: Params) {
    const [page, setPage] = useState<number>(0);
    const [questions, setQuestions] = useState<PlacementQuestions>();
    const [dressCodeOther, setDressCodeOther] = useState(false);
    const [placementId, setPlacementId] = useState(placement?.placementId);
    const [riskAssessmentPopup, setRiskAssessmentPopup] = useState(false);
    const [riskAssessmentLoading, setRiskAssessmentLoading] = useState(false);
    const [dbsCheckPopup, setDbsCheckPopup] = useState(false);
    const [dbsCheckLoading, setDbsCheckLoading] = useState(false);

    useEffect(() => {
        setPage(0);
    }, [visible]);

    useEffect(() => {
        console.log("Questions", questions);
    }, [questions]);

    useEffect(() => {
        if (!placement) return;
        if (placement.questions) {
            setQuestions(placement.questions);
        }
        if (placement.placementId) {
            setPlacementId(placement.placementId);
        }
    }, [placement]);

    const onSubmitQuestions = async (mapConsent: true | "institute" | false) => {
        console.log("MC", mapConsent);
        const uploadQuestionObject = {
            title: questions?.title,
            questions: questions,
            mapConsent: mapConsent,
        };

        if (!placement) {
            onComplete && await onComplete(uploadQuestionObject);
            return;
        }
        await executeCallable("placement-submitProviderReview", {pId: pId, stage: placement.currentStage, questions: uploadQuestionObject, providerId: placement.providerId, placementListingId: placementId})
            .catch((e) => {
                throw e;
            }).then(() => {
                const toSubmit:{[key:string]: unknown} = {...uploadQuestionObject, placementId: placementId};
                if (placementId) {
                    toSubmit.rememberConsent = true;
                }
                onComplete && onComplete(toSubmit);
                return;
            });
    };

    console.log("EP", placement?.existingPlacements);

    const selectPlacementRiskAssessmentOrDbsCheck = async ({e, setLoading, type, placement}:{e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setLoading: (value: React.SetStateAction<boolean>) => void, type: "riskAssessment"|"dbsCheck", placement: PlacementReviewDetails}) => {
        setLoading(true);

        if (!placement.existingPlacements?.[e.target.value][`${type}Type`]) {
            setLoading(false);
            setPlacementId(e.target.value);
            return;
        }
        if (placement.existingPlacements?.[e.target.value][`${type}Type`] === "file") {
            const fileData = await executeCallable(`${type}-duplicate`, {oldId: e.target.value, newId: pId}).catch((a) => {
                console.error("ERROR", a);
                setLoading(false);
                setPlacementId(e.target.value);
                return;
            });

            if (!fileData?.data) {
                console.log("No response");
                return;
            }

            await uploadFiles(fileData, `${type}s/${pId}.pdf`, "application/pdf");
        }

        await executeCallable(`${type}-add`, {
            uploadType: placement.existingPlacements?.[e.target.value][`${type}Type`],
            [type]: placement.existingPlacements?.[e.target.value][`${type}Data`],
            pId: pId,
            providerId: placement.providerId})
            .then(() => {
                onCompleteSection && onCompleteSection(type);
            });
        setLoading(false);
    };

    return (
        <>
            <FadeInBox card cardTitle={[<IconButtonPop responsive={false} onClick={back} title={"Back"}><ArrowBack/></IconButtonPop>, <GradientText m={"auto"} variant={"h4"}>Placement details</GradientText>]} visible={visible && page === 0}>
                <Typography variant='subtitle2'>This information will be available to the student, to help them prepare, and provide you with a student who is ready to learn. Please be as detailed as possible.</Typography>
                <Form functionType='sync' onSubmit={(e) => {
                    setQuestions((q) => ({...q, ...e} as PlacementQuestions)); setPage(1);
                }} submitText={"Next"}>
                    <Stack spacing={2} alignItems={"center"}>
                        <Grid xs={12} item sx={{width: "100%"}}>
                            <Grid container sx={{textAlign: "left"}}>
                                <Grid item xs={12}>
                                    <Typography variant='h6'>Basic details</Typography>
                                </Grid>
                                {placement && placement.existingPlacements && Object.keys(placement.existingPlacements).length > 0 && <>
                                    <Grid item xs={12}>
                                        <Alert severity="info">We've remembered previous placements so you don't have to duplicate the details. Select from the dropdown below or fill out the questions manually</Alert>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Dropdown label={"Select placement"} onChange={async (e) => {
                                            placement.existingPlacements && setQuestions(placement.existingPlacements[e.target.value].questions);
                                            selectPlacementRiskAssessmentOrDbsCheck({e: e, setLoading: setDbsCheckLoading, type: "dbsCheck", placement: placement});
                                            selectPlacementRiskAssessmentOrDbsCheck({e: e, setLoading: setRiskAssessmentLoading, type: "riskAssessment", placement: placement});
                                            setPlacementId(e.target.value);
                                        }}>
                                            {Object.entries(placement.existingPlacements).map(([id, p]) =>
                                                <MenuItem value={id}>{p.jobTitle}</MenuItem>
                                            )}
                                        </Dropdown>
                                    </Grid></>}
                                {placement && placement.requireRA && completedSections && <Grid item xs={12}>
                                    {Object.keys(completedSections).includes("riskAssessment") && <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} border={`1px solid ${completedSections?.riskAssessment ? SUCCESSTEXTCOLOR : PRIMARY_COLOUR}`} borderRadius={1} p={1}>
                                        <Typography>Submit a risk assessment</Typography>
                                        {riskAssessmentLoading ? <Box width={"50px"}><Preloader visible size="30px"/></Box> : (completedSections?.riskAssessment ? <Icon color='success' sx={{marginRight: "8px"}}><CheckCircleOutline/></Icon> :
                                            <><IconButtonPop disabled={!completedSections?.details} onClick={() => setRiskAssessmentPopup(true)} title={"Upload"}>
                                                <ArrowForward/>
                                            </IconButtonPop>
                                            <InputGroup name="racomplete" sx={{display: "none"}} required/></>)}
                                    </Stack>}
                                </Grid>}
                                {placement && placement.requireDBS && completedSections && <Grid item xs={12}>
                                    {Object.keys(completedSections).includes("dbsCheck") && <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} border={`1px solid ${completedSections?.dbsCheck ? SUCCESSTEXTCOLOR : PRIMARY_COLOUR}`} borderRadius={1} p={1}>
                                        <Typography>Submit a DBS check</Typography>
                                        {dbsCheckLoading ? <Box width={"50px"}><Preloader visible size="30px"/></Box> : (completedSections?.dbsCheck ? <Icon color='success' sx={{marginRight: "8px"}}><CheckCircleOutline/></Icon> :
                                            <><IconButtonPop disabled={!completedSections?.details} onClick={() => setDbsCheckPopup(true)} title={"Upload"}>
                                                <ArrowForward/>
                                            </IconButtonPop>
                                            <InputGroup name="dbsComplete" sx={{display: "none"}} required/></>)}
                                    </Stack>}
                                </Grid>}
                                <Grid item xs={12}>
                                    <InputGroup label={"Job title"} name={"title"} value={questions?.title} required/>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputGroup value={questions?.description} name={"description"} label={"General description"} multiline minRows={3} required/>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputGroup required value={questions?.firstDayContact} name={"firstDayContact"} label={"First day contact"} placeholder={"E.g. John Smith"}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputGroup required value={questions?.firstAider} name={"firstAider"} label={"First aider"} placeholder={"E.g. John Smith"}/>
                                </Grid>
                                <Grid item xs={12} md={dressCodeOther ? 6 : 12}>
                                    <Dropdown label={"Dress code"} id={"dressCode"} required name={"dressCode"} value={questions?.dressCode} onChange={(e) => setDressCodeOther(e.target.value === "other")}>
                                        <MenuItem value={"businessFormal"}>Business Formal</MenuItem>
                                        <MenuItem value={"businessProfessional"}>Business Professional</MenuItem>
                                        <MenuItem value={"businessCasual"}>Business Casual</MenuItem>
                                        <MenuItem value={"casual"}>Casual</MenuItem>
                                        <MenuItem value={"other"}>Other</MenuItem>
                                    </Dropdown>
                                </Grid>
                                {dressCodeOther && <Grid item xs={12} md={6}>
                                    <InputGroup value={questions?.dressCodeText} name={"dressCodeText"} label={"Dress code"} placeholder={"Please specify"} required/>
                                </Grid>}
                                <Grid item xs={12}>
                                    <InputGroup required value={questions?.hours} name={"hours"} label={"Working days and hours"} placeholder={"Mon-Fri 9-5"}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Stack>
                </Form>
            </FadeInBox>
            <FadeInBox card cardTitle={[<IconButtonPop responsive={false} onClick={() => setPage(0)} title={"Back"}><ArrowBack/></IconButtonPop>, <GradientText m={"auto"} variant={"h4"}>Placement details</GradientText>]} visible={visible && page === 1}>
                <Typography variant='subtitle2'>This information will be available to the student, to help them prepare, and provide you with a student who is ready to learn. Please be as detailed as possible.</Typography>
                <Form functionType='sync' onSubmit={(e) => {
                    setQuestions((q) => ({...q, ...e} as PlacementQuestions)); setPage(placementId ? 5 : uid ? 4 : 2);
                }} submitText={"Next"}>
                    <Stack spacing={2} alignItems={"center"}>
                        <Grid xs={12} item sx={{width: "100%"}}>
                            <Grid container sx={{textAlign: "left"}}>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        control={<Checkbox key={"carParking"}
                                            name={"carParking"}
                                            defaultChecked={questions?.carParking}/>}
                                        label={"Car parking"}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        control={<Checkbox key={"bikeStorage"}
                                            name={"bikeStorage"}
                                            defaultChecked={questions?.bikeStorage}/>}
                                        label={"Bike storage"}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        control={<Checkbox key={"wheelchairFriendly"}
                                            name={"wheelchairFriendly"}
                                            defaultChecked={questions?.wheelchairFriendly}/>}
                                        label={"Wheelchair friendly"}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        control={<Checkbox key={"kitchen"}
                                            name={"kitchen"}
                                            defaultChecked={questions?.kitchen}/>}
                                        label={"Kitchen"}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputGroup required value={questions?.lunchArrangements} name={"lunchArrangements"} label={"Lunch arrangements"}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <ListMaker required items={questions?.responsibilities} name={"responsibilities"} label={"Responsibilities"} subtitle='What will the student be responsible for?'>
                                        <InputGroup name={"responsibilities"} placeholder={"Start typing..."} />
                                    </ListMaker>
                                </Grid>
                                <Grid item xs={12}>
                                    <ListMaker items={questions?.equipment} name={"equipment"} label={"Required equipment"} subtitle='What should the student bring?'>
                                        <InputGroup name={"equipment"} placeholder={"Start typing..."} />
                                    </ListMaker>
                                </Grid>
                                <Grid item xs={12}>
                                    <ListMaker items={questions?.experiencesGiven} name={"experiencesGiven"} label={"Experience given"} subtitle='What experiences can the student expect to gain?' required>
                                        <InputGroup name={"experiencesGiven"} placeholder={"Start typing..."} />
                                    </ListMaker>
                                </Grid>
                                <Grid item xs={12}>
                                    <ListMaker items={questions?.experienceDesired} name={"experienceDesired"} label={"Experience desired"} subtitle='What skills will the student need to be proficient in?'>
                                        <InputGroup name={"experienceDesired"} placeholder={"Start typing..."} />
                                    </ListMaker>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputGroup multiline rows={3} value={questions?.otherAdvice} name={"otherAdvice"} label={"Other relevant information"} placeholder={"E.g. Preparation, directions"}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Stack>
                </Form>
            </FadeInBox>
            {placementId &&
                <FadeInBox card visible={visible && page === 5}>
                    <GradientText variant={"h4"}>Submit your information</GradientText>
                    <Typography variant={"h6"}>Click the button below to submit your placement review to {placement?.instituteName}</Typography>
                    <LoadingButton onClick={() => onSubmitQuestions(placement?.mapConsent || false)} variant='contained' text="Submit"/>
                </FadeInBox>}
            <FadeInBox card cardTitle={[<IconButtonPop responsive={false} onClick={() => setPage(1)} title={"Back"}><ArrowBack/></IconButtonPop>, <GradientText m={"auto"} variant={"h4"}>Placement details</GradientText>]} visible={visible && page === 2}>
                <Typography variant='subtitle2'>This information will be available to the student, to help them prepare, and provide you with a student who is ready to learn. Please be as detailed as possible.</Typography>
                <Form onSubmit={(e) => {
                    setQuestions((q) => ({...q, ...e} as PlacementQuestions)); setPage(4);
                }} submitText={"Next"} functionType="sync">
                    <Stack spacing={2} alignItems={"center"}>
                        <Grid xs={12} item sx={{width: "100%"}}>
                            <Grid container sx={{textAlign: "left"}}>
                                <Grid item xs={12}>
                                    <Dropdown multiple label={"Student level"} required name={"studentLevel"} value={questions?.studentLevel}>
                                        <MenuItem value={"gcse"}>GCSE (14-16)</MenuItem>
                                        <MenuItem value={"college"}>Sixth form / college (16-18)</MenuItem>
                                        <MenuItem value={"undergraduate"}>Undergraduate</MenuItem>
                                        <MenuItem value={"postgraduate"}>Postgraduate</MenuItem>
                                    </Dropdown>
                                </Grid>
                                <Grid item xs={12}>
                                    <Dropdown label={"Working location"} required name={"workingLocation"} value={questions?.workingLocation}>
                                        <MenuItem value={"inPerson"}>In-person</MenuItem>
                                        <MenuItem value={"hybrid"}>Hybrid</MenuItem>
                                        <MenuItem value={"remote"}>Remote</MenuItem>
                                    </Dropdown>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputGroup label={"Desired placement length"} min={1} name={"desiredLength"} value={questions?.desiredLength} type={"number"} required/>
                                </Grid>
                                <Grid item xs={6}>
                                    <Dropdown label={"Units"} required name={"desiredLengthUnits"} value={questions?.desiredLengthUnits || "weeks"}>
                                        <MenuItem value={"days"}>Days</MenuItem>
                                        <MenuItem value={"weeks"}>Weeks</MenuItem>
                                        <MenuItem value={"months"}>Months</MenuItem>
                                    </Dropdown>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputGroup required value={questions?.maximumStudents} min={1} type={"number"} name={"maximumStudents"} label={"Maximum number of students"}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Typography>By submitting this placement, you are agreeing to us advertising your placement {instituteName ? `to students at ${instituteName}` : "on Placementt"}. Students may contact you regarding the opportunities you post.</Typography>
                </Form>
            </FadeInBox>
            <FadeInBox card visible={visible && page === 4}>
                <Stack spacing={2} alignItems={"center"}>
                    <RequestMapConsent instituteName={placement?.instituteName || ""} uid={placement?.uid} onSubmit={onSubmitQuestions}/>
                </Stack>
                <IconButtonPop onClick={() => setPage(1)} responsive={false} title={"Back"} sx={{position: "absolute", top: "14px", left: "20px"}}>
                    <ArrowBack/>
                </IconButtonPop>
            </FadeInBox>
            {placement && completedSections && onCompleteSection && <UploadProviderRiskAssessment {...{pId, placement}} placementListingId={placement.placementId} providerId={placement?.providerId} visible={riskAssessmentPopup} back={() => setRiskAssessmentPopup(false)} onComplete={() => {
                setRiskAssessmentPopup(false);
                onCompleteSection("riskAssessment");
            }}/>}
            {placement && completedSections && onCompleteSection && <UploadProviderDbsCheck {...{pId, placement}} placementListingId={placement.placementId} providerId={placement?.providerId} visible={dbsCheckPopup} back={() => setDbsCheckPopup(false)} onComplete={() => {
                setDbsCheckPopup(false);
                onCompleteSection("dbsCheck");
            }}/>}
        </>
    );
}

function RequestMapConsent({instituteName, uid, onSubmit}:{instituteName: string, uid?: string, onSubmit: (mapConsent: true|"institute"|false) => Promise<void>}) {
    const [listToInstitutePopup, setListToInstitutePopup] = useState(false);

    return (
        <>
            <GradientText variant={"h4"}>Placementt</GradientText>
            <Box bgcolor={PRIMARY_COLOUR} sx={{position: "relative", margin: "10px -16px !important", padding: "16px", clipPath: "polygon(0 9%, 100% 0, 100% 100%, 0 86%)"}}>
                <Typography color={"#fff"} variant='h5'>Work experience is about students finding the best opportunities to support their development</Typography>
            </Box>
            <Typography variant={"h6"}>Placementt is providing a free database for students across the UK.</Typography>
            <List sx={{alignSelf: "start"}}>
                <ListItem>
                    <ListItemIcon><Lightbulb/></ListItemIcon>
                    <ListItemText primary={"Students find placements that suit them."}/>
                </ListItem>
                <ListItem>
                    <ListItemIcon><Handshake/></ListItemIcon>
                    <ListItemText primary={"You get more enthusiastic, motivated students."}/>
                </ListItem>
            </List>
            <Typography variant='h6'>Join our ever growing community of businesses supporting the next generation.</Typography>
            <Typography>Consent to us listing your business on our placement map. This requires no action from you, though you can take control of the listing at any time.</Typography>
            <LoadingButton>
                <Button onClick={async () => {
                    instituteName && uid ? setListToInstitutePopup(true) : await onSubmit(uid ? false : "institute");
                }}>{uid ? "Submit without listing" : "Share with institute"}</Button>
                <Button onClick={async () => await onSubmit(true)} variant='contained'>{uid ? "List placement and submit" : "Share on Placementt"}</Button>
            </LoadingButton>
            {instituteName && <Popup open={listToInstitutePopup} onClose={() => setListToInstitutePopup(false)} title={<GradientText variant={"h4"}>Share with institute</GradientText>}>
                <Typography variant='h6'>Can we share this placement with future students at {instituteName}?</Typography>
                <Typography>Only students in this institute will be able to see this placement, so you may help subsequent year groups find placements.</Typography>
                <Typography>No students outside the institute will see this, and you can change your preferences any time.</Typography>
                <LoadingButton>
                    <Button onClick={async () => await onSubmit(false)}>No, continue</Button>
                    <Button onClick={async () => await onSubmit("institute")} variant='contained'>Share with institute</Button>
                </LoadingButton>
            </Popup>}
        </>
    );
}
