import {Box, Breadcrumbs, Button, Checkbox, Chip, DialogActions, Divider, FormControlLabel, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Snackbar, Stack, Tab, Table, TableBody, TableCell, TableRow, Tabs, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import SubNavBar, {SubNavBarItems} from "../../../../Components/Navigation/SubNavBar";
import Page from "../../../../Components/Page";
import {UserTable} from "./../UserTable";
import {ChangeEvent, useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../../../../App";
import {CohortData, CustomFormSchema, EmailTemplate, Job, OrganisationAddress, PRIMARY_COLOUR, QueryObject, UserData, WorkflowStage, camelCaseToNormal, capitalise, convertDate, emailTemplates, executeCallable, getAccess, getDateDiff, storage, useAppSelector, useExecuteCallableJob, useWorkflowEditor} from "placementt-core";
import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {arrayUnion, deleteField, where} from "firebase/firestore";
import Card from "../../../../Components/Card";
import PlacementList from "../../../../Components/PlacementList";
import Tasks from "../../../../Components/Tasks";
import IconButtonPop from "../../../../Components/IconButtonPop";
import {Add, ArrowForward, Close, Delete, Edit, HelpOutline} from "@mui/icons-material";
import {Popup} from "../../../../Components/Popup";
import DateRangePicker from "../../../../Components/FormComponents/DateRangePicker";
import Form from "../../../../Components/Form";
import WorkflowEditorV3 from "../../../../Components/WorkflowEditorV3";
import ActivePlacementsMap from "../ActivePlacementsMap";
import emptyBox from "./../../../../Images/Empty box.png";
import UserUploadPopup from "./Popups/UserUploadPopup";
import VerticalPaginator from "../../../../Components/VerticalPaginator";
import Xarrow from "react-xarrows";
import Dropdown from "../../../../Components/FormComponents/Dropdown";
import CustomFormBuilder from "../../../../Components/CustomFormBuilder";
import FilterTable from "../../../../Components/FilterTable";
import DataExportPopup from "./Popups/PlacementDataExportPopup";
import InputGroup from "../../../../Components/FormComponents/InputGroup";
import SlateEditor from "../../../../Components/Slate";
import {Descendant} from "slate";
import {LoadingButton} from "../../../../Components/LoadingButton";
import {getDownloadURL, ref} from "firebase/storage";
import EmailTemplates from "../../../Shared/EmailTemplates";
import {FileSelectorInput} from "../../../Shared/Files";

const purpleCardStyle = {
    "backgroundColor": PRIMARY_COLOUR,
    "background": "linear-gradient(145deg, rgba(121,32,245) 0%, rgba(192,75,255) 50%, rgba(179,128,249) 100%)",
    "color": "white !important",
    "cursor": "pointer",
    "transition": "all ease-in-out 125ms",
    "&:hover": {
        "transform": "scale(1.02)",
        "box-shadow": "1px 1px 1px 0 black solid",
    },
};

export default function Cohort({cohort}:{cohort: CohortData|"all"}) {
    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();

    const submitCohort = async (e: {
        workflow: WorkflowStage[];
        includedFiles?: string[];
        includedForms?: string[];
        changeToSimple?: boolean;
    }) => {
        if (cohort === "all") return;
        // 123abc
        await firebaseQuery.update(["cohorts", cohort.id || ""], {customWorkflow: !e.changeToSimple, workflow: e.workflow, files: e.includedFiles, forms: [...(e.includedForms || []), ...Object.values(cohort.feedback || {}).map((v) => v)]});
        navigate(e.changeToSimple ? "./settings/workflow" : "./settings");
    };

    return (
        <Page
            title={typeof cohort === "string" ? "All students" : cohort.name}
            noGutters>
            <SubNavBar
                pathLevel={4}
                items={[
                    {
                        label: "Overview",
                        route: "overview",
                        element: <Overview {...{cohort}}/>,
                    },
                    {
                        label: "Students",
                        route: "students",
                        element: <Students {...{cohort}}/>,
                    },
                    {
                        label: "Placements",
                        route: "placements",
                        element: <Placements {...{cohort}}/>,
                    },
                    false && {
                        label: "Analytics",
                        route: "analytics",
                        element: <Analytics {...{cohort}}/>,
                    },
                    cohort !== "all" && {
                        label: "Logs & Feedback",
                        route: "feedback",
                        element: <Feedback {...{cohort}}/>,
                    },
                    {
                        label: "Map",
                        route: "active",
                        element: <ActivePlacementsMap {...{cohort}}/>,
                    },
                    cohort !== "all" && {
                        label: "Settings",
                        route: "settings",
                        element: <Settings {...{cohort}}/>,
                    },
                    cohort !== "all" && {
                        route: "settings/workflow",
                        element: <Workflow {...{cohort}}/>,
                    },
                    cohort !== "all" && {
                        route: "settings/workflow/customForm/:formType/:userType",
                        element: <CustomWorkflowForm/>,
                    },
                    cohort !== "all" && {
                        route: "settings/workflow/customForm/:formType/:userType",
                        element: <CustomWorkflowForm/>,
                    },
                    cohort !== "all" && {
                        route: "settings/workflow/custom",
                        element: <WorkflowEditorV3 onSubmit={submitCohort} cohortId={cohort.id || ""} initialData={cohort.workflow}/>,
                    },
                ].filter((i) => i) as SubNavBarItems
                }
            />
        </Page>
    );
}

function Overview({cohort}:{cohort: CohortData|"all"}) {
    const [students, setStudents] = useState<number>();
    const [currentPlacements, setCurrentPlacements] = useState<number>();
    const [completedPlacements, setCompletedPlacements] = useState<number>();

    const user = useContext(UserContext);
    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();

    const whereFilters = [where("oId", "==", user.oId)];

    if (cohort !== "all") {
        whereFilters.push(where("cohort", "==", cohort.id));
    }

    useEffect(() => {
        firebaseQuery.getCount("users", whereFilters).then(setStudents);
        firebaseQuery.getCount("placements", [...whereFilters, where("inProgress", "==", false)]).then(setCompletedPlacements);
        firebaseQuery.getCount("placements", [...whereFilters, where("inProgress", "==", true)]).then(setCurrentPlacements);
    }, []);

    if (students === undefined) return null;

    return (
        <Page
            metaTitle={cohort === "all" ? "Overview" : `${cohort.name} | Overview`}
            metaDesc="Desc"
            grid>
            {typeof cohort === "object" && !cohort?.workflow?.length ?
                <Card sx={purpleCardStyle} grid xs={12} md={5} title="Configure your workflow" onClick={() => navigate("../settings/workflow")}>
                    <Typography>Your workflow is a customise pipeline for processing placements. Configure this before activating your user accounts.</Typography>
                    <Button sx={{float: "right"}} color="white">Create workflow</Button>
                </Card> :
                students === 0 ?
                    <Card sx={purpleCardStyle} grid xs={12} md={5} title="Upload your students" onClick={() => navigate("../students")}>
                        <Typography>Upload students to your cohort, allowing them to add their placements.</Typography>
                        <Button sx={{float: "right"}} color="white">Upload students</Button>
                    </Card> :
                    undefined}
            <Grid item xs={12} md={8}>
                <Tasks cohort={cohort === "all" ? undefined : cohort}/>
            </Grid>
            <Card grid xs={12} md={6} lg={4} title={"Overview"}>
                <List>
                    <ListItem style={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                        <ListItemText primary="Students"/>
                        <ListItemSecondaryAction>
                            {students}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem style={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                        <ListItemText primary="Current placements"/>
                        <ListItemSecondaryAction>
                            {currentPlacements}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem style={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                        <ListItemText primary="Completed placements"/>
                        <ListItemSecondaryAction>
                            {completedPlacements}
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Card>
        </Page>
    );
}

function Students({cohort}:{cohort: CohortData|"all"}) {
    const [students, setStudents] = useState<number>();
    const [active, setActive] = useState<number>();
    const [activeNotLoggedIn, setActiveNotLoggedIn] = useState<number>();
    const [activeNotLoggedInPopup, setActiveNotLoggedInPopup] = useState(false);

    const [userUploadPopupActive, setUserUploadPopupActive] = useState(false);
    const [uploadJob, setUploadJob] = useState<string>();

    const jobs = useAppSelector((state) => state.jobs.values) as {[key: string]: Job}|undefined;

    useEffect(() => {
        if (!uploadJob || !jobs) return;

        const job = jobs[uploadJob];
        if (job.status !== "processing") {
            firebaseQuery.getCount("users", [where("oId", "==", user.oId), ...(cohort === "all" ? [] : [where("cohort", "==", cohort.id)]), where("userType", "==", "Students")]).then(setStudents);
        }
    }, [jobs]);

    const navigate = useNavigate();
    const user = useContext(UserContext);
    const {execute} = useExecuteCallableJob({user});

    if (!cohort) return null;

    const studentQuery: QueryObject[]|[] = [{
        path: ["users"],
        where: [["userType", "==", "Students"]],
        orderBy: "documentId",
    }];


    const firebaseQuery = new FirebaseQuery();

    const getUserCounts = () => {
        firebaseQuery.getCount("users", [where("oId", "==", user.oId), ...(cohort === "all" ? [] : [where("cohort", "==", cohort.id)]), where("userType", "==", "Students")]).then(setStudents);
        firebaseQuery.getCount("users", [where("oId", "==", user.oId), ...(cohort === "all" ? [] : [where("cohort", "==", cohort.id)]), where("userType", "==", "Students"), where("status", "==", "active")]).then(setActive);
        firebaseQuery.getCount("users", [where("oId", "==", user.oId), ...(cohort === "all" ? [] : [where("cohort", "==", cohort.id)]), where("userType", "==", "Students"), where("status", "==", "active"), where("active", "==", false)]).then(setActiveNotLoggedIn);
    };

    useEffect(() => {
        if (user.viewCohorts === "all" || user.userGroup === "admin") {
            getUserCounts();
        }
    }, []);

    return (
        <Page
            metaTitle={cohort === "all" ? "Students" : `${cohort.name} | Students`}
            metaDesc="Desc" grid>
            {
                students === 0 && cohort !== "all" ?
                    <>
                        <Stack alignItems={"center"} justifyContent={"center"} flex={1} mt={4}>
                            <img src={emptyBox} style={{maxHeight: 150}}/>
                            <Typography variant="h5" fontWeight={"bold"} textAlign={"center"} mb={5}>No students uploaded</Typography>
                            <Typography variant="subtitle1" textAlign={"center"} width={"500px"} maxWidth={"95%"}>Once you have uploaded students, you will see them here. From then, you can activate them and manage their placements.</Typography>
                            <Button onClick={() => setUserUploadPopupActive(true)} variant="contained">Upload students</Button>
                        </Stack>
                        <UserUploadPopup cohortId={cohort.id} onComplete={setUploadJob} userType="Students" key="Upload students" active={userUploadPopupActive} onToggle={() => setUserUploadPopupActive(false)}/>
                    </> :
                    <>
                        <Grid item xs={12} md={(user.viewCohorts === "all" || user.userGroup === "admin") ? 8 : 12}>
                            <UserTable onUserCountChange={getUserCounts} userType={"Students"} cohortId={cohort === "all" ? "all" : cohort.id} queries={studentQuery} onItemClick={(key:string) => navigate(`/${user.product}/users/${key}`)}/>
                        </Grid>
                        {(user.viewCohorts === "all" || user.userGroup === "admin") && <Card title={"Overview"} grid xs={12} md={4}>
                            <List>
                                <ListItem style={{background: PRIMARY_COLOUR, borderRadius: "20px", marginBottom: "10px", color: "white"}}>
                                    <ListItemText primaryTypographyProps={{fontWeight: "bold"}} primary="Total students"/>
                                    <ListItemSecondaryAction style={{fontWeight: "bold", color: "white"}}>
                                        {students}
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                                    <ListItemText primary="Active students"/>
                                    <ListItemSecondaryAction>
                                        {active}
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {Boolean(activeNotLoggedIn && activeNotLoggedIn > 0) && <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                                    <ListItemText primary="Active, not logged in"/>
                                    <ListItemSecondaryAction>
                                        <Stack direction={"row"} spacing={3} alignItems={"center"}>
                                            <Typography>{activeNotLoggedIn}</Typography>
                                            <Button onClick={() => setActiveNotLoggedInPopup(true)} size="small" color="inherit">View</Button>
                                        </Stack>
                                    </ListItemSecondaryAction>
                                </ListItem>}
                            </List>
                            <Popup title={"Students activated and not logged in"} open={activeNotLoggedInPopup} onClose={() => setActiveNotLoggedInPopup(false)}>
                                <FilterTable
                                    formatQueryData={(data) =>
                                        Object.entries(data as {[key:string]: UserData&{userGroupId: string}}).filter(([, user]) =>
                                            user.userGroup !== "admin").reduce((acc, [uid, user]) => {
                                            return {...acc, [uid]: {...user, ...user.details}};
                                        }, {} as {[key:string]: unknown})
                                    }
                                    disableSelection
                                    columns={["forename", "surname", "email"]} data={[{path: ["users"], where: [["oId", "==", user.oId], ...(cohort === "all" ? [] : [["cohort", "==", cohort.id]]), ["status", "==", "active"], ["userType", "==", "Students"], ["active", "==", false]]}] as QueryObject[]}/>
                                <DialogActions>
                                    <LoadingButton onClick={async () => await executeCallable("userManagement-exportActiveNotSignedInUsers", {userType: "Students", cohort: cohort === "all" ? "all" : cohort.id}).then(async (res) => {
                                        const url = await getDownloadURL(ref(storage, res.data));
                                        window.open(url, "_blank");
                                    })} text="Export as CSV"/>
                                    <LoadingButton onClick={async () => execute("userManagement-activateUsers", {users: [{path: ["users"], where: [["oId", "==", user.oId], ...(cohort === "all" ? [] : [["cohort", "==", cohort.id]]), ["status", "==", "active"], ["userType", "==", "Students"], ["active", "==", false]]}], userType: "Students", cohort: cohort === "all" ? undefined : cohort.id})} text="Resend activation email"/>
                                </DialogActions>
                            </Popup>
                        </Card>
                        }
                    </>
            }
        </Page>
    );
}

function Placements({cohort}:{cohort: CohortData|"all"}) {
    const [inWorkflow, setInWorkflow] = useState<number>(0);
    const [complete, setComplete] = useState<number>(0);
    const [scheduled, setScheduled] = useState<number>(0);
    const [active, setActive] = useState<number>(0);

    const navigate = useNavigate();
    const user = useContext(UserContext);
    const firebaseQuery = new FirebaseQuery();
    const viewAccess = getAccess(user, "viewStudents");

    const [searchParams] = useSearchParams();
    const filters = Object.fromEntries([...searchParams]);
    const filterElementId = filters.id;
    delete filters.id;

    const whereFilters = [where("oId", "==", user.oId), where("draft", "==", false)];

    if (cohort !== "all") {
        whereFilters.push(where("cohort", "==", cohort.id));
    }

    useEffect(() => {
        firebaseQuery.getCount("placements", [...whereFilters, where("inProgress", "==", true),
            ...(viewAccess === "roles" ? [where("staffRoles", "array-contains-any", user.staffRoles)] : [])]).then(setInWorkflow);

        firebaseQuery.getCount("placements", [...whereFilters, where("inProgress", "==", false),
            ...(viewAccess === "roles" ? [where("staffRoles", "array-contains-any", user.staffRoles)] : [])]).then(setComplete);

        firebaseQuery.getCount("placements", [...whereFilters, where("status", "==", 6),
            ...(viewAccess === "roles" ? [where("staffRoles", "array-contains-any", user.staffRoles)] : [])]).then(setScheduled);

        firebaseQuery.getCount("placements", [...whereFilters, where("active", "==", true),
            ...(viewAccess === "roles" ? [where("staffRoles", "array-contains-any", user.staffRoles)] : [])]).then(setActive);
    }, []);

    return (
        <Page
            metaTitle={cohort === "all" ? "Placements" : `${cohort.name} | Placements`}
            metaDesc="View student placements"
            grid>
            <Grid container item md={12} lg={8}>
                <Grid item xs={12}>
                    <PlacementList id={"upcoming"} inProgress urlRef={"upcoming"} key={"inProgressPlacements"} cohort={cohort} sx={{minHeight: "500px", maxHeight: "550px"}} title={"In progress"} queryConstraint={[where("inProgress", "==", true), where("draft", "==", false)]} filters={filterElementId === "upcoming" ? filters : undefined}/>
                </Grid>
            </Grid>
            <Grid container item md={12} lg={4}>
                <Card grid xs={12} md={6} lg={12} title={[<Typography variant={"h5"}>Overview</Typography>, <Button onClick={() => navigate("../active")}>View map</Button>]}>
                    <List>
                        <ListItem style={{background: active > 0 ? PRIMARY_COLOUR : "#00000010", borderRadius: "20px", marginBottom: "10px", color: active ? "white" : undefined}}>
                            <ListItemText primaryTypographyProps={{fontWeight: active && "bold"}} primary="Active"/>
                            <ListItemSecondaryAction style={{fontWeight: active && "bold", color: active ? "white" : undefined}}>
                                <Stack direction={"row"} alignItems={"center"}>
                                    {active}
                                    <IconButtonPop onClick={() => navigate("?id=upcoming&active=true")} title="View" responsive={false} ><ArrowForward htmlColor="white"/></IconButtonPop>
                                </Stack>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                            <ListItemText primary="In workflow"/>
                            <ListItemSecondaryAction>
                                <Stack direction={"row"} alignItems={"center"}>
                                    {inWorkflow}
                                    <IconButtonPop onClick={() => navigate("?id=upcoming&inProgress=true")} title="View" responsive={false}><ArrowForward/></IconButtonPop>
                                </Stack>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                            <ListItemText primary="Scheduled"/>
                            <ListItemSecondaryAction>
                                <Stack direction={"row"} alignItems={"center"}>
                                    {scheduled}
                                    <IconButtonPop onClick={() => navigate("?id=upcoming&status=6")} title="View" responsive={false}><ArrowForward/></IconButtonPop>
                                </Stack>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem sx={{background: "#00000010", borderRadius: "20px"}}>
                            <ListItemText primary="Completed"/>
                            <ListItemSecondaryAction>
                                {complete}
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Card>
                <Grid item xs={12} md={6} lg={12}>
                    <PlacementList id={"completed"} inProgress={false} urlRef={"completed"} key={"completedPlacements"} cohort={cohort} sx={{minHeight: "300px", maxHeight: "400px"}} title={"Completed placements"} queryConstraint={[where("inProgress", "==", false), where("draft", "==", false)]} filters={filterElementId === "completed" ? filters : undefined}/>
                </Grid>
            </Grid>
        </Page>
    );
}

function Analytics({cohort}:{cohort: CohortData|"all"}) {
    const [inWorkflow, setInWorkflow] = useState<number>(0);
    const [complete, setComplete] = useState<number>(0);
    const [scheduled, setScheduled] = useState<number>(0);
    const [active, setActive] = useState<number>(0);
    const [exportPlacementDataPopup, setExportPlacementDataPopup] = useState(false);

    const navigate = useNavigate();
    const user = useContext(UserContext);
    const firebaseQuery = new FirebaseQuery();
    const viewAccess = getAccess(user, "viewStudents");

    const [searchParams] = useSearchParams();
    const filters = Object.fromEntries([...searchParams]);
    delete filters.id;

    const whereFilters = [where("oId", "==", user.oId), where("draft", "==", false)];

    if (cohort !== "all") {
        whereFilters.push(where("cohort", "==", cohort.id));
    }

    useEffect(() => {
        firebaseQuery.getCount("placements", [...whereFilters, where("inProgress", "==", true),
            ...(viewAccess === "roles" ? [where("staffRoles", "array-contains-any", user.staffRoles)] : [])]).then(setInWorkflow);

        firebaseQuery.getCount("placements", [...whereFilters, where("inProgress", "==", false),
            ...(viewAccess === "roles" ? [where("staffRoles", "array-contains-any", user.staffRoles)] : [])]).then(setComplete);

        firebaseQuery.getCount("placements", [...whereFilters, where("status", "==", 6),
            ...(viewAccess === "roles" ? [where("staffRoles", "array-contains-any", user.staffRoles)] : [])]).then(setScheduled);

        firebaseQuery.getCount("placements", [...whereFilters, where("active", "==", true),
            ...(viewAccess === "roles" ? [where("staffRoles", "array-contains-any", user.staffRoles)] : [])]).then(setActive);
    }, []);

    return (
        <Page
            metaTitle={cohort === "all" ? "Analytics" : `${cohort.name} | Analytics`}
            metaDesc=""
            grid>
            <Card grid xs={12} md={8} title={"Data export"}>
                {false && <Button>Export user data</Button>}
                <Button onClick={() => setExportPlacementDataPopup(true)}>Export placement data</Button>
            </Card>
            <Card grid xs={12} md={4} title={[<Typography variant={"h5"}>Overview</Typography>, <Button onClick={() => navigate("../active")}>View map</Button>]}>
                <List>
                    <ListItem style={{background: active > 0 ? PRIMARY_COLOUR : "#00000010", borderRadius: "20px", marginBottom: "10px", color: active ? "white" : undefined}}>
                        <ListItemText primaryTypographyProps={{fontWeight: active && "bold"}} primary="Active"/>
                        <ListItemSecondaryAction style={{fontWeight: active && "bold", color: active ? "white" : undefined}}>
                            {active}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                        <ListItemText primary="In workflow"/>
                        <ListItemSecondaryAction>
                            {inWorkflow}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                        <ListItemText primary="Scheduled"/>
                        <ListItemSecondaryAction>
                            {scheduled}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem sx={{background: "#00000010", borderRadius: "20px"}}>
                        <ListItemText primary="Completed"/>
                        <ListItemSecondaryAction>
                            {complete}
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Card>
            <DataExportPopup type="placements" cohort={cohort} open={exportPlacementDataPopup} onClose={() => setExportPlacementDataPopup(false)}/>
        </Page>
    );
}


function Feedback({cohort}:{cohort: CohortData}) {
    const [cohortData, setCohortData] = useState(cohort);
    const [deleteLogOrFeedback, setDeleteLogOrFeedback] = useState<{formType: "feedback"|"logs", userType: "students"|"staff"|"provider"}>();
    const [snackbar, setSnackbar] = useState<{ open: boolean; message?: string}>({open: false});

    const [sendFeedbackPopup, setSendFeedbackPopup] = useState<"students"|"provider">();

    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();


    const sendForm = async (type: "students"|"provider", sendToAll?: boolean) => {
        // Send form to oId, cohortId, all that haven't completed form.
        console.log(type);

        const lastSent = cohortData[`${type}FeedbackSent`];

        if (lastSent) {
            const previousEmailTime = new Date(lastSent.seconds * 1000);
            const today = new Date();
            const timeSinceEmail = getDateDiff(previousEmailTime, today);

            if (timeSinceEmail === 0) {
                setSnackbar({open: true, message: "Emails can only be sent after 1 day."});
                return;
            }
        }

        await executeCallable("placement-sendFeedbackReminder", {cohortId: cohort.id, type: type, sendToAll: sendToAll});
        setCohortData((d) => ({...d, [`${type}FeedbackSent`]: new Date()}));
    };


    return (
        <Page
            metaTitle={`${cohort.name} | Settings`}
            metaDesc=""
            grid>
            <Card grid md={6} title={"Placement daily logs"} >
                {cohort.logType === "custom" ? <Stack spacing={1}>
                    <Typography>Create custom logs for staff, students and employers.</Typography>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{borderRadius: 1, border: "1px solid rgba(0, 0, 0, 0.12)"}} p={1}>
                        <Typography>Student log</Typography>
                        {cohort?.logs?.students ? <><Chip color="primary" label={"Custom"}/><Stack direction={"row"} alignItems={"center"}><Button onClick={() => navigate("workflow/customForm/logs/students", {state: {cohort: cohort, from: "settings"}})}>Edit log</Button><IconButtonPop responsive={false} title="Delete log" onClick={() => setDeleteLogOrFeedback({formType: "logs", userType: "students"})}><Delete/></IconButtonPop></Stack></> : <><Chip label={"Default"}/><Button onClick={() => navigate("workflow/customForm/logs/students", {state: {cohort: cohort, from: "settings"}})}>Customise</Button></>}
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{borderRadius: 1, border: "1px solid rgba(0, 0, 0, 0.12)"}} p={1}>
                        <Typography>Employer log</Typography>
                        {cohort?.logs?.provider ? <><Chip color="primary" label={"Custom"}/><Stack direction={"row"} alignItems={"center"}><Button onClick={() => navigate("workflow/customForm/logs/provider", {state: {cohort: cohort, from: "settings"}})}>Edit log</Button><IconButtonPop responsive={false} title="Delete log" onClick={() => setDeleteLogOrFeedback({formType: "logs", userType: "provider"})}><Delete/></IconButtonPop></Stack></> : <><Chip label={"Default"}/><Button onClick={() => navigate("workflow/customForm/logs/provider", {state: {cohort: cohort, from: "settings"}})}>Customise</Button></>}
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{borderRadius: 1, border: "1px solid rgba(0, 0, 0, 0.12)"}} p={1}>
                        <Typography>Staff log</Typography>
                        {cohort?.logs?.staff ? <><Chip color="primary" label={"Custom"}/><Stack direction={"row"} alignItems={"center"}><Button onClick={() => navigate("workflow/customForm/logs/staff", {state: {cohort: cohort, from: "settings"}})}>Edit log</Button><IconButtonPop responsive={false} title="Delete log" onClick={() => setDeleteLogOrFeedback({formType: "logs", userType: "staff"})}><Delete/></IconButtonPop></Stack></> : <><Chip label={"Default"}/><Button onClick={() => navigate("workflow/customForm/logs/staff", {state: {cohort: cohort, from: "settings"}})}>Customise</Button></>}
                    </Stack>
                    <Typography sx={{cursor: "pointer"}} color={"grey"} textAlign={"center"} fontSize={14} pt={1} onClick={() => cohort.id && firebaseQuery.update(["cohorts", cohort.id], {logType: "basic"})}>Switch to basic daily log</Typography>
                </Stack> :
                    <Stack alignItems={"center"}>
                        <Typography>Use our basic log, consisting of a text box for users to add any text, and a file uploader for images or PDF documents. Users will fill a separate log each day.</Typography>
                        <Button variant="contained" onClick={() => cohort.id && firebaseQuery.update(["cohorts", cohort.id], {logType: "custom"})}>Create custom daily log questions</Button>
                    </Stack>}
            </Card>
            <Card grid md={6} title={"Placement feedback"}>
                <Stack spacing={1}>
                    <Typography>Create custom feedback forms for students, staff and employers. These will be shared with users after a placement finishes.</Typography>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{borderRadius: 1, border: "1px solid rgba(0, 0, 0, 0.12)"}} p={1}>
                        <Typography>Student feedback</Typography>
                        {cohort?.feedback?.students ? <Stack direction={"row"} alignItems={"center"}><Button onClick={() => navigate("workflow/customForm/feedback/students", {state: {cohort: cohort, from: "settings"}})}>Edit feedback</Button><Button onClick={() => setSendFeedbackPopup("students")}>Resend form</Button><IconButtonPop responsive={false} title="Delete" onClick={() => setDeleteLogOrFeedback({formType: "feedback", userType: "students"})}><Delete/></IconButtonPop></Stack> : <><Chip label={"Inactive"}/><Button onClick={() => navigate("workflow/customForm/feedback/students", {state: {cohort: cohort, from: "settings"}})}>Create</Button></>}
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{borderRadius: 1, border: "1px solid rgba(0, 0, 0, 0.12)"}} p={1}>
                        <Typography>Employer feedback</Typography>
                        {cohort?.feedback?.provider ? <Stack direction={"row"} alignItems={"center"}><Button onClick={() => navigate("workflow/customForm/feedback/provider", {state: {cohort: cohort, from: "settings"}})}>Edit feedback</Button><Button onClick={() => setSendFeedbackPopup("provider")}>Resend form</Button><IconButtonPop responsive={false} title="Delete" onClick={() => setDeleteLogOrFeedback({formType: "feedback", userType: "provider"})}><Delete/></IconButtonPop></Stack> : <><Chip label={"Inactive"}/><Button onClick={() => navigate("workflow/customForm/feedback/provider", {state: {cohort: cohort, from: "settings"}})}>Create</Button></>}
                    </Stack>
                    {/* false && <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{borderRadius: 1, border: "1px solid rgba(0, 0, 0, 0.12)"}} p={1}>
                        <Typography>Staff feedback</Typography>
                        {cohort?.feedback?.staff ? <Stack direction={"row"} alignItems={"center"}><Button onClick={() => navigate("workflow/customForm/feedback/staff", {state: {cohort: cohort, from: "settings"}})}>Edit feedback</Button><LoadingButton noFlexGrow text="Resend form" onClick={() => sendForm("staff")}/><IconButtonPop responsive={false} title="Delete" onClick={() => setDeleteLogOrFeedback({formType: "feedback", userType: "staff"})}><Delete/></IconButtonPop></Stack> : <><Chip label={"Inactive"}/><Button onClick={() => navigate("workflow/customForm/feedback/staff", {state: {cohort: cohort, from: "settings"}})}>Create</Button></>}
                                </Stack>*/}
                </Stack>
            </Card>
            {<Popup title={`Delete ${deleteLogOrFeedback?.userType} ${deleteLogOrFeedback?.formType}`} open={Boolean(deleteLogOrFeedback)} onClose={() => setDeleteLogOrFeedback(undefined)}>
                <Typography>Deleting this form will unlink it from your cohort. You can delete it permanently through the Setup tab.</Typography>
                <LoadingButton onClick={async () => cohort.id && await firebaseQuery.update(["cohorts", cohort.id], {[`${deleteLogOrFeedback?.formType}.${deleteLogOrFeedback?.userType}`]: deleteField()})} text="Delete form"/>
            </Popup>}
            <Snackbar
                open={snackbar.open}
                onClose={() => setSnackbar((s) => ({...s, open: false}))}
                message={snackbar.message}
                autoHideDuration={6000}
                action={<IconButtonPop
                    title={"Close"}
                    responsive={false}
                    onClick={() => setSnackbar((s) => ({...s, open: false}))}
                >
                    <Close fontSize="small" color='inherit'/>
                </IconButtonPop>}
            />
            <Popup title={`Send ${sendFeedbackPopup} feedback`} open={Boolean(sendFeedbackPopup)} onClose={() => setSendFeedbackPopup(undefined)}>
                <Typography>Send feedback forms via email to your placement providers. They will be directed to Placementt to review the feedback you set up.</Typography>
                <br/>
                <LoadingButton>
                    <Button onClick={() => sendFeedbackPopup && sendForm(sendFeedbackPopup, true)}>Send to all.</Button>
                    <Button onClick={() => sendFeedbackPopup && sendForm(sendFeedbackPopup)}>Send only to completed placements</Button>
                </LoadingButton>
            </Popup>
        </Page>
    );
}


function Settings({cohort}:{cohort: CohortData}) {
    const [placementDetailsPopupActive, setPlacementDetailsPopupActive] = useState(false);
    const [cohortData, setCohortData] = useState(cohort);
    const [deletePopup, setDeletePopup] = useState(false);
    const [emailTemplatePopupOpen, setEmailTemplatePopupOpen] = useState<string|false>(false);

    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();

    const organisation = useContext(OrganisationContext);
    const addresses = organisation.addresses as {[key: string]: OrganisationAddress};
    const orgEmailTemplates = organisation.emailTemplates as {[key: string]: EmailTemplate};

    const deleteCohort = async (type: "delete"|"deactivate") => {
        return await executeCallable("userManagement-deleteCohort", {id: cohort.id, type: type});
    };

    const associatedAddress = Object.entries(addresses).find(([key]) => cohort.addressId === key)?.[1];

    return (
        <Page
            metaTitle={`${cohort.name} | Settings`}
            metaDesc=""
            grid>
            <Card grid md={8} title={"Placement settings"} secondaryTitle={<IconButtonPop title="Edit" onClick={() => setPlacementDetailsPopupActive(true)}><Edit/></IconButtonPop>}>
                {cohort.placementType === "defined" &&
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Cohort name</TableCell>
                            <TableCell>{cohort.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Associated address</TableCell>
                            <TableCell>{associatedAddress?.name}<Typography sx={{opacity: 0.7}}>{associatedAddress?.["address-line1"]} | {associatedAddress?.postal_code}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Placement type</TableCell>
                            <TableCell><Chip color="primary" label={capitalise(cohort.placementType)}/></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Submission dates</TableCell>
                            <TableCell>{convertDate(cohort.startSubmission, "visual") as string} - {convertDate(cohort.endSubmission, "visual") as string}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Placement dates</TableCell>
                            <TableCell>{convertDate(cohort.startPlacements, "visual") as string} - {convertDate(cohort.endPlacements, "visual") as string}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>}
                <Button sx={{mt: 2}} color="error" onClick={() => setDeletePopup(true)}>Archive or Delete cohort</Button>
            </Card>
            <Card grid sm={12} md={4} title={"Workflow"} secondaryTitle={<Button onClick={() => navigate("workflow")}>Edit workflow</Button>} sx={{height: "300px"}}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Stages</TableCell>
                            <TableCell>{cohort?.workflow?.length || "Not yet configured"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Required user types</TableCell>
                            <TableCell>
                                <Stack direction={"row"} spacing={1}>
                                    {cohort.workflow ? cohort.workflow.reduce((acc, stage) => {
                                        if (!stage.userType || acc.includes(stage.userType)) return acc;
                                        acc.push(stage.userType);
                                        return acc;
                                    }, [] as Array<string>).map((item) => <Chip label={item}/>) : "Not yet configured"}
                                </Stack>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
            <Card title={"Email templates"} subtitle="Assign custom templates to our emails to personalise your communications with students, parents and businesses." grid xs={12} md={4}>
                <List>
                    {Object.keys(emailTemplates).map((template) =>
                        <ListItem divider>
                            <ListItemText
                                primary={camelCaseToNormal(template)}
                                secondary={cohort.emailTemplates?.[template] ? orgEmailTemplates[cohort.emailTemplates?.[template]].name : undefined}
                            />
                            <ListItemSecondaryAction>
                                {cohort.emailTemplates?.[template] ? <Stack direction={"row"} alignItems={"center"}><IconButtonPop onClick={() => setEmailTemplatePopupOpen(template)} responsive={false} title="Edit template"><Edit/></IconButtonPop><IconButtonPop responsive={false} onClick={async () => cohort.id && firebaseQuery.update(["cohorts", cohort.id], {[`emailTemplates.${template}`]: deleteField()})} title="Delete template"><Delete/></IconButtonPop></Stack> : <Stack direction={"row"} alignItems={"center"}><Chip label={"Default"}/><IconButtonPop responsive={false} title="Select template" onClick={() => setEmailTemplatePopupOpen(template)}><Add/></IconButtonPop></Stack>}
                            </ListItemSecondaryAction>
                        </ListItem>)}
                </List>
                <EmailTemplates defaultSelected={(emailTemplatePopupOpen && cohort.emailTemplates?.[emailTemplatePopupOpen]) ? [cohort.emailTemplates?.[emailTemplatePopupOpen]] : undefined} onSubmitSelection={async (e) => (cohort.id && emailTemplatePopupOpen) && firebaseQuery.update(["cohorts", cohort.id], {[`emailTemplates.${emailTemplatePopupOpen}`]: e[0]}).then(() => setEmailTemplatePopupOpen(false))} popup emailTemplate={emailTemplatePopupOpen || undefined} open={Boolean(emailTemplatePopupOpen)} onClose={() => setEmailTemplatePopupOpen(false)} selectable/>
            </Card>
            <Popup open={placementDetailsPopupActive} onClose={() => setPlacementDetailsPopupActive(false)} title={"Edit placement submission details"}>
                <Form onSubmit={async () => firebaseQuery.update(["cohorts", cohort.id as string], cohortData)}>
                    <InputGroup required name={"name"} label={"Cohort name"} value={cohortData.name} onChange={(e: ChangeEvent<HTMLInputElement>) => setCohortData((p) => ({...p, name: e.target.value} as CohortData))}/>
                    <Dropdown required name="addressId" label="Address" value={cohortData?.addressId} onChange={(e) => setCohortData((p) => ({...p, addressId: e.target.value} as CohortData))}>
                        {Object.entries(addresses).map(([key, address]) => <MenuItem value={key}>
                            <Stack spacing={0}>
                                <Typography>{address.name}</Typography>
                                <Typography variant="caption">{address?.["address-line1"]} | {address?.postal_code}</Typography>
                            </Stack>
                        </MenuItem>)}
                    </Dropdown>
                    <Tabs
                        value={["defined", "continuous"].indexOf(cohortData?.placementType || "defined")}
                        onChange={(_, n) => setCohortData((p) => ({...p, placementType: (["defined", "continuous"][n])} as CohortData))}
                        centered
                        variant="standard">
                        <Tab label="Defined"/>
                        <Tab label="Continuous"/>
                    </Tabs>
                    {
                        cohortData?.placementType === "defined" ?
                            <>
                                <Typography color={"rgba(0, 0, 0, 0.75)"} textAlign={"center"}>Placements can only be submitted and attended within a defined period of time.</Typography>
                                <DateRangePicker name={"submission"} label="Placement submission dates" required start={cohortData?.startSubmission} end={cohortData?.endSubmission} onChange={(date, type) => setCohortData((p) => ({...p, [`${type}Submission`]: date} as CohortData))}/>
                                <DateRangePicker name={"placements"} label="Available placement dates" required start={cohortData?.startPlacements} end={cohortData?.endPlacements} onChange={(date, type) => setCohortData((p) => ({...p, [`${type}Placements`]: date} as CohortData))}/>
                            </> :
                            <>
                                <Typography color={"rgba(0, 0, 0, 0.75)"} textAlign={"center"}>Placements can be created and attended at any point from a student's account activation.</Typography>
                            </>
                    }
                </Form>
            </Popup>
            <Popup title={`Delete cohort: ${cohortData.name}`} open={deletePopup} onClose={() => setDeletePopup(false)}>
                You can either archive or permanently delete your cohort.
                <LoadingButton>
                    <Button onClick={async () => deleteCohort("deactivate").then(() => navigate("../../archive"))}>Archive</Button>
                    <Button color="error" onClick={() => deleteCohort("delete")}>Delete permanently</Button>
                </LoadingButton>
            </Popup>
        </Page>
    );
}


function Workflow({cohort}:{cohort: CohortData}) {
    const location = useLocation();
    const navigate = useNavigate();

    cohort = {...cohort, ...location.state?.cohort} || cohort;
    console.log("cohort", cohort);
    const imported = location.state?.imported || false;
    if (!cohort.id) return null;

    const [activeStep, setActiveStep] = useState<number>(location.state?.page || 0);
    const [forms, setForms] = useState<{[key:string]: {name: string}}>({});
    const [logType, setLogType] = useState<"basic"|"custom">(cohort.logType || "basic");

    const [employerInfoPopup, setEmployerInfoPopup] = useState(false);
    const [eliInfoPopup, setEliInfoPopup] = useState(false);
    const [riskAssessmentInfoPopup, setRiskAssessmentInfoPopup] = useState(false);
    const [dbsCheckInfoPopup, setDbsCheckInfoPopup] = useState(false);

    const [formInfoPopup, setFormInfoPopup] = useState(false);
    const [fileInfoPopup, setFileInfoPopup] = useState(false);
    const [importWorkflowPopup, setImportWorkflowPopup] = useState(false);


    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);
    const org = useContext(OrganisationContext);

    useEffect(() => {
        setForms(org.forms as {[key:string]: {name: string}});
    }, []);

    const submitCompleteWorkflow = (e:{workflow: WorkflowStage[], includedFiles?: string[], includedForms?: string[]}) => {
        if (!cohort.id) return;
        console.log("included forms", e.includedForms);
        firebaseQuery.update(["cohorts", cohort.id], {...cohort, logType: logType, workflow: e.workflow, forms: [...(e.includedForms || []), ...Object.values(cohort.feedback || {}).map((v) => v)], files: e.includedFiles});
        navigate("../settings", {state: {cohort: {...cohort, workflow: e.workflow, forms: [...(e.includedForms || []), ...Object.values(cohort.feedback || {}).map((v) => v)], files: e.includedFiles, logType: logType}}});
    };

    const {workflowNodes, addNode, onDelete, onChange, includedFiles, includedForms, submitWorkflow} = useWorkflowEditor({user: user, onSubmit: submitCompleteWorkflow, initialData: cohort.workflow, cohortId: cohort.id});

    const navigateToFormBuilder = (userType: string, formType: "log"|"feedback") => {
        if (!cohort.id) return;
        firebaseQuery.update(["cohorts", cohort.id], {workflow: workflowNodes, files: includedFiles, forms: [...includedForms, ...Object.values(cohort.feedback || {}).map((v) => v)]});
        navigate(`customForm/${formType}/${userType}`, {state: {cohort: {...cohort, workflow: workflowNodes, files: includedFiles, forms: [...includedForms, ...Object.values(cohort.feedback || {}).map((v) => v)], logType: logType}}});
    };
    if (!workflowNodes.length) return null;

    if (cohort.customWorkflow) {
        navigate("./custom");
        return null;
    }

    return (
        <Page fullHeight noOverflow>
            <Breadcrumbs sx={{padding: 2}}>
                <Link
                    style={{color: "grey"}}
                    to="../settings"
                >
                        Settings
                </Link>
                <Typography color="text.primary">Workflow</Typography>
            </Breadcrumbs>
            <VerticalPaginator items={[
                {
                    label: "Configuring your workflow",
                    element: <Card title={"Configuring your workflow"}>
                        <Typography>The following pages will take you through setting up your workflow.</Typography>
                        <br/>
                        <Typography>Your workflow is the automated stages we take to manage all your placement admin.</Typography>
                        <br/>
                        <Typography>Follow this tutorial to learn what information we collect, and configure it to your needs.</Typography>
                        <Stack direction={"row"} justifyContent={"end"} alignItems={"center"} pt={3}>
                            {imported ? <Typography border={"lightgrey 1px solid"} p={1} borderRadius={2}>Workflow imported from {imported}</Typography> : Object.entries((org.cohorts || {}) as {[k: string]: CohortData}).filter(([fId, fCohort]) => !fCohort.customWorkflow && fId !== cohort.id).length > 0 && <Button onClick={() => setImportWorkflowPopup(true)}>Import workflow</Button>}
                            <Button onClick={() => setActiveStep(1)} variant="contained">Start</Button>
                        </Stack>
                        <Typography mt={2}>Need complete customisation? <Link to={"./custom"}>Click here</Link></Typography>
                    </Card>,
                },
                {
                    label: "Pre-placement stages",
                    element: <Card title={"Pre-placement signoff"} sx={{maxHeight: "100%", position: "relative"}}>
                        <Xarrow strokeWidth={2.5} start={"submission"} end={"employerReview"} color={PRIMARY_COLOUR}/>
                        <Xarrow strokeWidth={2.5} start={"employerReview"} end={"parentReview"} color={"#38383b"}/>
                        <Xarrow strokeWidth={2.5} start={"parentReview"} end={"studentReview"} color={"#38383b"}/>
                        <Xarrow strokeWidth={2.5} start={"studentReview"} end={"staffReview"} color={"#38383b"}/>
                        <Typography mb={2}>By default, we require the employer and staff to sign off on the placement. Optionally, you can add parental consent. Learn what information we collect from employers on the next page.</Typography>
                        <Box id={"submission"} sx={{textAlign: "center", color: PRIMARY_COLOUR, borderRadius: 3, border: `2px solid ${PRIMARY_COLOUR}`, boxShadow: "black 4px 3px 8px -4px"}} p={2}>Student submits placement</Box>
                        <Box id={"employerReview"} sx={{marginTop: 5, textAlign: "center", borderRadius: 3, border: "2px solid #38383b", boxShadow: "black 4px 3px 8px -4px"}} p={2}>Employer review</Box>
                        <Box id={"parentReview"} onClick={() => workflowNodes.find((node) => node.userType === "Parent") ? onDelete(workflowNodes.find((node) => node.userType === "Parent")?.id) : addNode({userType: "Parent", prevStage: 3, nextStage: workflowNodes.find((node) => node.userType === "Students" && node.id !== 1)?.id || 2})} sx={{cursor: "pointer", marginTop: 3, opacity: workflowNodes.find((node) => node.userType === "Parent") ? 1 : 0.75, textAlign: "center", borderRadius: 3, border: "2px solid #38383b", boxShadow: "black 4px 3px 8px -4px"}} p={1}>
                            <FormControlLabel sx={{p: 0}} label={"Parental review"} control={<Checkbox checked={Boolean(workflowNodes.find((node) => node.userType === "Parent"))}/>}/>
                        </Box>
                        <Box id={"studentReview"} onClick={() => workflowNodes.find((node) => node.userType === "Students" && node.id !== 1) ? onDelete(workflowNodes.find((node) => node.userType === "Students" && node.id !== 1)?.id) : addNode({userType: "Students", prevStage: workflowNodes.find((node) => node.userType === "Parent")?.id || 3, nextStage: 2})} sx={{cursor: "pointer", marginTop: 3, opacity: workflowNodes.find((node) => node.userType === "Students" && node.id !== 1) ? 1 : 0.75, textAlign: "center", borderRadius: 3, border: "2px solid #38383b", boxShadow: "black 4px 3px 8px -4px"}} p={1}>
                            <FormControlLabel sx={{p: 0}} label={"Student review"} control={<Checkbox checked={Boolean(workflowNodes.find((node) => node.userType === "Students" && node.id !== 1))}/>}/>
                        </Box>
                        <Box id={"staffReview"} sx={{marginTop: 3, textAlign: "center", borderRadius: 3, border: "2px solid #38383b", boxShadow: "black 4px 3px 8px -4px"}} p={2}>Staff review</Box>

                        <Stack direction={"row"} justifyContent={"end"} alignItems={"center"} pt={3}>
                            <Button onClick={() => setActiveStep((a) => a-1)}>Back</Button>
                            {cohort.workflow.length > 0 && <Button onClick={() => submitWorkflow()}>Save and exit</Button>}
                            <Button onClick={() => setActiveStep((a) => a+1)} variant="contained">Next</Button>
                        </Stack>
                        <Typography mt={2}>Need complete customisation? <Link to={"custom"}>Click here</Link></Typography>
                    </Card>,
                },
                {
                    label: "Employer review",
                    element: <Card title={"Employer review"}>
                        <Typography>Configure what information we retrieve from your placement providers.</Typography>
                        <Typography color={PRIMARY_COLOUR} sx={{cursor: "pointer", padding: 1}} onClick={() => setEmployerInfoPopup(true)}>What information do we already collect?</Typography>
                        <Divider/>
                        <Typography fontWeight={"bold"} mt={1}>Optional</Typography>
                        <FormControlLabel key={"eli"} label={<Typography>Require employers liability insurance <IconButtonPop responsive={false} title="Click to learn more" onClick={() => setEliInfoPopup(true)}><HelpOutline/></IconButtonPop></Typography>} control={<Checkbox onChange={(e) => onChange([3, "eli"], e.target.checked)} key={"eliCheckbox"} checked={workflowNodes.find((n) => n.id === 3)?.eli}/>}/>
                        <FormControlLabel key={"riskAssessment"} label={<Typography>Require risk assessment <IconButtonPop responsive={false} title="Click to learn more" onClick={() => setRiskAssessmentInfoPopup(true)}><HelpOutline/></IconButtonPop></Typography>} control={<Checkbox onChange={(e) => onChange([3, "riskAssessment"], e.target.checked)} key={"riskAssessmentCheckbox"} checked={workflowNodes.find((n) => n.id === 3)?.riskAssessment}/>}/>
                        <FormControlLabel key={"dbsCheck"} label={<Typography>Require DBS check <IconButtonPop responsive={false} title="Click to learn more" onClick={() => setDbsCheckInfoPopup(true)}><HelpOutline/></IconButtonPop></Typography>} control={<Checkbox onChange={(e) => onChange([3, "dbsCheck"], e.target.checked)} key={"dbsCheckCheckbox"} checked={workflowNodes.find((n) => n.id === 3)?.dbsCheck}/>}/>
                        <Divider/>
                        <Typography mt={1}>Select custom forms for the employer to complete <IconButtonPop responsive={false} title="Click to learn more" onClick={() => setFormInfoPopup(true)}><HelpOutline/></IconButtonPop></Typography>
                        <Dropdown
                            label='Forms'
                            multiple
                            key={"employerForms"}
                            value={workflowNodes.find((n) => n.id === 3)?.forms}
                            onChange={(e) => onChange([3, "forms"], (e.target.value as unknown as unknown[]).filter((x: unknown) => x !== undefined))}>
                            {Object.entries(forms).map(([key, e]) => {
                                return (<MenuItem value={key}>{e.name}</MenuItem>);
                            })}
                            <Divider/>
                            <MenuItem onClick={()=>window.open(`/${user.product}/organisation/resources/f`, "_blank")}>
                                {"Create form"}
                            </MenuItem>
                        </Dropdown>
                        <Typography mt={1}>Select files for the employer to review <IconButtonPop responsive={false} title="Click to learn more" onClick={() => setFileInfoPopup(true)}><HelpOutline/></IconButtonPop></Typography>
                        <FileSelectorInput
                            files={workflowNodes.find((n) => n.id === 3)?.files}
                            onChange={(f) => {
                                onChange([3, "files"], f.filter((x) => x !== undefined));
                            }}/>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} pt={3}>
                            <Button onClick={() => setActiveStep((a) => a-1)}>Back</Button>
                            {cohort.workflow.length > 0 && <Button onClick={() => submitWorkflow()}>Save and exit</Button>}
                            <Button onClick={() => setActiveStep((a) => a+1)} variant="contained">Next</Button>
                        </Stack>
                        <Typography mt={2}>Need complete customisation? <Link to={"custom"}>Click here</Link></Typography>
                    </Card>,
                },
                workflowNodes.find((node) => node.userType === "Parent") && {
                    label: "Parental review",
                    element: <Card title={"Parental review"}>
                        <Typography>Customise what, if any, forms and files parents may need to complete when reviewing their childs placement.</Typography>
                        <Typography mt={1} mb={1} color={PRIMARY_COLOUR}>Parents will consent to the placement by clicking "Accept", so there is no need to add this.</Typography>
                        <Divider/>
                        <Typography mt={1}>Select custom forms for parents to complete <IconButtonPop responsive={false} title="Click to learn more" onClick={() => setFormInfoPopup(true)}><HelpOutline/></IconButtonPop></Typography>
                        <Dropdown
                            label='Forms'
                            multiple
                            key={"parentForms"}
                            value={workflowNodes.find((n) => n.userType === "Parent")?.forms}
                            onChange={(e) => onChange([workflowNodes.find((n) => n.userType === "Parent")?.id || "", "forms"], (e.target.value as unknown as unknown[]).filter((x: unknown) => x !== undefined))}>
                            {Object.entries(forms).map(([key, e]) => {
                                return (<MenuItem value={key}>{e.name}</MenuItem>);
                            })}
                            <Divider/>
                            <MenuItem onClick={()=>window.open(`/${user.product}/organisation/resources/f`, "_blank")}>
                                {"Create form"}
                            </MenuItem>
                        </Dropdown>
                        <Typography mt={1}>Select files for parents to review <IconButtonPop responsive={false} title="Click to learn more" onClick={() => setFileInfoPopup(true)}><HelpOutline/></IconButtonPop></Typography>
                        <FileSelectorInput
                            files={workflowNodes.find((n) => n.userType === "Parent")?.files}
                            onChange={(f) => {
                                onChange([workflowNodes.find((n) => n.userType === "Parent")?.id || "", "files"], f.filter((x) => x !== undefined));
                            }}/>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} pt={3}>
                            <Button onClick={() => setActiveStep((a) => a-1)}>Back</Button>
                            {cohort.workflow.length > 0 && <Button onClick={() => submitWorkflow()}>Save and exit</Button>}
                            <Button onClick={() => setActiveStep((a) => a+1)} variant="contained">Next</Button>
                        </Stack>
                        <Typography mt={2}>Need complete customisation? <Link to={"custom"}>Click here</Link></Typography>
                    </Card>,
                },
                workflowNodes.find((node) => node.userType === "Students" && node.id !== 1) && {
                    label: "Student review",
                    element: <Card title={"Student review"}>
                        <Typography>Customise what, if any, forms and files students may need to complete for their placement.</Typography>
                        <Divider/>
                        <Typography mt={1}>Select custom forms for students to complete <IconButtonPop responsive={false} title="Click to learn more" onClick={() => setFormInfoPopup(true)}><HelpOutline/></IconButtonPop></Typography>
                        <Dropdown
                            label='Forms'
                            multiple
                            key={"studentForms"}
                            value={workflowNodes.find((n) => n.userType === "Students")?.forms}
                            onChange={(e) => onChange([workflowNodes.find((n) => n.userType === "Students")?.id || "", "forms"], (e.target.value as unknown as unknown[]).filter((x: unknown) => x !== undefined))}>
                            {Object.entries(forms).map(([key, e]) => {
                                return (<MenuItem value={key}>{e.name}</MenuItem>);
                            })}
                            <Divider/>
                            <MenuItem onClick={()=>window.open(`/${user.product}/organisation/resources/f`, "_blank")}>
                                {"Create form"}
                            </MenuItem>
                        </Dropdown>
                        <Typography mt={1}>Select files for students to review <IconButtonPop responsive={false} title="Click to learn more" onClick={() => setFileInfoPopup(true)}><HelpOutline/></IconButtonPop></Typography>
                        <FileSelectorInput
                            files={workflowNodes.find((n) => n.userType === "Students")?.files}
                            onChange={(f) => {
                                onChange([workflowNodes.find((n) => n.userType === "Students")?.id || "", "files"], f.filter((x) => x !== undefined));
                            }}/>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} pt={3}>
                            <Button onClick={() => setActiveStep((a) => a-1)}>Back</Button>
                            {cohort.workflow.length > 0 && <Button onClick={() => submitWorkflow()}>Save and exit</Button>}
                            <Button onClick={() => setActiveStep((a) => a+1)} variant="contained">Next</Button>
                        </Stack>
                        <Typography mt={2}>Need complete customisation? <Link to={"custom"}>Click here</Link></Typography>
                    </Card>,
                },
                {
                    label: "Staff review",
                    element: <Card title={"Staff review"}>
                        <Typography mb={1}>This stage is primarily for you to have final say on the placement. You can customise what forms and files should be signed off by you or your staff at this point.</Typography>
                        <Divider/>
                        <Typography mt={1}>Select custom forms for staff to complete <IconButtonPop responsive={false} title="Click to learn more" onClick={() => setFormInfoPopup(true)}><HelpOutline/></IconButtonPop></Typography>
                        <Dropdown
                            label='Forms'
                            multiple
                            key={"staffForms"}
                            value={workflowNodes.find((n) => n.id === 2)?.forms}
                            onChange={(e) => onChange([2, "forms"], (e.target.value as unknown as unknown[]).filter((x: unknown) => x !== undefined))}>
                            {Object.entries(forms).map(([key, e]) => {
                                return (<MenuItem value={key}>{e.name}</MenuItem>);
                            })}
                            <Divider/>
                            <MenuItem onClick={()=>window.open(`/${user.product}/organisation/resources/f`, "_blank")}>
                                {"Create form"}
                            </MenuItem>
                        </Dropdown>
                        <Typography mt={1}>Select files for staff to review <IconButtonPop responsive={false} title="Click to learn more" onClick={() => setFileInfoPopup(true)}><HelpOutline/></IconButtonPop></Typography>
                        <FileSelectorInput
                            files={workflowNodes.find((n) => n.id === 2)?.files}
                            onChange={(f) => {
                                onChange([2, "files"], f.filter((x) => x !== undefined));
                            }}/>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} pt={3}>
                            <Button onClick={() => setActiveStep((a) => a-1)}>Back</Button>
                            {cohort.workflow.length > 0 && <Button onClick={() => submitWorkflow()}>Save and exit</Button>}
                            <Button onClick={() => setActiveStep((a) => a+1)} variant="contained">Next</Button>
                        </Stack>
                        <Typography mt={2}>Need complete customisation? <Link to={"custom"}>Click here</Link></Typography>
                    </Card>,
                },
                {
                    label: "During placements",
                    element: <Card title={"During placements"}>
                        <Typography mb={1}>We provide students, employers and students a daily log to document progress.</Typography>
                        <ToggleButtonGroup value={logType} exclusive onChange={(_, v) => setLogType(v)} color="primary">
                            <ToggleButton sx={{borderBottomLeftRadius: 0}} value={"basic"}>Use our basic log</ToggleButton>
                            <ToggleButton sx={{borderBottomRightRadius: 0}} value={"custom"}>Create a custom log</ToggleButton>
                        </ToggleButtonGroup>
                        <Box borderRadius={"4px"} sx={{borderTopLeftRadius: 0}} border={"1px solid rgba(0, 0, 0, 0.12)"} mt={"-1px"} p={1}>
                            {logType === "basic" ? <>
                                <Typography>Use our basic log, consisting of a text box for users to add any text, and a file uploader for images or PDF documents. Users will fill a separate log each day.</Typography>
                            </> : <Stack spacing={1}>
                                <Typography>Create custom logs for staff, students and employers.</Typography>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{borderRadius: 1, border: "1px solid rgba(0, 0, 0, 0.12)"}} p={1}>
                                    <Typography>Student log</Typography>
                                    {cohort?.logs?.students ? <><Chip color="primary" label={"Custom"}/><Button onClick={() => navigateToFormBuilder("students", "log")}>Edit log</Button></> : <><Chip label={"Default"}/><Button onClick={() => navigateToFormBuilder("students", "log")}>Customise</Button></>}
                                </Stack>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{borderRadius: 1, border: "1px solid rgba(0, 0, 0, 0.12)"}} p={1}>
                                    <Typography>Employer log</Typography>
                                    {cohort?.logs?.provider ? <><Chip color="primary" label={"Custom"}/><Button onClick={() => navigateToFormBuilder("provider", "log")}>Edit log</Button></> : <><Chip label={"Default"}/><Button onClick={() => navigateToFormBuilder("provider", "log")}>Customise</Button></>}
                                </Stack>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{borderRadius: 1, border: "1px solid rgba(0, 0, 0, 0.12)"}} p={1}>
                                    <Typography>Staff log</Typography>
                                    {cohort?.logs?.staff ? <><Chip color="primary" label={"Custom"}/><Button onClick={() => navigateToFormBuilder("staff", "log")}>Edit log</Button></> : <><Chip label={"Default"}/><Button onClick={() => navigateToFormBuilder("staff", "log")}>Customise</Button></>}
                                </Stack>
                            </Stack>}
                        </Box>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} pt={3}>
                            <Button onClick={() => setActiveStep((a) => a-1)}>Back</Button>
                            {cohort.workflow && <Button onClick={() => submitWorkflow()}>Save and exit</Button>}
                            <Button onClick={() => setActiveStep((a) => a+1)} variant="contained">Next</Button>
                        </Stack>
                        <Typography mt={2}>Need complete customisation? <Link to={"custom"}>Click here</Link></Typography>
                    </Card>,
                },
                {
                    label: "Placement feedback",
                    element: <Card title={"Placement feedback"}>
                        <Typography>Create custom feedback forms for students, staff and employers. These will be shared with users after a placement finishes.</Typography>
                        <Stack spacing={1}>
                            <Typography>Create custom logs for staff, students and employers.</Typography>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{borderRadius: 1, border: "1px solid rgba(0, 0, 0, 0.12)"}} p={1}>
                                <Typography>Student feedback</Typography>
                                {cohort?.feedback?.students ? <Button onClick={() => navigateToFormBuilder("students", "feedback")}>Edit form</Button> : <><Chip label={"Inactive"}/><Button onClick={() => navigateToFormBuilder("students", "feedback")}>Create</Button></>}
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{borderRadius: 1, border: "1px solid rgba(0, 0, 0, 0.12)"}} p={1}>
                                <Typography>Employer feedback</Typography>
                                {cohort?.feedback?.provider ? <Button onClick={() => navigateToFormBuilder("provider", "feedback")}>Edit form</Button> : <><Chip label={"Inactive"}/><Button onClick={() => navigateToFormBuilder("provider", "feedback")}>Create</Button></>}
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{borderRadius: 1, border: "1px solid rgba(0, 0, 0, 0.12)"}} p={1}>
                                <Typography>Staff feedback</Typography>
                                {cohort?.feedback?.staff ? <Button onClick={() => navigateToFormBuilder("staff", "feedback")}>Edit form</Button> : <><Chip label={"Inactive"}/><Button onClick={() => navigateToFormBuilder("staff", "feedback")}>Create</Button></>}
                            </Stack>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} pt={3}>
                            <Button onClick={() => setActiveStep((a) => a-1)}>Back</Button>
                            <Button color="success" variant="contained" onClick={() => submitWorkflow()}>Submit workflow</Button>
                        </Stack>
                        <Typography mt={2}>Need complete customisation? <Link to={"custom"}>Click here</Link></Typography>
                    </Card>,
                },
            ].filter((a) => a) as {
                label: string;
                element: React.ReactNode;
            }[]} activeStep={activeStep}/>
            <Popup title={"Information we collect from employers"} open={employerInfoPopup} onClose={() => setEmployerInfoPopup(false)}>
                <Stack>
                    <Typography>Below is a list of information we collect from employers regarding their placements.</Typography>
                    <Typography>If you require more information, and think it could benefit other institutes, let us know and we will add it. Otherwise, you can create a custom form to acquire any bespoke information.</Typography>
                    <Table>
                        <TableRow>
                            <TableCell>Job title</TableCell>
                            <TableCell>Job description</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Dress code</TableCell>
                            <TableCell>Working hours</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>First day contact</TableCell>
                            <TableCell>Working location (In person, remote or hybrid)</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Required equipment</TableCell>
                            <TableCell>General responsibilities</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Required experience</TableCell>
                            <TableCell>Experiences gained</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Catering arrangements</TableCell>
                            <TableCell>Travel and parking arrangements</TableCell>
                        </TableRow>
                    </Table>
                </Stack>
            </Popup>
            <Popup open={importWorkflowPopup} onClose={() => setImportWorkflowPopup(false)} title={"Import workflow"}>
                <Typography>Import a workflow from another cohort.</Typography>
                <Form functionType="sync" onSubmit={(e) => {
                    setImportWorkflowPopup(false);
                    if (!cohort.id) return;
                    const selectedCohort = org.cohorts[e.cohort as string] as CohortData;
                    const newCohort = {logs: selectedCohort.logs, logType: selectedCohort.logType, files: selectedCohort.files, forms: selectedCohort.forms, feedback: selectedCohort.feedback, workflow: selectedCohort.workflow} as CohortData;
                    console.log(newCohort.logType, "LOGTYPE");
                    firebaseQuery.update(["cohorts", cohort.id], newCohort);
                    navigate("../settings/workflow", {state: {cohort: newCohort, imported: selectedCohort.name}});
                }}>
                    <Dropdown name={"cohort"} label="Select cohort" required>
                        {Object.entries((org.cohorts || {}) as {[k: string]: CohortData}).filter(([fId, fCohort]) => !fCohort.customWorkflow && fId !== cohort.id).map(([id, cohort]) => <MenuItem value={id}>{cohort.name}</MenuItem>)}
                    </Dropdown>
                </Form>
            </Popup>
            <Popup title={"Employers liability insurance"} open={eliInfoPopup} onClose={() => setEliInfoPopup(false)}>
                <Stack>
                    <Typography>When a placement is created by one of your students, we will automatically email the employer and request a copy of their employers liability insurance.</Typography>
                    <Typography>Insurance is required for any business with employees, so we highly recommend selecting this for your placements.</Typography>
                    <Typography>Once a business uploads their insurance to Placementt, you are required to sign off on the document yourself. Accepting a document will accept it for the entire business, until it expires.</Typography>
                    <Typography>Once business insurance expires, we will require a new copy, which will be automatically requested when a new student attends the placement.</Typography>
                </Stack>
            </Popup>
            <Popup title={"Placementt risk assessments"} open={riskAssessmentInfoPopup} onClose={() => setRiskAssessmentInfoPopup(false)}>
                <Stack>
                    <Typography>By selecting this box, we will require businesses to upload a risk assessment, or fill out a general risk assessment form.</Typography>
                    <Typography>Once uploaded, we will require you to sign off on the risk assessment, and we leave the responsibility of making any adjustments to you.</Typography>
                    <Typography>If you require amendments to the risk assessment, you can reject with a specific reason, for the business to reupload.</Typography>
                    <Typography>As opposed to the employers liability insurance, risk assessments are specific to a placement, not the business.</Typography>
                </Stack>
            </Popup>
            <Popup title={"Placementt DBS checks"} open={dbsCheckInfoPopup} onClose={() => setDbsCheckInfoPopup(false)}>
                <Stack>
                    <Typography>By selecting this box, we will require businesses to upload a DBS check.</Typography>
                    <Typography>Once uploaded, we will require you to sign off on the document, and we leave the responsibility of making any adjustments to you.</Typography>
                    <Typography>If you require amendments, you can reject with a specific reason, for the business to reupload.</Typography>
                    <Typography>As opposed to the employers liability insurance, DBS checks are specific to a placement, not the business.</Typography>
                </Stack>
            </Popup>
            <Popup title={"Creating custom forms"} open={formInfoPopup} onClose={() => setFormInfoPopup(false)}>
                <Stack>
                    <Typography>If you require additional information that we do not already cover, you can generate customised forms through our form builder.</Typography>
                    <Typography>These forms can be assigned to staff, employers or parents, who will be required to complete them before submitting and progressing the placement.</Typography>
                    <Typography>When you first view the form builder, you can view a short tutorial on how to use it.</Typography>
                    <Typography>To create a form, click the dropdown and select to create a form. Otherwise, navigate to Organisation → Resources.</Typography>
                </Stack>
            </Popup>
            <Popup title={"Uploading custom files"} open={fileInfoPopup} onClose={() => setFileInfoPopup(false)}>
                <Stack>
                    <Typography>If you require your employers, staff or parents to view specific forms, you can upload them to the platform and select for your users to review them.</Typography>
                    <Typography>Users will be required to view any forms you select on their stage in order to progress their placement.</Typography>
                    <Typography>To upload a file, click the dropdown and select to upload. Otherwise, navigate to Organisation → Resources.</Typography>
                </Stack>
            </Popup>
        </Page>
    );
}


function CustomWorkflowForm() {
    const location = useLocation();

    const {cohort, from}:{cohort: CohortData, from?: string} = location.state;

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);

    if (!cohort.id) return null;
    console.log("cohort", cohort);
    const navigate = useNavigate();
    const {formType, userType} = useParams() as {formType: "logs"|"feedback", userType: "students"|"provider"|"staff"};

    const [formData, setFormData] = useState<CustomFormSchema>();
    const [messageText, setMessageText] = useState<Descendant[]>();

    useEffect(() => {
        if (!userType || !cohort?.[formType]?.[userType]) return;
        firebaseQuery.getDocData(["forms", cohort?.[formType]?.[userType] as string]).then((form) => setFormData(form as CustomFormSchema));
    }, [cohort]);

    if (!formType || !userType || !["logs", "feedback"].includes(formType)) return null;


    const submitLogSchema = async (schema: CustomFormSchema) => {
        if (!cohort.id) return;

        let resData:CustomFormSchema|string = schema;

        console.log("Form id", schema.id);
        const fFormData = {
            name: `${cohort.name} ${userType.toLowerCase()} ${formType}`,
            oId: user.oId,
            product: user.product,
            updated: (new Date()).toISOString(),
            form: schema.form,
        };
        if (cohort[formType]?.[userType]) {
            await firebaseQuery.update(["forms", cohort[formType]?.[userType] as string], fFormData);
        } else if (schema.id) {
            const updateObj:{[key: string]: unknown} = {[`${formType}.${userType}`]: schema.id, ["forms"]: arrayUnion(schema.id)};

            if (messageText) {
                updateObj[`feedbackText.${userType}`] = messageText;
            }
            firebaseQuery.update(["cohorts", cohort.id], updateObj);
            resData = schema.id;
        } else {
            console.log("FORMDATA", fFormData);
            const userTypeName = await firebaseQuery.add(["forms"], fFormData);
            resData = userTypeName.id;
            const updateObj:{[key: string]: unknown} = {[`${formType}.${userType}`]: userTypeName.id, ["forms"]: arrayUnion(userTypeName.id)};

            if (messageText) {
                updateObj[`feedbackText.${userType}`] = messageText;
            }
            firebaseQuery.update(["cohorts", cohort.id], updateObj);
        }

        if (from && from === "settings") {
            navigate("../settings");
            return;
        }
        navigate("../settings/workflow", {state: {cohort: {...cohort, [formType]: {...cohort[formType], [userType]: resData}}, page: formType === "logs" ? (cohort.workflow.length - 2) : (cohort.workflow.length - 1)}});
    };

    return (
        <Page fullHeight noOverflow>
            <Breadcrumbs sx={{padding: 2}}>
                <Link
                    style={{color: "grey"}}
                    to="../settings"
                >
                    Settings
                </Link>
                <Link
                    style={{color: "grey"}}
                    to="../settings/workflow"
                    state={{cohort: cohort, page: formType === "logs" ? (cohort.workflow.find((n) => n.userType === "Parent") ? 5 : 4) : (cohort.workflow.find((n) => n.userType === "Parent") ? 6 : 5)}}
                >
                    Workflow
                </Link>
                <Typography color="text.primary">{capitalise(userType)} {formType}</Typography>
            </Breadcrumbs>
            {formType === "feedback" && <>
                <Typography variant="h5">Add custom text to share with your provider</Typography>
                <Stack maxWidth={"600px"}>
                    <Typography>We will email this message to the provider. You can request they view a form or file, or ask them to complete a custom form below.</Typography>
                    <SlateEditor initialValue={cohort.feedbackText?.[userType]} onChange={setMessageText}/>
                </Stack>
            </>}
            <CustomFormBuilder key={`${formType}-${userType}-Form`} noSections={formType === "logs"} onSubmit={submitLogSchema} title={`Add ${userType} ${formType} questions`} formData={formData} id={`${formType}-${userType}-Form`} noTitle/>
        </Page>
    );
}
