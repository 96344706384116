import {CheckCircleOutline, Delete, Edit} from "@mui/icons-material";
import {Stack, Typography, Grid, Chip, TableRow, TableBody, Table, Dialog, DialogTitle, DialogContent, Button, TableCell, Checkbox} from "@mui/material";
import {OrganisationAddress, PlacementListing, UserData, camelCaseToNormal, capitalise, executeCallable, flagCodes, getAccess, getPlacementListingsById, getUserById, useExecuteCallableJob} from "placementt-core";
import {useContext, useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {OrganisationContext, UserContext} from "../../App";
import EditUserPopup from "../../Components/EditUserPopup";
import IconButtonPop from "../../Components/IconButtonPop";
import {InfoTableCell} from "../../Util/styledComponents";
import Page, {PageTitle} from "../../Components/Page";
import StudentPlacementList from "../../Components/StudentPlacementList";
import ActivateUsersPopup from "../Institutes/Staff/Cohorts/Popups/ActivateUsersPopup";
import Card from "../../Components/Card";
import Alert from "../../Components/Alert";
import {Popup} from "../../Components/Popup";
import {LoadingButton} from "../../Components/LoadingButton";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {arrayUnion, deleteField} from "firebase/firestore";
import {AddressSelector, ListingSelector} from "../Providers/AddItemForms/RequestItemLists";


export default function UserProfile() {
    const {id} = useParams();
    const [editUserPopup, setEditUserPopup] = useState(false);
    const [activateUserPopup, setActivateUserPopup] = useState(false);
    const [accessRequestPopup, setAccessRequestPopup] = useState<"addresses"|"listings">();

    const [deleteUserPopup, setDeleteUserPopup] = useState(false);
    const [selectedUser, setSelectedUser] = useState<UserData>();

    const [requestAddressAccessPopup, setRequestAddressAccessPopup] = useState(false);
    const [requestListingAccessPopup, setRequestListingAccessPopup] = useState(false);

    const organisation = useContext(OrganisationContext);
    const user = useContext(UserContext);
    const cohorts = organisation.cohorts;
    const {execute} = useExecuteCallableJob({user: user});

    useEffect(() => {
        if (!id) return;
        getUserById(id, setSelectedUser);
    }, []);

    const navigate = useNavigate();

    if (selectedUser === undefined) {
        return null;
    }

    const userGroup = selectedUser.userGroup ? organisation.userGroups[selectedUser.userGroup] : undefined;
    const cohort = selectedUser.cohort ? organisation.cohorts[selectedUser.cohort] : undefined;

    const access = getAccess(user, `edit${selectedUser.userType}` as any, `delete${selectedUser.userType}` as any);

    if (!id || !selectedUser) {
        return (
            <Stack height={"100%"}>
                <PageTitle back={"/institutes/users"}>User profile</PageTitle>
                <Typography>User not found.</Typography>
            </Stack>
        );
    }

    const updateUser = async (data: {[key:string]: unknown}) => {
        await executeCallable("userManagement-editUser", {userId: id, newData: data, userType: selectedUser.userType});
        setEditUserPopup(false);
    };

    console.log(requestListingAccessPopup, setRequestListingAccessPopup);

    return (
        <Page
            back={`/${user.product}/cohorts/${selectedUser.cohort}/students`}
            metaTitle={`${selectedUser.details.forename} ${selectedUser.details.surname} | Profile`}
            title={`${selectedUser.details.forename} ${selectedUser.details.surname}`}
            grid>
            <Card grid xs={12} md={5} title={["Basic info",
                <Stack direction={"row"}>
                    {access[`edit${selectedUser.userType}`] && selectedUser.status === "inactive" && <IconButtonPop variant="contained" title={"Activate user"} onClick={() => setActivateUserPopup(true)}><CheckCircleOutline/></IconButtonPop>}
                    {access[`edit${selectedUser.userType}`] && <IconButtonPop title={"Edit details"} onClick={() => setEditUserPopup(true)}><Edit /></IconButtonPop>}
                    {access[`delete${selectedUser.userType}`] && <IconButtonPop title={"Delete"} color="error" onClick={() => setDeleteUserPopup(true)}><Delete /></IconButtonPop>}
                </Stack>,
            ]}>
                <Stack spacing={2}>
                    {selectedUser.flags && selectedUser.flags.map((flag) => {
                        const flagObject = flagCodes[flag];
                        return <Alert severity={flagObject.severity}>{flagObject.message}</Alert>;
                    })}
                </Stack>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <InfoTableCell>Name</InfoTableCell>
                            <InfoTableCell>{`${selectedUser.details.forename} ${selectedUser.details.surname}`}</InfoTableCell>
                        </TableRow>
                        <TableRow>
                            <InfoTableCell>Email</InfoTableCell>
                            <InfoTableCell>{selectedUser.email}</InfoTableCell>
                        </TableRow>
                        {selectedUser.product === "institutes" && <TableRow>
                            <InfoTableCell>User type</InfoTableCell>
                            <InfoTableCell><Chip label={selectedUser.userType} color="primary" sx={{width: "max-content"}} /></InfoTableCell>
                            <InfoTableCell></InfoTableCell>
                        </TableRow>}
                        {selectedUser.product === "institutes" && <TableRow>
                            <InfoTableCell>User group</InfoTableCell>
                            <InfoTableCell><Chip label={userGroup?.name} color="primary" sx={{width: "max-content"}} /></InfoTableCell>
                            <InfoTableCell></InfoTableCell>
                        </TableRow>}
                        {selectedUser.product === "institutes" && selectedUser.userType === "Staff" && <TableRow>
                            <InfoTableCell>Visible cohorts</InfoTableCell>
                            <InfoTableCell>
                                {
                                    selectedUser.viewCohorts === "all" ? <Chip label={"All"} color="primary" sx={{width: "max-content"}} /> :
                                        selectedUser.viewCohorts === "none" ? <Chip label={"None"} color="primary" sx={{width: "max-content"}} /> :
                                            (selectedUser.visibleCohorts || "").split(",").map((c) => <Chip label={cohorts[c].name} color="primary" sx={{width: "max-content", marginRight: "5px"}} />)
                                }
                            </InfoTableCell>
                            <InfoTableCell></InfoTableCell>
                        </TableRow>}
                        {selectedUser.product === "institutes" && selectedUser.userType === "Students" && <TableRow>
                            <InfoTableCell>Cohort</InfoTableCell>
                            <InfoTableCell><Chip label={cohort.name} color="primary" sx={{width: "max-content"}} /></InfoTableCell>
                            <InfoTableCell></InfoTableCell>
                        </TableRow>}
                        {organisation.details[`${selectedUser.userType.toLowerCase()}Fields`]?.map((field: string) => {
                            if (!Object.prototype.hasOwnProperty.call(selectedUser.details, field)) {
                                return null;
                            }
                            if (["forename", "surname", "email"].includes(field)) {
                                return null;
                            }
                            return (
                                <TableRow>
                                    <InfoTableCell>{camelCaseToNormal(field)}</InfoTableCell>
                                    <InfoTableCell>{selectedUser.details[field as keyof typeof selectedUser.details]}</InfoTableCell>
                                    <InfoTableCell></InfoTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Card>
            {selectedUser.product === "providers" &&
                <Card grid xs={12} md={6} title={"User group"}>
                    {(Boolean(selectedUser.requestedVisibleAddresses?.length) || Boolean(selectedUser.requestedVisibleListings?.length)) && <Stack mb={2}>
                        {Boolean(selectedUser.requestedVisibleAddresses?.length) && <Alert action={<Button color="inherit" onClick={() => setAccessRequestPopup("addresses")}>{flagCodes["requestedVisibleAddresses"].button}</Button>} severity={flagCodes["requestedVisibleAddresses"].severity}>{flagCodes["requestedVisibleAddresses"].message}</Alert>}
                        {Boolean(selectedUser.requestedVisibleListings?.length) && <Alert action={<Button color="inherit" onClick={() => setAccessRequestPopup("listings")}>{flagCodes["requestedVisiblePlacementListings"].button}</Button>} severity={flagCodes["requestedVisiblePlacementListings"].severity}>{flagCodes["requestedVisiblePlacementListings"].message}</Alert>}
                    </Stack>}
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <InfoTableCell>User group</InfoTableCell>
                                <InfoTableCell><Chip label={userGroup?.name} color="primary" sx={{width: "max-content"}} /></InfoTableCell>
                                <InfoTableCell></InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell>Visible addresses</InfoTableCell>
                                <InfoTableCell>{selectedUser?.viewAddresses === "request" ? selectedUser.visibleAddresses?.length : capitalise(selectedUser?.viewAddresses)}</InfoTableCell>
                                <InfoTableCell><Button onClick={() => setRequestAddressAccessPopup(true)}>Edit access</Button></InfoTableCell>
                                <AddressSelector selectedUser={selectedUser} selectedUserId={id} visible={requestAddressAccessPopup} onClose={() => setRequestAddressAccessPopup(false)} type="popup" onForward={() => setRequestAddressAccessPopup(false)}/>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell>Visible placement listings</InfoTableCell>
                                <InfoTableCell>{selectedUser?.viewPlacementListings === "request" ? selectedUser.visibleListings?.length : capitalise(selectedUser?.viewPlacementListings)}</InfoTableCell>
                                <InfoTableCell><Button onClick={() => setRequestListingAccessPopup(true)}>Edit access</Button></InfoTableCell>
                                <ListingSelector selectedUser={selectedUser} selectedUserId={id} visible={requestListingAccessPopup} onClose={() => setRequestListingAccessPopup(false)} type="popup" onForward={() => setRequestListingAccessPopup(false)}/>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>}
            {selectedUser.product === "institutes" && selectedUser.userType === "Students" &&
                    <Grid xs={12} md={7} item>
                        <StudentPlacementList sx={{minHeight: "300px", maxHeight: "400px"}} title={"Placements"} student={selectedUser}/>
                    </Grid>}
            <Dialog
                open={deleteUserPopup}
                onClose={() => setDeleteUserPopup(false)}>
                <DialogTitle>Delete user?</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography variant='body1'>You can permanently delete all user data or disable the user accounts.</Typography>
                        <Typography variant='body1'>User data will be unretrievable once deleted. Users may be disabled to preserve data.</Typography>
                        <Stack direction={"row"} justifyContent="space-evenly">
                            <Button color="error" onClick={() => {
                                execute("userManagement-deleteUsers", {users: {[id]: selectedUser}, userType: selectedUser.userType}); navigate("/institutes/users");
                            }}>Delete</Button>
                            <Button onClick={() => {
                                execute("userManagement-deactivateUsers", {users: {[id]: selectedUser}, userType: selectedUser.userType}); navigate("/institutes/users");
                            }}>Deactivate</Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
            {selectedUser.product === "providers" && <ReviewAccessRequest {...{selectedUser}} open={Boolean(accessRequestPopup)} onClose={() => setAccessRequestPopup(undefined)} type={accessRequestPopup}/>}
            {selectedUser.product === "institutes" && <ActivateUsersPopup userType={selectedUser.userType} users={{[selectedUser.id]: selectedUser}} active={activateUserPopup} onToggle={() => setActivateUserPopup(false)}/>}
            <EditUserPopup open={editUserPopup} onClose={() => setEditUserPopup(false)} fields={[...["forename", "surname"], ...organisation.details[`${selectedUser.userType.toLowerCase()}Fields`]]} initialData={selectedUser} onSubmit={updateUser} />
        </Page>
    );
}

function ReviewAccessRequest({open, onClose, type, selectedUser}:{open: boolean, onClose: () => void, type?: "addresses"|"listings", selectedUser: UserData}) {
    const [fType, setFType] = useState<"addresses"|"listings"|undefined>(type);
    const [requestedItems, setRequestedItems] = useState<string[]>([]);
    const [items, setItems] = useState<[string, OrganisationAddress|PlacementListing][]>();

    const firebaseQuery = new FirebaseQuery();

    const addresses = useContext(OrganisationContext).addresses as {[key: string]: OrganisationAddress};

    const [selectedItems, setSelectedItems] = useState<"all"|string[]>([]);

    useEffect(() => {
        if (!type) return;
        setFType(type);

        if (type === "addresses") {
            const addressesToRender = Object.entries(addresses).filter(([k]) => selectedUser.requestedVisibleAddresses?.includes(k));
            setItems(addressesToRender);
            setRequestedItems(selectedUser.requestedVisibleAddresses || []);
        }

        if (type === "listings") {
            setItems(undefined);
            if (!selectedUser.requestedVisibleListings?.length) return;
            getPlacementListingsById(selectedUser.requestedVisibleListings).then(setItems);
            setRequestedItems(selectedUser.requestedVisibleListings || []);
        }
    }, [type]);

    const submitSelection = async () => {
        if (selectedItems === "all" && selectedUser[`requestedVisible${capitalise(fType)}` as keyof typeof selectedUser]) {
            await firebaseQuery.update(["users", selectedUser.id], {
                [`requestedVisible${capitalise(fType)}`]: deleteField(),
                [`visible${capitalise(fType)}`]: arrayUnion(...selectedUser[`requestedVisible${capitalise(fType)}` as keyof typeof selectedUser] as string[]),
            } as Partial<UserData>);
        }

        console.log("Update addresses", selectedItems);
        await firebaseQuery.update(["users", selectedUser.id], {
            [`requestedVisible${capitalise(fType)}`]: deleteField(),
            [`visible${capitalise(fType)}`]: arrayUnion(...selectedItems),
        } as Partial<UserData>);
    };

    return (
        <Popup title={"Review access request for "+fType} {...{open, onClose}}>
            <Stack>
                <Typography>Review the access request made by {selectedUser.details.forename} {selectedUser.details.surname}. Allowing access to these items will allow them to view all associated detains, and depending on their other permissions, enable them to view applications and scheduled placements.</Typography>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Checkbox checked={selectedItems === "all"} onClick={() => setSelectedItems((s) => Array.isArray(s) ? "all" : [])}/>
                            </TableCell>
                            <TableCell>{fType === "addresses" ? "Address" : "Listing"} name</TableCell>
                        </TableRow>
                        {items?.map(([k, v]) =>
                            <TableRow key={k+"_requestRow"}>
                                <TableCell><Checkbox checked={selectedItems === "all" || selectedItems.includes(k)} onClick={() => setSelectedItems((s) => s === "all" ? requestedItems.filter((i) => i !== k) : s.includes(k) ? s.filter((i) => i !== k) : [...s, k])}/></TableCell>
                                <TableCell>{v.name}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <LoadingButton text="Submit selection" onClick={submitSelection}/>
            </Stack>
        </Popup>
    );
}
/*
function StaffRoles({selectedUser}) {
    const [editRolePopup, setEditRolePopup] = useState(false);

    const [assignedRoles, setAssignedRoles] = useState({});

    const organisation = useContext(OrganisationContext);
    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        // Get where students includes or filters

        const getRoleUsers = async (type, roles) => {
            console.log("roles", roles);

            const rolesWithUsers = Object.fromEntries(await Promise.all(Object.entries(roles).map(async ([key, role]) => {
                const uids = type === "students" ? role?.filters?.students : role.staff;
                console.log("uids", uids);
                const users = await Promise.all(uids.map(async (uid) => {
                    console.log("uid", uid);
                    return await getUserById(uid).then((user) => `${user.details.forename} ${user.details.surname}`).catch(() => "User not found");
                }));

                if (type === "students") {
                    return [key, {...role, filters: {...role?.filters, students: users}}];
                }
                return [key, {...role, staff: users}];
            })));
            console.log("rolesWithUsers", rolesWithUsers);
            setAssignedRoles(rolesWithUsers);
        };

        if (selectedUser.userType === "Staff") {
            firebaseQuery.collectionSnapshot((roles) => getRoleUsers("students", roles), ["staffRoles"], [where("product", "==", selectedUser.product), where("oId", "==", selectedUser.oId), where("staff", "array-contains", selectedUser.id)]);
        } else {
            getStaffRolesForStudent(organisation.details.studentsFields, organisation.details.product, selectedUser).then((roles) => {
                getRoleUsers("staff", roles);
            });
        }
    }, []);

    // ALL THE USER ROLE STUFF IS REFERENCING THE LOGGED IN USER! NEED TO MAKE IT THE USER I AM LOOKING AT!
    // I think I've fixed this, but will need to check
    return (
        <>
            <Grid item xs={12} md={5}>
                <Card>
                    <CardHeader
                        title={selectedUser.userType === "Staff" ? ["Roles",
                            <Stack direction={"row"}>
                                {<IconButtonPop title={"Edit roles"} onClick={() => setEditRolePopup(true)}><Edit /></IconButtonPop>}
                            </Stack>] : "Staff"
                        } />
                    <CardContent>
                        <Table size="small">
                            <TableBody>
                                {Object.keys(assignedRoles).length > 0 ? Object.entries(assignedRoles).map(([, role]) =>
                                    selectedUser.userType === "Staff" ? <TableRow>
                                        <InfoTableCell>{role.name}</InfoTableCell>
                                        <InfoTableCell>
                                            {[...(role.filters.fields ? Object.entries(role.filters.fields).map(([key, value]) => {
                                                return (
                                                    <Chip
                                                        key={`${key}_${value}`}
                                                        tabIndex={-1}
                                                        label={key + " = " + value}
                                                        color={"primary"}
                                                        sx={{margin: "2px"}}
                                                    />
                                                );
                                            }) : []), ...(role.filters.students ? (role.filters.students).map((student) => {
                                                console.log("student", student);
                                                return (
                                                    <Chip
                                                        key={student}
                                                        tabIndex={-1}
                                                        label={student}
                                                        color={"primary"}
                                                        sx={{margin: "2px"}}
                                                    />
                                                );
                                            }) : [])]
                                            }


                                        </InfoTableCell>
                                    </TableRow> :
                                        <TableRow>
                                            <InfoTableCell>{role.name}</InfoTableCell>
                                            <InfoTableCell>
                                                {(role.staff).map((name) => {
                                                    console.log("name", name);
                                                    return (
                                                        <Chip
                                                            key={name}
                                                            tabIndex={-1}
                                                            label={name}
                                                            color={"primary"}
                                                            sx={{margin: "2px"}}
                                                        />
                                                    );
                                                })}
                                            </InfoTableCell>
                                        </TableRow>
                                ) : "No roles to display."}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
            {selectedUser.userType === "Staff" && <EditStaffRoleDialog open={editRolePopup} onClose={() => setEditRolePopup(false)} {...{selectedUser, assignedRoles, setAssignedRoles}} />}
        </>
    );
}


function EditStaffRoleDialog({open, onClose, assignedRoles, selectedUser}) {
    const [roleName, setRoleName] = useState("");
    const [filters, setFilters] = useState({});
    const [error, setError] = useState(false);
    const [filterUsersKey, setFilterUsersKey] = useState((Math.random() + 1).toString(36).substring(7));

    const user = useContext(UserContext);
    const firebaseQuery = new FirebaseQuery();

    const addNewRole = async () => {
        if (Object.keys(assignedRoles).length === 10) {
            setError("There is a limit of 10 roles per staff member.");
            return;
        }
        if (!roleName || (Object.keys(filters.students).length === 0 && Object.keys(filters.fields).length === 0) ||
            (Object.keys(filters.fields).length > 0 && Object.entries(filters.fields).some(([, value]) => !value))) {
            setError("Role name and filters required.");
            return;
        }
        const students = Object.entries(filters.students).map(([uid]) => uid);
        const role = {name: roleName, filters: {...filters, students: students}, staff: [selectedUser.id], oId: user.oId, product: user.product};
        await firebaseQuery.add(["staffRoles"], role);
        setRoleName("");
        setFilterUsersKey((Math.random() + 1).toString(36).substring(7));
    };

    const removeRole = async (id, role) => {
        if (role.staff.length === 1) {
            await firebaseQuery.delete(["staffRoles", id]);
            return;
        }
        delete role.id;
        await firebaseQuery.update(["staffRoles", id], {...role, oId: user.oId, product: user.product, staff: arrayRemove(selectedUser.id)});
        return;
    };

    return (

        <Popup {...{open, onClose}} title={"Add roles"} cardSx={{height: "500px", maxHeight: "90vh", maxWidth: "450px"}}>
            <Stack spacing={2}>
                <Stack direction={"row"} spacing={2} sx={{marginTop: "10px"}}>
                    <InputGroup sx={{maxWidth: "200px"}} value={roleName} onChange={(e) => {
                        setRoleName(e.target.value); setError(false);
                    }} label={"Role name"} placeholder={"E.g. Year lead"}/>
                    <Button onClick={addNewRole}>Add</Button>
                </Stack>
                <FilterUsersInput noStudents key={filterUsersKey} userType={"Students"} onChange={(f) => {
                    setFilters(f);
                }}/>
                <Collapse in={error}>
                    <Alert severity='error' onClose={() => setError(false)}>{error}</Alert>
                </Collapse>
                <Divider />
                <Typography variant={"h6"}>Current roles</Typography>
                <Stack spacing={2}>
                    {Object.keys(assignedRoles).length > 0 ? Object.entries(assignedRoles).map(([id, role]) =>
                        <Stack direction={"row"} spacing={1}>
                            <InputGroup sx={{maxWidth: "25%"}} value={role.name} disabled label={"Role name"} />
                            <Box sx={{position: "relative", flex: 1}}>
                                <InputGroup
                                    label={"Students"}
                                    disabled
                                    InputProps={{
                                        startAdornment: [...(role.filters.fields ? Object.entries(role.filters.fields).map(([key, value]) => {
                                            return (
                                                <Chip
                                                    key={`${key}_${value}`}
                                                    tabIndex={-1}
                                                    label={key + " = " + value}
                                                    color={"primary"}
                                                    sx={{marginRight: "5px"}}
                                                />
                                            );
                                        }) : []), ...(role.filters.students ? (role.filters.students).map((student) => {
                                            return (
                                                <Chip
                                                    key={student}
                                                    tabIndex={-1}
                                                    label={student}
                                                    color={"primary"}
                                                    sx={{marginRight: "5px"}}
                                                />
                                            );
                                        }) : [])],
                                    }}
                                />
                            </Box>
                            <IconButton onClick={() => removeRole(id, role)}><Delete /></IconButton>
                        </Stack>) : "No roles to display."}
                </Stack>
            </Stack>
        </Popup>
    );
}
*/
