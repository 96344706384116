/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {CardContent, Grid, MenuItem, Stack, Switch, ToggleButton, Typography} from "@mui/material";
import {ChangeEvent, useContext, useEffect, useState} from "react";
import Dropdown from "../../../../Components/FormComponents/Dropdown";
import InputGroup from "../../../../Components/FormComponents/InputGroup";
import Form from "../../../../Components/Form";
import ControlledSwitch from "../../../../Components/FormComponents/ControlledSwitch/ControlledSwitch";
import OptionalSection from "../../../../Components/FormComponents/OptionalSection";
import {OrganisationContext, UserContext} from "../../../../App";
import {FormSection, ToggleFormItem} from "../../../../Util/styledComponents";
import {StaffUserGroup, UserGroupData, camelCaseToNormal} from "placementt-core";
import ControlledToggleGroup from "../../../../Components/ControlledToggleGroup";
import {where} from "firebase/firestore";
import Card from "../../../../Components/Card";


type Params = {
    formData?: UserGroupData,
    onSubmit?: () => void,
    access?: boolean,
    id?: string
}

export default function UserGroupForm({formData, onSubmit, access, id}: Params) {
    const [groupType, setGroupType] = useState<"Staff" | "Students"|undefined>(formData?.template);
    const [name, setName] = useState(formData?.name || "");
    const [nameDisabled, setNameDisabled] = useState(false);

    useEffect(() => {
        if (!formData || Object.keys(formData).length === 0) {
            return;
        }
        setGroupData(formData);
    }, [formData]);

    const setGroupData = (group: UserGroupData) => {
        setName(group.name);
        setGroupType(group.template);
        if (group.name === group.template) {
            setNameDisabled(true);
        }
    };

    if (!formData) return null;

    return (
        <Form onSubmit={onSubmit} sx={{alignSelf: "center", width: "600px", maxWidth: "100%"}}>
            <Card key={"name-card"}>
                <CardContent>
                    <Stack spacing={2}>
                        {nameDisabled ? <Typography variant="h4">{name}</Typography> : <InputGroup label="Group name" value={name} name={"name"} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)} required disabled={nameDisabled || !access}/>}
                        {!formData.template ? <Dropdown name="template" label="User group template" required value={groupType} onChange={(e) => setGroupType(e.target.value as "Staff"|"Students")}>
                            <MenuItem value={"Staff"}>Staff</MenuItem>
                            <MenuItem value={"Students"}>Students</MenuItem>
                        </Dropdown> : <input hidden name={"template"} value={groupType}/>}
                    </Stack>
                </CardContent>
            </Card>
            {groupType &&
            <Stack key={"form-card"} style={{alignSelf: "center", width: "600px", maxWidth: "100%"}}>
                {groupType === "Staff" ?
                    <StaffForm formData={formData as StaffUserGroup} access={access || false}/> :
                    <StudentForm/>}
            </Stack>
            }
        </Form>
    );
}

function StudentForm() {
    return (
        <Stack spacing={2}>
            <Typography variant="h4">User permissions</Typography>

        </Stack>
    );
}

type StaffFormParams = {
    formData: StaffUserGroup,
    access: boolean
}
function StaffForm({formData, access}: StaffFormParams) {
    return (
        <Stack spacing={2}>
            <Card title="Users" grid>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>View staff</Typography>
                        <ControlledSwitch id={"viewStaff"} name={"viewStaff"} checked={formData.viewStaff || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>

                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>Create cohorts</Typography>
                        <ControlledSwitch id={"createCohorts"} name={"createCohorts"} checked={formData.createCohorts || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
                <OptionalSection label={"Manage staff"} formData={formData} disabled={!access}>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Add staff</Typography>
                            <ControlledSwitch id={"addStaff"} name={"addStaff"} defaultChecked={formData["addStaff"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Activate staff</Typography>
                            <ControlledSwitch id={"activateStaff"} name={"activateStaff"} defaultChecked={formData["activateStaff"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Edit staff</Typography>
                            <ControlledSwitch id={"editStaff"} name={"editStaff"} defaultChecked={formData["editStaff"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Delete staff</Typography>
                            <ControlledSwitch id={"deleteStaff"} name={"deleteStaff"} defaultChecked={formData["deleteStaff"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                </OptionalSection>
                <OptionalSection label={"Manage staff groups"} formData={formData} disabled={!access}>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Add staff groups</Typography>
                            <ControlledSwitch id={"addStaffGroups"} name={"addStaffGroups"} defaultChecked={formData["addStaffGroups"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Edit staff groups</Typography>
                            <ControlledSwitch id={"editStaffGroups"} name={"editStaffGroups"} defaultChecked={formData["editStaffGroups"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Delete staff groups</Typography>
                            <ControlledSwitch id={"deleteStaffGroups"} name={"deleteStaffGroups"} defaultChecked={formData["deleteStaffGroups"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                </OptionalSection>
                <OptionalSection label={"Manage students"} formData={formData} disabled={!access}>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Add students</Typography>
                            <ControlledSwitch id={"addStudents"} name={"addStudents"} defaultChecked={formData["addStudents"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Activate students</Typography>
                            <ControlledSwitch id={"activateStudents"} name={"activateStudents"} defaultChecked={formData["activateStudents"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Edit students</Typography>
                            <ControlledSwitch id={"editStudents"} name={"editStudents"} defaultChecked={formData["editStudents"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Delete students</Typography>
                            <ControlledSwitch id={"deleteStudents"} name={"deleteStudents"} defaultChecked={formData["deleteStudents"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                </OptionalSection>
            </Card>
            <Card title="Cohorts" grid>
                <OptionalSection label={"Manage cohorts"} formData={formData} disabled={!access}>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Add cohorts</Typography>
                            <ControlledSwitch id={"addCohorts"} name={"addCohorts"} defaultChecked={formData["addCohorts"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Edit cohorts</Typography>
                            <ControlledSwitch id={"editCohorts"} name={"editCohorts"} defaultChecked={formData["editCohorts"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Delete cohorts</Typography>
                            <ControlledSwitch id={"deleteCohorts"} name={"deleteCohorts"} defaultChecked={formData["deleteCohorts"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                </OptionalSection>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>View placement details</Typography>
                        <ControlledSwitch id={"viewPlacements"} name={"viewPlacements"} defaultChecked={formData["viewPlacements"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>Sign off stages</Typography>
                        <ControlledSwitch id={"signOffPlacements"} name={"signOffPlacements"} defaultChecked={formData["signOffPlacements"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>Verify insurance</Typography>
                        <ControlledSwitch id={"verifyInsurance"} name={"verifyInsurance"} defaultChecked={formData["verifyInsurance"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>Create student placements</Typography>
                        <Switch id={"proposePlacements"} name={"proposePlacements"} checked={formData["proposePlacements"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>Edit placement details</Typography>
                        <ControlledSwitch id={"editPlacements"} name={"editPlacements"} defaultChecked={formData["editPlacements"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>Delete placements</Typography>
                        <ControlledSwitch id={"deletePlacements"} name={"deletePlacements"} defaultChecked={formData["deletePlacements"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
            </Card>
            <Card title={"Placement database"} grid>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>View database</Typography>
                        <ControlledSwitch id={"viewDatabase"} name={"viewDatabase"} checked={formData["viewDatabase"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>Add listings</Typography>
                        <ControlledSwitch id={"addListings"} name={"addListings"} checked={formData["addListings"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>Delete listings</Typography>
                        <ControlledSwitch id={"deleteListings"} name={"deleteListings"} checked={formData["deleteListings"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>Verify listings</Typography>
                        <ControlledSwitch id={"verifyListings"} name={"verifyListings"} checked={formData["verifyListings"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
            </Card>
            <Card title="Admin" grid>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>View resources page</Typography>
                        <ControlledSwitch id={"viewSetup"} name={"viewSetup"} checked={formData["viewSetup"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>Edit institute details</Typography>
                        <ControlledSwitch id={"editInstitute"} name={"editInstitute"} checked={formData["editInstitute"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
                <OptionalSection label={"Manage forms"} formData={formData} disabled={!access}>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Add forms</Typography>
                            <ControlledSwitch id={"addForms"} name={"addForms"} defaultChecked={formData["addForms"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Edit forms</Typography>
                            <ControlledSwitch id={"editForms"} name={"editForms"} defaultChecked={formData["editForms"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Delete forms</Typography>
                            <ControlledSwitch id={"deleteForms"} name={"deleteForms"} defaultChecked={formData["deleteForms"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                </OptionalSection>
                <OptionalSection label={"Manage files"} formData={formData} disabled={!access}>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Add files</Typography>
                            <ControlledSwitch id={"addFiles"} name={"addFiles"} defaultChecked={formData["addFiles"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleFormItem>
                            <Typography>Delete files</Typography>
                            <ControlledSwitch id={"deleteFiles"} name={"deleteFiles"} defaultChecked={formData["deleteFiles"] || false} disabled={!access}/>
                        </ToggleFormItem>
                    </Grid>
                </OptionalSection>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>View billing</Typography>
                        <Switch id={"viewBilling"} name={"viewBilling"} checked={formData["viewBilling"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>Manage billing</Typography>
                        <Switch id={"manageBilling"} name={"manageBilling"} checked={formData["manageBilling"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
            </Card>
        </Stack>
    );
}
