import {useContext, useEffect, useState} from "react";
import SubNavBar from "../../../../Components/Navigation/SubNavBar";
import Page from "../../../../Components/Page";
import {OrganisationContext, UserContext} from "../../../../App";
import {useNavigate} from "react-router-dom";
import {PRIMARY_COLOUR, QueryObject, UserData, UserGroupData, executeCallable, getAccess, storage, useExecuteCallableJob} from "placementt-core";
import FilterList from "../../../../Components/FilterList";
import {Button, Chip, DialogActions, Grid, List, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText, Stack, Typography} from "@mui/material";
import IconButtonPop from "../../../../Components/IconButtonPop";
import {CopyAll, Delete, Edit} from "@mui/icons-material";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import DeletePopup from "../../../../Components/DeletePopup";
import {where} from "firebase/firestore";
import Card from "../../../../Components/Card";
import {Popup} from "../../../../Components/Popup";
import FilterTable from "../../../../Components/FilterTable";
import {LoadingButton} from "../../../../Components/LoadingButton";
import {UserTable} from "../UserTable";
import {getDownloadURL, ref} from "firebase/storage";


export default function Staff() {
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const [staff, setStaff] = useState<number>();
    const [active, setActive] = useState<number>();
    const [activeNotLoggedIn, setActiveNotLoggedIn] = useState<number>();
    const [activeNotLoggedInPopup, setActiveNotLoggedInPopup] = useState(false);

    const staffQuery: QueryObject[] = [{
        path: ["users"],
        where: [["userType", "==", "Staff"]],
    }];

    const firebaseQuery = new FirebaseQuery();
    const {execute} = useExecuteCallableJob({user});
    const viewStaff = getAccess(user, "viewStaff");

    useEffect(() => {
        if (user.viewCohorts === "all" || user.userGroup === "admin") {
            firebaseQuery.getCount("users", [where("oId", "==", user.oId), where("userType", "==", "Staff")]).then(setStaff);
            firebaseQuery.getCount("users", [where("oId", "==", user.oId), where("userType", "==", "Staff"), where("status", "==", "active")]).then(setActive);
            firebaseQuery.getCount("users", [where("oId", "==", user.oId), where("userType", "==", "Staff"), where("status", "==", "active"), where("active", "==", false)]).then(setActiveNotLoggedIn);
        }
    }, []);

    const staffTablePage = () => <Page
        metaTitle={"Placementt | Staff"}
        metaDesc="Desc" grid>
        <Grid item xs={12} md={(viewStaff || user.userGroup === "admin") ? 9 : 12}>
            <UserTable key={"staffTable"} queries={staffQuery} userType="Staff" onItemClick={(key: string) => navigate(`/${user.product}/users/${key}`)}/>
        </Grid>
        {(viewStaff || user.userGroup === "admin") && <Card title={"Overview"} grid xs={12} md={3}>
            <List>
                <ListItem style={{background: PRIMARY_COLOUR, borderRadius: "20px", marginBottom: "10px", color: "white"}}>
                    <ListItemText primaryTypographyProps={{fontWeight: "bold"}} primary="Total staff"/>
                    <ListItemSecondaryAction style={{fontWeight: "bold", color: "white"}}>
                        {(staff || 1)-1}
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                    <ListItemText primary="Active staff"/>
                    <ListItemSecondaryAction>
                        {(active || 1)-1}
                    </ListItemSecondaryAction>
                </ListItem>
                {Boolean(activeNotLoggedIn && activeNotLoggedIn > 0) && <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                    <ListItemText primary="Active, not logged in"/>
                    <ListItemSecondaryAction>
                        <Stack direction={"row"} spacing={3} alignItems={"center"}>
                            <Typography>{activeNotLoggedIn}</Typography>
                            <Button onClick={() => setActiveNotLoggedInPopup(true)} size="small" color="inherit">View</Button>
                        </Stack>
                    </ListItemSecondaryAction>
                </ListItem>}
            </List>
            <Popup title={"Staff activated and not logged in"} open={activeNotLoggedInPopup} onClose={() => setActiveNotLoggedInPopup(false)}>
                <FilterTable
                    formatQueryData={(data) =>
                        Object.entries(data as {[key:string]: UserData&{userGroupId: string}}).filter(([, user]) =>
                            user.userGroup !== "admin").reduce((acc, [uid, user]) => {
                            return {...acc, [uid]: {...user, ...user.details}};
                        }, {} as {[key:string]: unknown})
                    }
                    disableSelection
                    columns={["forename", "surname", "email"]} data={[{path: ["users"], where: [["oId", "==", user.oId], ["status", "==", "active"], ["userType", "==", "Staff"], ["active", "==", false]]}] as QueryObject[]}/>
                <DialogActions>
                    <LoadingButton onClick={async () => await executeCallable("userManagement-exportActiveNotSignedInUsers", {userType: "Staff"}).then(async (res) => {
                        const url = await getDownloadURL(ref(storage, res.data));
                        window.open(url, "_blank");
                    })} text="Export as CSV"/>
                    <LoadingButton onClick={async () => execute("userManagement-activateUsers", {users: [{path: ["users"], where: [["oId", "==", user.oId], ["status", "==", "active"], ["userType", "==", "Staff"], ["active", "==", false]]}], userType: "Staff"})} text="Resend activation email"/>
                </DialogActions>
            </Popup>
        </Card>}
    </Page>;

    return (
        <Page
            title="Staff"
            metaTitle="Placementt | Staff"
            metaDesc="View your institute's staff.">
            <SubNavBar
                pathLevel={4}
                items={[
                    {
                        label: "All",
                        route: "all",
                        element: staffTablePage(),
                    },
                    {
                        label: "User Groups",
                        route: "groups",
                        element: <StaffGroups/>,
                    },
                ]}/>
        </Page>
    );
}

function StaffGroups() {
    const userGroups = Object.fromEntries(Object.entries(useContext(OrganisationContext).userGroups as {[key:string]: UserGroupData}).filter(([, group]) => group.template === "Staff"));
    const navigate = useNavigate();
    const [deletePopup, setDeletePopup] = useState<{name: string, id: string}>();

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);

    return (
        <>
            <FilterList card={false} data={userGroups} title="User Groups" actionButton={<Button onClick={() => navigate("/institutes/userGroups", {state: {formData: {template: "Staff"}}})}>New User Group</Button>} hideNoResultsText>
                {Object.entries(userGroups).map(([key, group]) =>
                    <ListItemButton key={key} id={key} divider onClick={() => navigate("/institutes/userGroups/"+group.id, {state: {group: group}})}>
                        <ListItemText
                            primary={<Stack direction={"row"} alignItems={"center"} mb={1}><Typography fontWeight={"bold"} fontSize={"1.2em"} color={"primary"}>{group.name}</Typography>{group.default && <Chip label={"Default"}/>}</Stack>}
                        />
                        <ListItemSecondaryAction>
                            <Stack direction={"row"}>
                                <IconButtonPop responsive={false} title="Copy" onClick={(e) => {
                                    e.stopPropagation(); firebaseQuery.copy(["userGroups"], group.id, user.oId, user.product);
                                }}><CopyAll/></IconButtonPop>
                                <IconButtonPop responsive={false} title="Edit" onClick={() => navigate("/institutes/userGroups/"+group.id, {state: {group: group}})}><Edit/></IconButtonPop>
                                {group.default || <IconButtonPop responsive={false} title="Delete" onClick={() => setDeletePopup({name: group.name, id: group.id})}><Delete/></IconButtonPop>}
                            </Stack>
                        </ListItemSecondaryAction>
                    </ListItemButton>)}
            </FilterList>
            <DeletePopup key={"delete"} open={typeof deletePopup === "object"} onClose={() => setDeletePopup(undefined)} title="staff group" itemName={deletePopup?.name} onDelete={async () => deletePopup?.id ? firebaseQuery.delete(["userGroups", deletePopup?.id]) : undefined}/>
        </>
    );
}
