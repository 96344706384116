import {FormHelperText, MenuItem, TextField, TextFieldPropsSizeOverrides} from "@mui/material";
import {OverridableStringUnion} from "@mui/types";
import {QueryConstraint, QueryFieldFilterConstraint} from "firebase/firestore";
import {camelCase, countryList} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {ChangeEvent, ReactNode, useEffect, useState} from "react";
type Params = {
    value?: string|string[],
    onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    children?: ReactNode,
    disabled?: boolean,
    required?: boolean,
    multiple?: boolean,
    name?: string,
    path?: [(string | string[]), (QueryConstraint | QueryConstraint[] | QueryFieldFilterConstraint | QueryFieldFilterConstraint[] | undefined)],
    size?: OverridableStringUnion<"small" | "medium", TextFieldPropsSizeOverrides>,
    label?: string,
    sx?: {[key:string]: unknown},
    errorText?: string,
    id?: string,
    formatMenuItems?: (data: {[key: string]: unknown}) => ReactNode
}

export default function Dropdown({id, value, onChange, children, disabled, required, multiple, name, path, size="small", label, sx, errorText, formatMenuItems}:Params) {
    const [selected, setSelected] = useState(value ? multiple ? Array.isArray(value) ? value : value.split(",") : value : undefined);
    const [error, setError] = useState(false);
    const [items, setItems] = useState(children);

    const firebaseQuery = new FirebaseQuery();

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        console.log("ONCHANGE DROPDOWN", id, e);
        if (e.target.value === "" && required) {
            setError(true);
        } else {
            setError(false);
        }
        setSelected(e.target.value);
        onChange && onChange(e);
    };

    useEffect(() => {
        setSelected(multiple ? typeof value === "string" ? value.split(",") : value : (value || undefined));
    }, [multiple, value]);

    useEffect(() => {
        if (name === "country") {
            setItems(
                countryList.map((item) => <MenuItem value={camelCase(item)}>{item}</MenuItem>)
            );
            return;
        }

        if (!path) {
            setItems(children || "");
        }
    }, [children, name]);

    useEffect(() => {
    // Get items from database if none are given as children.

        if (!path) {
            return;
        }
        console.log("get items");
        firebaseQuery.getDocsWhere(...path).then((collectionResult: any) => {
            const i = Object.entries(collectionResult as {[key:string]: {name: string}}).map(([k, v]) => {
                return (formatMenuItems ? formatMenuItems({id: k, ...v}) : <MenuItem value={k}>{v.name}</MenuItem>);
            });
            setItems(children ? [...i, children] : i);
        });
    }, [path?.[0]]);

    useEffect(() => {
        if (path) {
            console.log("items", items);
        }
    }, [items]);

    return (
        <TextField fullWidth
            select
            SelectProps={{
                multiple: multiple,
            }}
            value={((multiple && !selected) ? [] : selected)}
            onBlur={handleChange}
            onChange={handleChange}

            {...{id, sx, label, disabled, size, name, required, error}}
        >
            {items}
            <FormHelperText error={error}>{error ? (errorText || "Required") : ""}</FormHelperText>
        </TextField>
    );
}
