import {ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {UserContext} from "../../../App";
import {Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Card, CardContent, CardHeader, Chip, Collapse, Grid, Icon, Link, Stack, Tab, Table, TableCell, TableRow, Tabs, Typography} from "@mui/material";
import {AddCircleOutlineRounded, ExpandMoreRounded, InfoOutlined} from "@mui/icons-material";
import CardPages from "../../../Components/CardPages";
import {ToggleFormItem, FadeInBox} from "../../../Util/styledComponents";
import IconButtonPop from "../../../Components/IconButtonPop";
import Form from "../../../Components/Form";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import {LoadingButton} from "../../../Components/LoadingButton";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import DeletePopup from "../../../Components/DeletePopup";
import {PageTitle} from "../../../Components/Page";
import SpreadsheetUploader from "../../../Components/SpreadsheetUploader";
import {where} from "firebase/firestore";
import styled from "styled-components";
import {camelCaseToNormal, executeCallable, UserData, validateEmail} from "placementt-core";
import ControlledSwitch from "../../../Components/FormComponents/ControlledSwitch/ControlledSwitch";
import FilterTable from "../../../Components/FilterTable";


const NewViewCard = styled(Card)`
     width: 650px;
     transition: scale 150ms ease-in-out;
     cursor: pointer;

        &:hover {
            scale: 1.05;
        }
    `;

const CreateCohortBox = styled(Box)`
    padding-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

type AdmissionsCohort = {
    id?: string,
    name: string,
    permissions: {[key:string]: unknown},
    students: {id: string, email:string, forename:string, surname:string}[],
    expiry?: string
};

export default function Admissions() {
    const [cohorts, setCohorts] = useState<{[key:string]: AdmissionsCohort}>();
    const [createCohortPos, setCreateCohortPos] = useState<number>(-1);
    const [tabPosition, setTabPosition] = useState(0);
    const [createCohortKey, setCreateCohortKey] = useState(0);
    const [deletePopup, setDeletePopup] = useState<string>();

    const user = useContext(UserContext) as UserData;
    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        firebaseQuery.getDocsWhere("admissionCohorts", [where("product", "==", "institutes"), where("oId", "==", user.oId)]).then((cohorts) => setCohorts(cohorts as {[key:string]: AdmissionsCohort}));
    }, []);

    useEffect(() => {
        if (createCohortPos === -1) {
            setCreateCohortKey((c) => c+1);
        }
    }, [createCohortPos]);

    console.log("c", cohorts);

    return (
        <Stack flex={1} position={"relative"}>
            <PageTitle>Admissions</PageTitle>
            <FadeInBox $visible={(createCohortPos === -1 && (cohorts && !Object.keys(cohorts).length) || false)} sx={{position: "absolute", height: "calc(100vh - 250px)", top: "100px", left: "50%", transform: "translateX(-50%)"}}>
                <CreateCohortBox onClick={() => setCreateCohortPos(0)}>
                    <NewViewCard sx={{maxWidth: "650px"}}>
                        <CardContent sx={{textAlign: "center", color: "grey"}}>
                            <Typography variant="h5">Click to create a new admissions cohort</Typography>
                            <Icon fontSize="large"><AddCircleOutlineRounded fontSize="large"/></Icon>
                            <Typography variant="h6">Request access to student accounts to view placement progress, skills development and provider feedback</Typography>
                        </CardContent>
                    </NewViewCard>
                </CreateCohortBox>
            </FadeInBox>
            <FadeInBox $visible={createCohortPos > -1} sx={{position: "absolute", width: "100%", top: "100px", height: "calc(100vh - 250px)"}}>
                <NewCohort key={createCohortKey} {...{createCohortPos, setCreateCohortPos}}/>
            </FadeInBox>
            {(cohorts && Object.keys(cohorts).length > 0) && <FadeInBox $visible={createCohortPos < 0}>
                <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                    <Tabs value={tabPosition} onChange={(e, n) => {
                        n < Object.keys(cohorts).length ? setTabPosition(n) : setCreateCohortPos(0);
                    }} aria-label="Cohort tab selector">
                        {Object.entries(cohorts).map(([, cohort]) => <Tab label={cohort.name} />)}
                        <Tab label={"+ New cohort"} />
                    </Tabs>
                </Box>
                {Object.entries(cohorts).map(([key, cohort], index) =>
                    <Box sx={{display: tabPosition === index ? "block" : "none", paddingTop: "16px"}}>
                        <Grid container>
                            <Grid item xs={12} lg={8}>
                                <Card>
                                    <CardContent>
                                        <FilterTable data={Object.fromEntries(cohort.students.map((student) => [student.id, student]))} access={Boolean(cohort.students.length)} columnTemplate={{status: (e:string) => <Chip color={["Accepted", "In institute"].includes(e) ? "success" : ["Rejected", "Account removed"].includes(e) ? "error" : "primary"} label={e || "Pending"}/>}} title="Students" columns={["forename", "surname", "email", "status"]}/>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Grid container direction={"column"}>
                                    <Grid item>
                                        <Card>
                                            <CardHeader title={"Permissions"}/>
                                            <CardContent>
                                                <Table>
                                                    {cohort.permissions && Object.entries(cohort.permissions).filter(([, v]) => v).map(([perm]) =>
                                                        <TableRow>
                                                            <TableCell>{camelCaseToNormal(perm)}</TableCell>
                                                        </TableRow>
                                                    )}
                                                </Table>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card>
                                            <CardContent>
                                                <Button onClick={() => setDeletePopup(key)} color="error">Delete</Button>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Box>
                )}
            </FadeInBox>}
            <DeletePopup title="cohort" itemName={deletePopup ? cohorts?.[deletePopup]?.name : ""} open={Boolean(deletePopup)} onClose={() => setDeletePopup(undefined)} onDelete={async () => {
                if (deletePopup) {
                    await executeCallable("admissions-deleteCohort", deletePopup); setDeletePopup(undefined); return;
                } else {
                    return;
                }
            }}/>
        </Stack>
    );
}

function NewCohort({createCohortPos, setCreateCohortPos}:{createCohortPos:number, setCreateCohortPos:Dispatch<SetStateAction<number>>}) {
    const [cohortData, setCohortData] = useState<{permissions?:{[key:string]: unknown}, students?:{email:string, forename:string, surname:string}[]}>();
    const [error, setError] = useState<string>();
    const [openAccordion, setOpenAccordion] = useState(0);
    const [name, setName] = useState("");
    const spreadsheetRef = useRef(null);

    const checkStudents = (e:{email:string, forename:string, surname: string}[]) => {
        const studentEmails = e.map((studentObj) => studentObj.email).filter((x) => x);

        if (!studentEmails.length) {
            setError(undefined);
            setCreateCohortPos((p) => p+1);
            return;
        }

        for (const email of studentEmails) {
            if (!validateEmail(email)) {
                setError(`Error in email formatting: '${email}'. Amend errors and reupload.`);
                return;
            }
        }
        setCohortData((c) => ({...c, students: e}));
        setError(undefined);
        setCreateCohortPos((p) => p+1);
        return;
    };

    const onSubmit = async () => {
        if (!cohortData) return;

        await executeCallable("admissions-createCohort", {cohortData: {...cohortData, name: name}}).then(() => {
            setCreateCohortPos(-2);
        }).catch((e) => {
            throw e;
        });
    };

    return (
        <CardPages pos={createCohortPos} sections={["Select permissions", "Input user emails", "Review details"]}>
            <Form button={false} onSubmit={(e) => setCohortData((c) => ({...c, permissions: e}))} functionType="sync">
                <Stack spacing={0}>
                    <Accordion expanded={openAccordion === 1} onChange={(e, expanded) => setOpenAccordion(expanded ? 1 : 0)}>
                        <AccordionSummary expandIcon={<ExpandMoreRounded/>}>
                            User profile
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ToggleFormItem>
                                        <Typography>Migrate student to institute</Typography>
                                        <Stack direction={"row"}>
                                            <ControlledSwitch id={"migrateStudent"} name={"migrateStudent"}/>
                                            <IconButtonPop responsive={false} title="Migrate entire student account into your institute. You will have complete control over the student's account."><InfoOutlined/></IconButtonPop>
                                        </Stack>
                                    </ToggleFormItem>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={openAccordion === 2} onChange={(e, expanded) => setOpenAccordion(expanded ? 2 : 0)}>
                        <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                            Analytics
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ToggleFormItem>
                                        <Typography>View personal placement analytics</Typography>
                                        <Stack direction={"row"}>
                                            <ControlledSwitch id={"viewStudentAnalytics"} name={"viewStudentAnalytics"}/>
                                            <IconButtonPop responsive={false} title="View student-defined placement targets."><InfoOutlined/></IconButtonPop>
                                        </Stack>
                                    </ToggleFormItem>
                                </Grid>
                                <Grid item xs={12}>
                                    <ToggleFormItem>
                                        <Typography>View personal skills development</Typography>
                                        <Stack direction={"row"}>
                                            <ControlledSwitch id={"viewStudentTargets"} name={"viewStudentTargets"}/>
                                            <IconButtonPop responsive={false} title="View student-defined skills targets."><InfoOutlined/></IconButtonPop>
                                        </Stack>
                                    </ToggleFormItem>
                                </Grid>
                                <Grid item xs={12}>
                                    <ToggleFormItem>
                                        <Typography>Set placement targets</Typography>
                                        <Stack direction={"row"}>
                                            <ControlledSwitch id={"setAnalytics"} name={"setAnalytics"}/>
                                            <IconButtonPop responsive={false} title="Set generic, time-based and year-based targets for students. View progress towards these."><InfoOutlined/></IconButtonPop>
                                        </Stack>
                                    </ToggleFormItem>
                                </Grid>
                                <Grid item xs={12}>
                                    <ToggleFormItem>
                                        <Typography>Set skills targets</Typography>
                                        <Stack direction={"row"}>
                                            <ControlledSwitch id={"setSkills"} name={"setSkills"}/>
                                            <IconButtonPop responsive={false} title="Set specific skills targets for students to develop. View student progress towards these. Note: Students cannot edit previous placements."><InfoOutlined/></IconButtonPop>
                                        </Stack>
                                    </ToggleFormItem>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={openAccordion === 3} onChange={(e, expanded) => setOpenAccordion(expanded ? 3 : 0)}>
                        <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                            Portfolio
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ToggleFormItem>
                                        <Typography>View portfolio</Typography>
                                        <Stack direction={"row"}>
                                            <ControlledSwitch id={"viewPortfolio"} name={"viewPortfolio"}/>
                                            <IconButtonPop responsive={false} title="View and download a PDF portfolio of student placements, skills and analytics."><InfoOutlined/></IconButtonPop>
                                        </Stack>
                                    </ToggleFormItem>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <InputGroup sx={{marginTop: "16px"}} type={"date"} label={"Expiry date"} name={"expiry"} required/>
                    <Stack direction={"row"} mt={"16px"} alignItems={"center"} justifyContent={"space-between"}>
                        <Link onClick={() => setCreateCohortPos(() => -1)}>Back</Link>
                        <Button type="submit" onClick={() => setCreateCohortPos((p) => p+1)}>Next</Button>
                    </Stack>
                </Stack>
            </Form>
            <Stack position={"relative"}>
                <SpreadsheetUploader ref={spreadsheetRef} noSubmitButton onSubmit={(e) => checkStudents(e as {email:string, forename:string, surname: string}[])} defaultCols={["forename", "surname", "email"]} noNewColumns/>
                <Collapse in={Boolean(error)}>
                    <Alert severity="error">{error}</Alert>
                </Collapse>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Link onClick={() => setCreateCohortPos((p) => p-1)}>Back</Link>
                    <Button onClick={() => spreadsheetRef.current ? (spreadsheetRef.current as {submit: () => void}).submit() : undefined}>Next</Button>
                </Stack>
            </Stack>
            <Stack position={"relative"}>
                <Stack>
                    <InputGroup name={"name"} label={"Cohort name"} value={name} onChange={(e:ChangeEvent<HTMLInputElement>) => setName(e.target.value)} required/>
                    <Table>
                        <TableRow>
                            <TableCell>Students</TableCell>
                            <TableCell>{cohortData?.students?.length}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><strong>Permissions</strong></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        {cohortData?.permissions && Object.entries(cohortData.permissions).filter(([, v]) => v).map(([perm]) =>
                            <TableRow>
                                <TableCell>{camelCaseToNormal(perm)}</TableCell>
                                <TableCell>True</TableCell>
                            </TableRow>
                        )}
                    </Table>
                    <Typography>To protect student data, we will not disclose whether students have accounts. You will see when students accept or reject these permissions. Unresponsive and non-existent accounts will be shown as pending.</Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Link onClick={() => setCreateCohortPos((p) => p-1)}>Back</Link>
                    <LoadingButton onClick={onSubmit} text="Create cohort"/>
                </Stack>
            </Stack>
        </CardPages>
    );
}
