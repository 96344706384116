import {Box, Button, Divider, Grid, Stack, Tab, Tabs, Typography} from "@mui/material";
import {CohortData, INSTITUTE_COLOUR, PROVIDER_COLOUR, STUDENT_COLOUR, convertDate, deleteStorageItem, getRandomNumber, storage, uploadFiles} from "placementt-core";
import IconButtonPop, {LightTooltip} from "./IconButtonPop";
import {Delete, FileUploadRounded} from "@mui/icons-material";
import {useContext, useEffect, useState} from "react";
import SlateEditor from "./Slate";
import FileUploaderPopup from "./FileUploaderPopup";
import {Descendant} from "slate";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {DocumentData, arrayRemove, arrayUnion} from "firebase/firestore";
import {Popup} from "./Popup";
import {Viewer, Worker} from "@react-pdf-viewer/core";
import {getDownloadURL, ref} from "firebase/storage";
import DeletePopup from "./DeletePopup";
import Card from "./Card";
import {OrganisationContext, UserContext} from "../App";
import CustomFormPage from "./CustomFormPage";


export default function DailyPlacementLog({placement, userType, access=[userType], card}:{placement:{startDate: string, endDate: string, id: string, uid: string, activeDates: string[], cohort?: string, cohortData?: CohortData, providerLogs?: string[], staffLogs?: string[], studentsLogs?: string[]}, card?: boolean, userType:"students"|"staff"|"provider", access?:Array<"students"|"staff"|"provider">}) {
    const [selectedDate, setSelectedDate] = useState<string>();

    const [fileUploaderOpen, setFileUploaderOpen] = useState(false);
    const [pageContent, setPageContent] = useState<Descendant[]|{[key: string]: unknown}>();
    const [files, setFiles] = useState<string[]>();
    const [slateKey, setSlateKey] = useState<number>();
    const [openFile, setOpenFile] = useState<{name: string, url: string}>();
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [tabPosition, setTabPosition] = useState<number>(access.indexOf(userType));
    const [log, setLog] = useState<DocumentData>();
    const [cohortData, setCohortData] = useState<CohortData>();

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);
    const institute = useContext(OrganisationContext) as {cohorts: {[key: string]: CohortData}};


    const saveLog = async (value: Descendant[]|{[key: string]: unknown}) => {
        console.log("value", value);
        const empty = (Array.isArray(value) && value.length === 0) || (Object.entries(Object.values(value)[0] as {[key: string]: unknown}).filter(([k, v]) => (![`${userType}Uid`, "completed"].includes(k)) && v)).length === 0;

        await firebaseQuery.set(["logs", `${placement.id}-${selectedDate}`], {[userType]: empty ? null : Array.isArray(value) ? value : Object.values(value)[0]}, true);
        await firebaseQuery.update(["placements", placement.id], {[userType+"Logs"]: empty ? arrayRemove(selectedDate) : arrayUnion(selectedDate)});
    };

    useEffect(() => {
        console.log(placement);
        firebaseQuery.getDocData(["logs", `${placement.id}-${selectedDate}`]).then((d) => {
            setLog(d);
        }).catch(() => setLog(undefined));
    }, [selectedDate, tabPosition]);

    useEffect(() => {
        setSelectedDate(convertDate(new Date(), "dbstring") as string);


        const getCohortData = async () => {
            if (userType === "provider") {
                if (user.product === "providers" && placement.cohort) {
                    return await firebaseQuery.getDocData(["cohorts", placement.cohort]) as CohortData;
                } else {
                    return placement.cohortData;
                }
            }
            if (userType === "students" && placement.uid === user.id) return user.cohortData;
            if (userType === "staff" && user.product === "institutes") return institute.cohorts[placement.cohort || ""];
            return undefined;
        };
        getCohortData().then(setCohortData);
    }, []);

    const submitFiles = async (files: any[]) => {
        if (!selectedDate) return;
        await uploadFiles(files, ["placements", placement.id, "log", `${userType}_files`, selectedDate]);
        await firebaseQuery.set(["logs", `${placement.id}-${selectedDate}`], {[`${userType}_files`]: arrayUnion(...files.map((file) => file.name))}, true);
        setFiles((f) => [...(f || []), ...files.map((file) => file.name)]);
        setFileUploaderOpen(false);
    };

    const deleteFile = async () => {
        if (!openFile) return;
        await deleteStorageItem(`placements/${placement.id}/log/${userType}_files/${selectedDate}/${openFile.name}`);
        setOpenFile(undefined);
        setDeletePopup(false);
        setFiles((f) => {
            if (!f) return [];
            return f.filter((file) => file !== openFile.name);
        });
        await firebaseQuery.set(["logs", `${placement.id}-${selectedDate}`], {[`${userType}_files`]: arrayRemove(openFile.name)}, true);
    };

    useEffect(() => {
        if (!log) return;
        setPageContent(log[access[tabPosition]]);
        setFiles(log[`${access[tabPosition]}_files`]);
        setSlateKey(getRandomNumber(0, 100000));
    }, [tabPosition, log]);


    const innerComponent = <>
        <Typography variant="h6">Daily placement log</Typography>
        <Tabs allowScrollButtonsMobile value={selectedDate} onChange={(_, value) => setSelectedDate(value)}>
            {placement.activeDates.map((dateStr, i) => {
                const date = new Date(dateStr);
                return (<Tab value={dateStr} label={
                    <Stack spacing={0}>
                        <Typography fontSize={12} color={"GrayText"}>{date.getDate() === 1 || selectedDate === dateStr || i === 0 ? date.toLocaleString("default", {month: "long"}) : <>&nbsp;</>}</Typography>
                        <Typography fontSize={16} fontWeight={selectedDate === dateStr ? "bold" : undefined}>
                            {date.getDate()}
                        </Typography>
                        <Stack direction={"row"} justifyContent={"center"} spacing={1} mt={1}>
                            {selectedDate && access.includes("students") && placement?.studentsLogs?.includes(dateStr) && <LightTooltip title={"Student has completed."}><Box borderRadius={10} width={10} height={10} bgcolor={STUDENT_COLOUR}/></LightTooltip>}
                            {selectedDate && access.includes("provider") && placement?.providerLogs?.includes(dateStr) && <LightTooltip title={"Employer has completed."}><Box borderRadius={10} width={10} height={10} bgcolor={PROVIDER_COLOUR}/></LightTooltip>}
                            {selectedDate && access.includes("staff") && placement?.staffLogs?.includes(dateStr) && <LightTooltip title={"Institute has completed."}><Box borderRadius={10} width={10} height={10} bgcolor={INSTITUTE_COLOUR}/></LightTooltip>}
                        </Stack>
                    </Stack>}/>);
            })}
        </Tabs>
        {access.length > 1 &&
            <Tabs sx={{width: "100%"}} value={tabPosition} onChange={(e, n) => setTabPosition(n)}>
                {(access.includes("staff")) && <Tab label={"Staff log"}/>}
                {(access.includes("provider")) && <Tab label={"Provider log"}/>}
                {(access.includes("students")) && <Tab label={"Student log"}/>}
            </Tabs>}
        {userType !== access[tabPosition] && <Typography color={"grey"} fontSize={"0.9em"}>Selected log is read-only</Typography>}
        {userType === "students" && <Typography color={"grey"} fontSize={"0.9em"}>Your log will be visible to your provider and institute.</Typography>}
        <Grid container mt={0} mb={1}>
            {files && files.map((file) =>
                <Grid item xs={3}><Button onClick={async () => {
                    const url = await getDownloadURL(ref(storage, `placements/${placement.id}/log/${access[tabPosition]}_files/${selectedDate}/${file}`));
                    setOpenFile({name: file, url: url});
                }} sx={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", justifyContent: "left", maxWidth: "100%"}}>{file}</Button></Grid>)}
            {userType === access[tabPosition] && <Grid item xs={3}><Button startIcon={<FileUploadRounded/>} onClick={() => setFileUploaderOpen(true)}>Upload file</Button></Grid>}
        </Grid>

        {cohortData?.logType === "custom" && cohortData.logs?.[access[tabPosition]] ?
            <CustomFormPage disabled={userType !== access[tabPosition]} autosave formData={Array.isArray(pageContent) ? undefined : pageContent} onComplete={async (e) => saveLog(e)} embedded name={access[tabPosition]} schema={["forms", cohortData.logs?.[access[tabPosition]] as string]}/> :
            slateKey && <Box minHeight={"100px"}><SlateEditor key={slateKey} readOnly={userType !== access[tabPosition]} initialValue={Array.isArray(pageContent) ? pageContent : undefined} onSave={saveLog} onChange={() => null}/></Box>}
        <FileUploaderPopup accept="any" onSubmit={submitFiles} open={fileUploaderOpen} onClose={() => setFileUploaderOpen(false)}/>
        <Popup title={openFile?.name} key = {"FileViewer"} onClose = {() => setOpenFile(undefined)} open = {Boolean(openFile)} fullWidth actions={userType === access[tabPosition] && <IconButtonPop title={"Delete"} onClick={() => setDeletePopup(true)}><Delete/></IconButtonPop>}>
            {openFile?.url.split(".").pop() === "pdf" ? <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                {openFile && <Viewer fileUrl = {openFile?.url}/>}
            </Worker> : <Button onClick={() => window.open(openFile?.url, "_blank")}>Download file</Button>}
        </Popup>
        <DeletePopup open={deletePopup} onClose={() => setDeletePopup(false)} title={openFile?.name || ""} onDelete={() => deleteFile()}/>
    </>;


    if (!cohortData) {
        return null;
    }

    if (card) {
        return (
            <Card sx={{marginTop: "16px", textAlign: "start"}}>
                {innerComponent}
            </Card>
        );
    } else {
        return (
            <Box sx={{marginTop: "16px", paddingLeft: "16px", textAlign: "start", width: "100%"}}>
                <Divider sx={{marginBottom: "16px"}}/>
                {innerComponent}
                <Divider/>
            </Box>
        );
    }
}
