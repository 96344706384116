import {CheckRounded, CloseRounded} from "@mui/icons-material";
import {Divider, Icon, Radio, Stack, Table, TableCell, TableRow, Typography} from "@mui/material";
import {where} from "firebase/firestore";
import {BillingPackage, camelCaseToNormal, capitaliseWords, PRIMARY_COLOUR, Products} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {useEffect, useState} from "react";


export default function BillingTable({product, selectedPackage, setSelectedPackage}:{product: Products, selectedPackage?: string, setSelectedPackage?: React.Dispatch<React.SetStateAction<string>>}) {
    const [billingPackages, setBillingPackages] = useState<{[key: string]: BillingPackage}>();
    const [features, setFeatures] = useState<string[]>();

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        firebaseQuery.getDocsWhere("billing", where("product", "==", product)).then((items) => {
            console.log("PACKGAGES", items);
            const entries = Object.entries(items as {[key: string]: BillingPackage});

            entries.sort(([, a], [, b]) => a.priority - b.priority);

            const featureKeys: string[] = [];

            // Step 3: Iterate over the sorted entries and collect unique feature keys
            for (const [, billingPackage] of entries) {
                for (const featureKey of Object.keys(billingPackage.features)) {
                    if (!featureKeys.includes(featureKey)) {
                        featureKeys.push(featureKey);
                    }
                }
            }
            setFeatures(featureKeys);
            const sortedBillingPackages = Object.fromEntries(entries);

            setBillingPackages(sortedBillingPackages);
        });
    }, []);

    return (
        <Table>
            <TableRow sx={{verticalAlign: "top"}}>
                <TableCell sx={{fontWeight: "bold", color: PRIMARY_COLOUR, verticalAlign: "middle"}}>Feature</TableCell>
                {Object.entries(billingPackages || {}).map(([k, v]) => <TableCell sx={{"padding": 2, "position": "relative", ":after": {position: "absolute", content: "''", left: 0, top: "25%", width: 0, height: "50%", borderLeft: "1px solid lightgrey"}, "fontWeight": "bold", "color": PRIMARY_COLOUR}} key={`${k}_title`}>
                    <Stack direction={"row"} alignItems={"center"}>
                        {setSelectedPackage && <Radio
                            checked={selectedPackage === k}
                            onChange={() => setSelectedPackage(k)}
                            name="radio-buttons"
                            inputProps={{"aria-label": k}}
                        />}
                        {v.name}
                    </Stack>
                    <Typography variant="caption" color={"CaptionText"}>{v.description}</Typography>
                </TableCell>)}
            </TableRow>
            {features?.map((feature) =>
                <TableRow>
                    <TableCell sx={{fontWeight: "bold"}}>{capitaliseWords(camelCaseToNormal(feature))}</TableCell>
                    {Object.entries(billingPackages || {}).map(([k, v]) => <TableCell sx={{textAlign: "center"}} key={`${k}_title`}>{(v.features[feature] === 1000000 ? "Unlimited" : v.features[feature] === true ? <Icon><CheckRounded color="success"/></Icon> : v.features[feature]) || <Icon><CloseRounded color="error"/></Icon>}</TableCell>)}
                </TableRow>)}
            <TableRow>
                <TableCell colSpan={4}>
                    <Divider/>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{fontWeight: "bold"}}>£ / User / Month</TableCell>
                {Object.entries(billingPackages || {}).map(([k, v]) => <TableCell sx={{textAlign: "center", fontWeight: "bold"}} key={`${k}_staffCost`}>£{v.staffUnitCost}</TableCell>)}
            </TableRow>
            <TableRow>
                <TableCell sx={{fontWeight: "bold"}}>£ / Placement</TableCell>
                {Object.entries(billingPackages || {}).map(([k, v]) => <TableCell sx={{textAlign: "center", fontWeight: "bold"}} key={`${k}_placementCost`}>£{v.placementUnitCost}</TableCell>)}
            </TableRow>
        </Table>
    );
}
