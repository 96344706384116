import {Alert, Box, Button, Collapse, Stack, Table, TableCell, TableRow, Typography} from "@mui/material";
import React, {ReactNode, forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Popup} from "./Popup";
import {CellChange, CellLocation, ReactGrid, Row, TextCell} from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import {camelCase, camelCaseToNormal, capitaliseWords} from "placementt-core";
import IconButtonPop from "./IconButtonPop";
import {Delete} from "@mui/icons-material";
import styled from "styled-components";
// import {Importer, ImporterField} from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import {LoadingButton} from "./LoadingButton";
import InputGroup from "./FormComponents/InputGroup";
import Form from "./Form";

const SpreadsheetContainer = styled(Box)`
    max-height: 500px;
    overflow-y: auto;
    position: relative;
    
    div {
        color: black !important;
    }

    .rg-header-cell {
        background: none !important;
        border: none !important;
        border-bottom: 1px solid #e8e8e8 !important;
        text-align: center !important;
        display: block !important;
        font-weight: bold;
    }

    input {
        outline: none !important;
        border: none !important;
    }
`;

type Params = {
    defaultCols: string[],
    noNewRows?: boolean,
    noNewColumns?: boolean,
    noSubmitButton?: boolean,
    formatColLabels?: (label:string) => string,
    onChange?: () => void,
    alert?: {
        severity: "warning" | "error" | "success" | "info";
        msg: string;
    },
    onSubmit: (users: {[key:string]: unknown}[]) => void,
    initialData?: {[key:string]: unknown}[],
    cellTypes?: {[key:string]: ["dropdown", string[], string]},
    utilityButtons?: ReactNode,
    colWidth?: number,
    funcType?: "sync"|"async"
}
const SpreadsheetUploader = forwardRef(({onSubmit, initialData, funcType="sync", onChange, noSubmitButton, defaultCols, noNewColumns, noNewRows, alert, cellTypes, formatColLabels, utilityButtons, colWidth}:Params, ref) => {
    const [columnNames, setColumnNames] = useState(defaultCols);
    const [colWidths, setColWidths] = useState(colWidth);

    const containerRef = useRef<HTMLDivElement>();

    const isMacOs = () => window.navigator.appVersion.indexOf("Mac") !== -1;

    const getEmptyData = (rows=1) => {
        const emptyObject = [];

        for (let i = 0; i < rows; i++) {
            const emptyRow: {[key:string]: unknown} = Object.fromEntries(columnNames.map((name) => ([name, ""])));
            cellTypes && Object.entries(cellTypes).forEach(([key, type]) => {
                emptyRow[`${key}IsOpen`] = false;
                emptyRow[key] = type[2];
            });
            emptyObject.push(emptyRow);
        }

        return emptyObject;
    };

    const [data, setData] = useState<{[key:string]: unknown}[]>(initialData || getEmptyData());
    const [selectedCell, setSelectedCell] = useState<CellLocation>({rowId: 0, columnId: columnNames[0]});

    const [colNamePopup, setColNamePopup] = useState(false);

    const [cellChangesIndex, setCellChangesIndex] = React.useState(() => -1);
    const [cellChanges, setCellChanges] = React.useState<CellChange<TextCell>[][]>(() => []);

    // const [uploader, setUploader] = useState(0);

    useImperativeHandle(ref, () => ({
        submit: () => formatAndSubmit(),
    }));

    useEffect(() => {
        onChange && onChange();
    }, [data]);

    const submitLabelForm = (column: string, action:"create"|"delete") => {
        if (action === "create") {
            if (!column) return;
            if (column.trim().length === 0) return;
            setData((prev) => (prev.map((row) => ({...row, [camelCase(column)]: ""}))));

            setColumnNames((c) => ([...c, camelCase(column)]));
        }
        if (action === "delete") {
            setColumnNames((c) => {
                const n = c ? [...c] : [];
                const index = n.indexOf(column);
                n.splice(index, 1);
                return n;
            });
            setData((d) => d.map((row) => {
                if (!column) {
                    return row;
                }
                const n = {...row};
                delete n[column];
                return n;
            }));
        }

        setColNamePopup(false);
    };

    const formatAndSubmit = async () => {
        if (!columnNames) return;
        console.log("colnames", columnNames);
        const submissionData = data.map((row) => Object.entries(row).reduce((acc, [key, value], index) => {
            if (key.includes("IsOpen")) {
                return acc;
            }
            const columnName = columnNames[index].trim();
            acc[columnName] = value ? (value as string).trim() : "";
            return acc;
        }, Object.fromEntries(columnNames.map((columnName) => [columnName])) as {[key:string]:unknown})).filter((row) => {
            // if dropdowns are default and no other values
            // This filters empty rows
            const rowToProcess = {...row};
            if (cellTypes) {
                Object.entries(cellTypes).forEach(([key, item]) => {
                    if (item[0] !== "dropdown") return;

                    if (rowToProcess[key] === item[2]) {
                        delete rowToProcess[key];
                    }
                });
            }
            const filteredRow = Object.entries(rowToProcess).filter(([, v])=>!!v);
            return filteredRow.length > 0;
        });
        await onSubmit(submissionData);
    };

    const headerRow: Row = {
        rowId: "header",
        cells: columnNames.map((name) => ({type: "header", text: formatColLabels ? formatColLabels(name) : capitaliseWords(camelCaseToNormal(name))})),
    };

    const getRows = (): Row[] => [
        headerRow,
        ...data.map<Row>((row, idx) => ({
            rowId: idx,
            cells: Object.entries(row).filter(([k]) => !k.includes("IsOpen")).map(([k, item]) => {
                const cellType = cellTypes ? cellTypes[k.toLowerCase()] : undefined;
                if (!cellType) {
                    return ({type: "text", text: item as string});
                }
                return ({type: "dropdown", isOpen: Boolean(row[`${k.toLowerCase()}IsOpen`]), selectedValue: item as string, values: cellType[1].map((i) => ({value: camelCase(i), label: i}))});
            }),
        })),
    ];


    const handleChange = (
        changes: CellChange<any>[],
        usePrevValue=false,
        undoRedo=false
    ) => {
        if (!changes) return;
        if (!undoRedo) {
            setCellChanges([...cellChanges.slice(0, cellChangesIndex + 1), changes]);
            setCellChangesIndex(cellChangesIndex + 1);
        }

        setData((prevData) => {
            changes.forEach((change) => {
                const fieldName = change.columnId as string;
                let dataRow = prevData[change.rowId as number];
                if (!dataRow) {
                    dataRow = getEmptyData()[0];
                    prevData.push(dataRow);
                }
                if (change.type === "text" && typeof dataRow[fieldName] === "string") {
                    dataRow[fieldName] = change[usePrevValue ? "previousCell" : "newCell"].text.replaceAll("\r", "") as never;
                }
                if (change.type === "dropdown" && cellTypes) {
                    if ((change.newCell.selectedValue !== change.previousCell.selectedValue) && cellTypes[fieldName.toLowerCase()][1].includes(capitaliseWords(camelCaseToNormal(change[usePrevValue ? "previousCell" : "newCell"].selectedValue)))) {
                        dataRow[fieldName] = change[usePrevValue ? "previousCell" : "newCell"].selectedValue as never;
                    }
                    dataRow[`${fieldName.toLowerCase()}IsOpen`] = change[usePrevValue ? "previousCell" : "newCell"].isOpen;
                }
            });
            return [...prevData];
        });
    };

    const pasteIntoTable = (e: React.ClipboardEvent<HTMLDivElement>) => {
        const rawString = e.clipboardData.getData("Text");
        const stringToRows = rawString.replaceAll("\r", "").split("\n");
        const processedObject = stringToRows.map((row) => row.split("\t"));

        const selectedRow = selectedCell.rowId as number;
        const totalRows = data.length;

        const rowsToAdd = processedObject.length + selectedRow - totalRows;
        if (rowsToAdd <= 0) return;

        const dataToAdd = processedObject.slice(-rowsToAdd);

        setData((prev) => ([...prev, ...dataToAdd.map((row) => {
            const rowLength = row.length;
            const indexOfFirstColumn = columnNames.indexOf(selectedCell.columnId as string);
            const cols = columnNames.slice(indexOfFirstColumn, indexOfFirstColumn+rowLength);
            const partialObject = Object.fromEntries(cols.map((col, index) => ([col, row[index]])));

            const emptyRow: {[key:string]: unknown} = Object.fromEntries(columnNames.map((name) => ([name, partialObject[name] || ""])));
            cellTypes && Object.entries(cellTypes).forEach(([key, type]) => {
                emptyRow[`${key}IsOpen`] = false;
                emptyRow[capitaliseWords(key)] = type[2];
            });
            return emptyRow;
        })]));
    };

    const handleUndoChanges = () => {
        console.log("changes", cellChanges, cellChangesIndex);
        if (cellChangesIndex >= 0) {
            handleChange(cellChanges[cellChangesIndex], true, true);
            setCellChangesIndex((p) => p-1);
        }
    };
    const handleRedoChanges = () => {
        if (cellChangesIndex + 1 <= cellChanges.length - 1) {
            handleChange(cellChanges[cellChangesIndex+1], false, true);
            setCellChangesIndex((p) => p+1);
        }
    };

    useEffect(() => {
        const containerWidth = containerRef.current?.getBoundingClientRect().width;
        if (!containerWidth) return;
        setColWidths(Math.max(containerWidth / columnNames.length, 100));
    }, []);

    return (
        <Stack direction={"column"} position={"relative"} spacing={2}>
            <Collapse in={Boolean(alert)}>
                <Alert severity={alert?.severity}>{alert?.msg}</Alert>
            </Collapse>
            { /* <Tabs value={uploader} onChange={(e, n) => setUploader(n)}>
                <Tab label={"Upload file"}/>
                <Tab label={"Spreadsheet"}/>
            </Tabs>
            <Stack display={uploader === 0 ? "flex" : "none"}>
                <Importer
                    dataHandler={async (rows, {startIndex}) => {
                        if (startIndex !== 0) return;
                        setData(rows);
                    }}
                    defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                    restartable={true} // optional, lets user choose to upload another file when import is complete
                    onComplete={({file, preview, fields, columnFields}) => {
                        formatAndSubmit();
                    }}
                >
                    {
                        columnNames.map((name) => <ImporterField name={name} label={camelCaseToNormal(name)} optional={true}/>)
                    }
                </Importer>
                {noNewColumns || <Button onClick={() => setColNamePopup(true)}>Add column</Button>}
            </Stack>
                <Stack display={uploader === 1 ? "flex" : "none"}>*/}
            <SpreadsheetContainer ref={containerRef} onPaste={pasteIntoTable} onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if ((!isMacOs() && e.ctrlKey) || e.metaKey) {
                    switch (e.key) {
                    case "z":
                        e.stopPropagation();
                        e.preventDefault();
                        e.shiftKey ? handleRedoChanges() : handleUndoChanges();
                        return;
                    case "y":
                        e.stopPropagation();
                        e.preventDefault();
                        handleRedoChanges();
                        return;
                    }
                }
            }}>
                {colWidths && <ReactGrid rows={getRows()} minColumnWidth={colWidth} enableFullWidthHeader enableRangeSelection columns={columnNames.map((name) => ({columnId: name, width: colWidths}))} onCellsChanged={handleChange} onFocusLocationChanged={setSelectedCell}/>}
            </SpreadsheetContainer>
            <Typography sx={{alignSelf: "flex-start", color: "#00000050", fontSize: "0.8em", textAlign: "left"}}>Tip: Copy and paste entire tables without manually adding rows. Click the top left cell and paste your dataset.</Typography>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                    {noNewRows || <Button onClick={() => setData((prev) => ([...prev, ...getEmptyData()]))}>Add row</Button>}
                    {noNewColumns || <Button onClick={() => setColNamePopup(true)}>Add column</Button>}
                    {utilityButtons}
                </Stack>
                {noSubmitButton || (funcType === "sync" ? <Button variant={"contained"} onClick={formatAndSubmit}>Submit</Button> : <LoadingButton variant="contained" noFlexGrow onClick={async () => await formatAndSubmit()} text="Submit"/>)}
            </Stack>
            {/* {</Stack>} */}

            <Popup open={colNamePopup} onClose={() => setColNamePopup(false)} title={"Enter column name"}>
                <Table size="small">
                    {columnNames.map((column) =>
                        <TableRow>
                            <TableCell>{column}</TableCell>
                            <TableCell>{defaultCols.includes(column) ? <Typography fontSize={"0.8em"} sx={{opacity: 0.5}}>Required</Typography> : <IconButtonPop onClick={() => submitLabelForm(column, "delete")} title="Delete"><Delete/></IconButtonPop>}</TableCell>
                        </TableRow>)}
                </Table>
                <Form onSubmit={(e) => submitLabelForm(e.name as string, "create")} submitText="Create" functionType={"sync"}>
                    <InputGroup name={"name"} label={"Column name"} required/>
                </Form>
            </Popup>
        </Stack>
    );
});

export default SpreadsheetUploader;
