/* eslint-disable new-cap */
import Page from "../../Components/Page";
import Form from "../../Components/Form";
import InputGroup from "../../Components/FormComponents/InputGroup";
import Dropdown from "../../Components/FormComponents/Dropdown";
import {MenuItem, Grid, Divider, Chip, Stack, Box, Typography} from "@mui/material";
import ListMaker from "../../Components/FormComponents/ListMaker";
import FileDropzone from "../../Components/FileDropzone/FileDropzone";
// import {useState} from "react";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {Blog, BlogCategories, camelCaseToNormal, capitaliseWords, convertDate, readingTime, storage, uploadFiles} from "placementt-core";
import SlateEditor from "../../Components/Slate";
import {ChangeEvent, useEffect, useState} from "react";
import ProfileLogo from "../../Components/Public/ProfileLogo";
import {getDownloadURL, ref} from "firebase/storage";
import {serialiseSlate} from "../../Util/util";
import {useParams} from "react-router-dom";

const defaultBlogData: Blog = {
    title: "",
    summary: "",
    author: "",
    uploaded: "",
    category: "placementt",
    tags: "",
};


export default function CreateBlog() {
    const firebaseQuery = new FirebaseQuery();
    const [blog, setBlog] = useState<Blog>(defaultBlogData);
    const [authorImage, setAuthorImage] = useState<string>("");
    const [heroImage, setHeroImage] = useState<{object?: File, url: string}>();
    let {id} = useParams();

    const handleSubmission = async () => {
        if (id) {
            (await firebaseQuery.update(["blogs", id], {...blog, uploaded: convertDate(new Date(), "dbstring")}));
        } else {
            id = (await firebaseQuery.add(["blogs"], {...blog, uploaded: convertDate(new Date(), "dbstring")})).id;
        }
        if (heroImage?.object) {
            await uploadFiles([heroImage.object], ["blogs", `${id}.png`]);
        }
    };

    const onImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e?.target?.files?.[0];
        if (!file) return;
        setHeroImage({object: file, url: URL.createObjectURL(file)});
    };

    useEffect(() => {
        if (!blog?.author) return;
        const getLogo = async () => {
            const image = await getDownloadURL(ref(storage, `/profilePics/${blog?.author}.png`));
            setAuthorImage(image);
        };
        getLogo();
    }, [blog?.author]);

    useEffect(() => {
        if (!id) return;
        firebaseQuery.getDocData(["blogs", id]).then(async (doc) => {
            setBlog(doc as Blog);
            const authorImage = await getDownloadURL(ref(storage, `/profilePics/${doc?.author}.png`));
            setAuthorImage(authorImage);
            const heroImage = await getDownloadURL(ref(storage, `/blogs/${id}.png`));
            setHeroImage({url: heroImage});
        });
    }, [id]);

    return (
        <Page
            title="Create blog"
            metaTitle="Admin | Create Blog"
            grid>
            <Grid item xs={12} md={6}>
                <Form submitText={"Create Blog"} sx={{maxWidth: "600px"}} onSubmit={handleSubmission}>
                    <Grid container>
                        <Grid item xs={12}>
                            <InputGroup required value={blog.title} name={"title"} label="Title" onChange={(e: ChangeEvent<HTMLInputElement>) => setBlog((b) => ({...b, title: e.target.value}))}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputGroup required name={"summary"} value={blog.summary} label="Summary" multiline={true} rows={4} onChange={(e: ChangeEvent<HTMLInputElement>) => setBlog((b) => ({...b, summary: e.target.value}))}/>
                        </Grid>
                        <Grid item xs={12} >
                            <input name="body" hidden readOnly value={JSON.stringify(blog.body)}/>
                            {((id && blog.body?.length) || !id) && <SlateEditor initialValue={blog.body} onSave={async (a) => setBlog((b) => ({...b, body: a}))} onChange={async (a) => setBlog((b) => ({...b, body: a}))}/>}
                        </Grid>
                        <Grid item xs={12}>
                            <Dropdown label={"Category"} value={blog.category} required name={"category"} onChange={(e) => setBlog((b) => ({...b, category: e.target.value as BlogCategories}))}>
                                <MenuItem value={"institutes"}>Institutes</MenuItem>
                                <MenuItem value={"students"}>Students</MenuItem>
                                <MenuItem value={"providers"}>Providers</MenuItem>
                                <MenuItem value={"placementt"}>General</MenuItem>
                            </Dropdown>
                        </Grid>
                        <Grid item xs={12}>
                            <FileDropzone required accept={"image/png"} label = "Upload Header Image" name={"headerImage"} defUploadText = "Upload header image" onChange={(e: ChangeEvent<HTMLInputElement>) => onImageUpload(e)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ListMaker required name={"tags"} items={blog.tags} label="Tags" onChange={(e) => setBlog((b) => ({...b, tags: e.join(", ")}))}>
                                <InputGroup name={"tags"} placeholder={"Start typing..."} />
                            </ListMaker>
                        </Grid>
                        <Grid item xs={12}>
                            {((id && blog.author) || !id) && <Dropdown name="author" required label="Author" value={blog.author} onChange={(e) => setBlog((b) => ({...b, author: e.target.value}))}>
                                <MenuItem value={"daisyCookson"}>Daisy Cookson</MenuItem>
                                <MenuItem value={"thomasBarton"}>Thomas Barton</MenuItem>
                                <MenuItem value={"kiitanAbel-Ajala"}>Kiitan Abel-Ajala</MenuItem>
                            </Dropdown>}
                        </Grid>
                    </Grid>
                </Form>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid item>
                    <Box
                        component = "img"
                        width={"100%"}
                        marginTop = {5}
                        marginBottom = {5}
                        src={heroImage?.url}
                    />
                </Grid>
                <Grid item>
                    <h1>{blog?.title}</h1>
                    <Stack direction = "row" alignItems={"center"} justifyContent={"space-between"}>
                        <Stack direction = "row" alignItems={"center"}>
                            <ProfileLogo src={authorImage}/>
                            <Stack spacing={0} sx={{opacity: 0.75}}>
                                <Typography>By {capitaliseWords(camelCaseToNormal(blog?.author))}</Typography>
                                <Typography>Published on October 9, 2023 at 5:30pm</Typography>
                            </Stack>
                        </Stack>
                        <Typography>Reading time: {readingTime(blog.summary + JSON.stringify(blog.body))}</Typography>
                    </Stack>
                </Grid>

                <Divider sx={{marginTop: 4, marginBottom: 3}}/>
                <Stack direction={"row"}>
                    <Chip label={capitaliseWords(blog.category)} color={blog?.category === "placementt" ? "primary" : blog?.category}/>
                    <Divider orientation="vertical" sx={{height: "25px"}}/>
                    <Stack direction={"row"} flexWrap="wrap" spacing={0}>
                        {blog?.tags?.split(",").map((tag: string) => {
                            return (
                                <Chip style={{marginBottom: "8px", marginRight: "8px", alignSelf: "flex-start"}} label = {tag} color = {"primary"}/>
                            );
                        })}
                    </Stack>
                </Stack>
                <Grid item mt={3}>
                    <Typography fontWeight={600}>{blog?.summary}</Typography>
                    {serialiseSlate(blog.body || [])}
                </Grid>
            </Grid>
        </Page>
    );
}
