import {Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Stack, Typography} from "@mui/material";
import {ChangeEvent, Children, ReactNode, cloneElement, isValidElement, useEffect, useState} from "react";
import IconButtonPop from "../IconButtonPop";
import {AddCircleOutline, RemoveCircleOutline} from "@mui/icons-material";
import {capitalise, getRandomNumber} from "placementt-core";


type Params = {
    items?: string | (string|{[key:string]:string})[],
    name: string,
    label: string,
    subtitle?: string,
    separator?: string,
    required?: boolean,
    onChange?: (e: (string|{[key:string]:string})[]) => void,
    children: ReactNode,
    multipleInputs?: boolean,
    header?: boolean,
    noDefault?: boolean
}


export default function ListMaker({items, name, label, subtitle, separator=", ", required, onChange, children, multipleInputs, header, noDefault}:Params) {
    const [fItems, setFItems] = useState<{[key:string]:string|{[key:string]:string}}>(items ?
        Object.fromEntries(typeof items === "string" ? items.split(separator).map((item) => [getRandomNumber(0, 100000000), item]) : items.map((item) => [getRandomNumber(0, 1000000000), item])) :
        noDefault ? {} : {0: multipleInputs ? {} : ""});
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!items) return;

        if (typeof items === "string") {
            setFItems(Object.fromEntries(items.split(separator).map((item) => [getRandomNumber(0, 100000000), item])));
            return;
        }
        setFItems(Object.fromEntries(items.map((item) => [getRandomNumber(0, 1000000000), item])));
    }, []);

    useEffect(() => {
        if (!onChange || !fItems) return;

        onChange(Object.entries(fItems).map(([, v]) => v).filter((v) => v));
    }, [fItems]);

    const checkError = () => {
        if (!required) return;
        if (!Object.entries(fItems).map(([, v]) => v).filter((v) => v).join(separator).length) {
            setError(true);
            return;
        }
        setError(false);
    };

    const addControlStates = (children:ReactNode, k: string, values: {[key: string]: unknown}|string):ReactNode => {
        return Children.map(children, (child,) => {
            if (!isValidElement(child)) {
                return child;
            }

            const itemName: string|undefined = child.props.name;

            const props:{[key: string]: any} = itemName ? {
                onBlur: checkError,
                value: typeof values === "string" ? values : values?.[itemName],
                onChange: (e:ChangeEvent<HTMLInputElement>, additionalInfo?: unknown) => {
                    const value = typeof e === "string" ? e : e.target.value;
                    const nameToUpdate = child.key?.toString().includes("dateRangePicker") ? `${additionalInfo}${capitalise(itemName)}` : itemName;
                    child.props.onChange && child.props.onChange();
                    setFItems((i) => ({...i, [k]: multipleInputs ? {...((i as {[key: string]: {[key: string]: string}})[k] || {}), [nameToUpdate]: value} : value}));
                },
            } : {};

            if (child.key?.toString().includes("dateRangePicker")) {
                props.start = typeof values === "string" ? values : values?.[`start${capitalise(itemName)}`];
                props.end = typeof values === "string" ? values : values?.[`end${capitalise(itemName)}`];
            }

            const childzFurtherChildren = child.props.children ?
                addControlStates(child.props.children, k, values) :
                undefined;

            return childzFurtherChildren ?
                cloneElement(child, props, childzFurtherChildren) :
                cloneElement(child, props);
        });
    };

    return (
        <List sx={{borderBottom: "1px solid #00000050"}}>
            <input hidden required={required} name={name} value={Object.entries(fItems).map(([, v]) => v).filter((v) => v).join(separator)}/>
            <ListItem sx={{padding: 0}}>
                <ListItemText
                    primary={<Typography color={error ? "error" : "inherit"} variant={header ? "h6" : "subtitle1"}>{label}&nbsp;{required ? <sup>*</sup> : ""}</Typography>}
                    secondary={subtitle}
                    sx={{paddingRight: "70px"}}
                />
                <ListItemSecondaryAction>
                    <IconButtonPop responsive={false} title="Add item" onClick={() => setFItems((i) => ({...i, [getRandomNumber(0, 1000)]: ""}))}><AddCircleOutline color="primary"/></IconButtonPop>
                </ListItemSecondaryAction>
            </ListItem>
            {Object.entries(fItems)?.map(([k, v]) =>
                <ListItem key={k} sx={{padding: 0}}>
                    <ListItemText sx={{paddingRight: "70px"}}>
                        {multipleInputs ?
                            <Stack spacing={0}>
                                {addControlStates(children, k, v)}
                                <Divider/>
                            </Stack> :
                            addControlStates(children, k, v)}
                    </ListItemText>
                    <ListItemSecondaryAction>
                        <IconButtonPop title="Remove" responsive={false} onClick={() => setFItems((i) => Object.fromEntries(Object.entries(i).filter(([key]) => key !== k)))}><RemoveCircleOutline/></IconButtonPop>
                    </ListItemSecondaryAction>
                </ListItem>
            )}
        </List>
    );
}
// <InputGroup onBlur={checkError} value={v} placeholder={"Start typing..."} onChange={(e:ChangeEvent<HTMLInputElement>) => setFItems((i) => ({...i, [k]: e.target.value}))}/>
