import {Alert, AlertColor, Box, Button, Checkbox, Collapse} from "@mui/material";
import {QueryConstraint, collection, getDocs, query, where} from "firebase/firestore";
import {QueryObject, UserData, db, useExecuteCallableJob} from "placementt-core";
import {useContext, useState} from "react";
import {OrganisationContext, UserContext} from "../../../../../App";
import Preloader from "../../../../../Components/Preloader";
import {Popup} from "../../../../../Components/Popup";

type Params = {
    onToggle: (active: boolean) => void,
    filters?: {[key:string]: unknown},
    active: boolean,
    users: QueryObject[] | {[key: string]: UserData},
    userType: "Staff"|"Students",
    cohortId?: string
};

export default function ActivateUsersPopup({onToggle, filters, active, users, userType, cohortId}: Params) {
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [error, setError] = useState("");
    const [errorCode, setErrorCode] = useState<AlertColor>("error");
    const [preloader, setPreloader] = useState(false);
    const [inputKey, setInputKey] = useState((Math.random() + 1).toString(36).substring(7));

    const cohorts = useContext(OrganisationContext).cohorts;
    console.log("ORG", useContext(OrganisationContext));
    const user = useContext(UserContext);
    const {execute} = useExecuteCallableJob({user: user});

    // Remove all users already active from list.
    const handleUserActivation = async () => {
        let formattedUsers:{[key:string]: UserData} = {};

        if (Array.isArray(users)) {
            await Promise.all(users.map( async (queryObject) => {
                const constraints:QueryConstraint[] = [where("product", "==", user.product), where("oId", "==", user.oId)];
                queryObject.where && queryObject.where.forEach((w) => {
                    constraints.push(where(...w));
                });
                filters && Object.entries(filters).forEach(([field, value]) => {
                    constraints.push(where(field, "==", value));
                });
                console.log("q", queryObject);
                const q = query(collection(db, queryObject.path.join("/")), ...(constraints));

                const docs = await getDocs(q);

                docs.docs.forEach((doc) => {
                    const docData = doc.data() as UserData;
                    formattedUsers[doc.id] = docData;
                });
            }));
        } else {
            formattedUsers = {...users};
        }


        const e = Object.entries(formattedUsers).map(([, user]) => {
            console.log(user);
            console.log(cohorts[user.cohort as string]);
            console.log(cohorts[user.cohort as string]?.workflow);
            if (!user.userGroup) {
                setError("Cannot activate users without a user group. Create a user group and assign it to your users.");
                setErrorCode("error");
                return false;
            }
            if (userType === "Students" && !user.cohort) {
                setError("Cannot activate users without a cohort. Create a user group and assign it to your users.");
                setErrorCode("error");
                return false;
            }
            if (userType === "Students" && !cohorts[user.cohort as string]?.workflow.length) {
                setError("All users must have a workflow before activating.");
                setErrorCode("error");
                return false;
            }
            setError("");
            return true;
        });

        if (e.includes(false)) {
            return;
        }

        setPreloader(true);
        execute("userManagement-activateUsers", {
            users: users, filters: filters, userType: userType, cohort: cohortId,
        });
        setPreloader(false);
        onToggle(false);
        setInputKey((Math.random() + 1).toString(36).substring(7));
    };

    return (
        <Popup title={"Activate user account(s)"} open={active} key={inputKey} onClose={() => {
            onToggle(false); setSubmitDisabled(true); setError("");
        }}>
            <p>
            In activating these user account(s), you grant them access to the Placementt platform.
            </p>
            <p>
                {userType} will be sent an activation email to reset their password and enter the platform.
            You may alter permissions of users in the 'Users' section.
            </p>
            <Collapse in={Boolean(error)}>
                <Alert severity={errorCode}>{error}</Alert>
            </Collapse>
            <Box m={"auto"}>
                <Checkbox checked={!submitDisabled} onClick={() => setSubmitDisabled((prev) => !prev)}/>
                <Button disabled={submitDisabled} onClick={handleUserActivation}>Activate!</Button>
            </Box>
            <Preloader visible={preloader}/>
        </Popup>
    );
}
