import {Grid, List, Stack, Typography} from "@mui/material";
import {CustomFormSchema, CustomFormSchemaSection, auth, camelCase} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {cloneElement, isValidElement, useEffect, useRef, useState} from "react";
import {To, useLocation, useNavigate} from "react-router-dom";
import Form from "./Form";
import {renderFieldJSON} from "./CustomFormBuilder";
import Card from "./Card";
import Page from "./Page";

type Params = {
    schema?: CustomFormSchema|string[],
    destination?: string[],
    name?: string,
    formData?: {[key: string]: unknown},
    redirect?: string,
    noUpdate?: boolean,
    noNavigate?: boolean,
    onComplete?: (e: {[key: string]: unknown}) => Promise<void>,
    autosave?: boolean,
    embedded?: boolean,
    disabled?: boolean,
    readOnly?: boolean
}

export default function CustomFormPage(props: Params) {
    const location = useLocation();
    const navigate = useNavigate();
    const formRef = useRef();

    const [mSchema, setMSchema] = useState<CustomFormSchema>();
    const firebaseQuery = new FirebaseQuery();


    const {schema, destination, name, formData, redirect}:Params = Object.keys(props).length > 0 ? props : location.state;


    if (!schema) {
        return (
            <Typography>Cannot find form.</Typography>
        );
    }

    useEffect(() => {
        if (!schema) return;
        if (!Array.isArray(schema)) {
            setMSchema(schema);
            return;
        }

        firebaseQuery.getDocData(schema).then((data) => {
            setMSchema(data);
        });
    }, [schema]);


    const onSubmit = async (data: {[key: string]: unknown}) => {
        if (!name) throw new Error("CustomFormPage missing parameters");
        data[`${name}Uid`] = auth?.currentUser?.uid || "public";
        data["completed"] = (new Date()).toISOString();

        !props?.noUpdate && destination && await firebaseQuery.update([...destination], {[name]: data});
        !props?.noNavigate && redirect && navigate((redirect || -1) as To);
        props?.onComplete && await props?.onComplete({[name]: data});
    };

    const editable = (props.disabled || props.readOnly) ? false : Boolean(props.onComplete) || Boolean(destination && name);

    const customForm = mSchema ?
        <Form ref={formRef} button={editable} readOnly={!editable} successText={props.autosave ? "Form saved" : "Form submitted"} successDismissible={props.autosave} onSubmit={async (e) => await onSubmit(e)} sx={{width: "100%", margin: "20px auto"}}>
            <List onBlur={() => props.autosave && (formRef.current as {submitForm: () => void}|undefined)?.submitForm()}>
                {mSchema.form && mSchema.form.map((section: CustomFormSchemaSection) => {
                    console.log("Section", section);
                    const item =
                        <Grid container>
                            {section.fields && section.fields.map((field) => {
                                const name = camelCase(field.label);
                                const value = formData ? formData[name] : undefined;
                                return isValidElement(renderFieldJSON[field.type]) && cloneElement(renderFieldJSON[field.type], {field: field, value: value});
                            })}
                        </Grid>;

                    return props.embedded ? <Stack><Typography variant="h6">{section.title}</Typography>{item}</Stack> : <Card title={section.title}>{item}</Card>;
                })}
            </List>
        </Form> : null;

    if (props.embedded) return customForm;

    return (
        <Page back={redirect} title={mSchema?.name} subtitle={mSchema?.description}>
            {customForm}
        </Page>
    );
}
