import {Button, Grid, Stack, Typography} from "@mui/material";
import {ChangeEvent, useContext, useState} from "react";
import InputGroup from "../../../../Components/FormComponents/InputGroup";
import {camelCaseToNormal, getRandomNumber, snakeCase} from "placementt-core";
import IconButtonPop from "../../../../Components/IconButtonPop";
import {RemoveCircleOutline} from "@mui/icons-material";
import {UserContext} from "../../../../App";

type Params = {
    existingFields: string[],
    data: {details:{[key:string]:unknown}}
}

export function UserForm({existingFields=[], data={details: {}}}:Params) {
    const [additionalFields, setAdditionalFields] = useState<{[key:string]:{name?:string, value?:string}}>({});

    const user = useContext(UserContext);

    return (
        <Stack alignContent={"center"} textAlign={"center"} spacing={2}>
            <Typography variant='h6'>Enter user details</Typography>
            {existingFields.map((name) =>
                <InputGroup name={name} label={camelCaseToNormal(name)} type={name.toLowerCase().includes("email") ? "email" : name.toLowerCase().includes("year") ? "number" : "string"} value={data.details[name]} required={["forename", "surname", "email", "year"].includes(name)}/>
            )}

            <Grid container sx={{marginLeft: "-16px !important"}}>
                {Object.entries(additionalFields).map(([key, field]) => {
                    return (
                        <>
                            <Grid item xs={5}><InputGroup required name={""} defaultValue={field.name ? data.details[field.name] : ""} value={field.name} onChange={(e:ChangeEvent<HTMLInputElement>) => setAdditionalFields((f) => ({...f, [key]: {...field, name: e.target.value}}))} label='Field' placeholder={"e.g. Class"}/></Grid>
                            <Grid item xs={5}><InputGroup required name={snakeCase(field.name)} defaultValue={field.name ? data.details[field.name] : ""} value={field.value} onChange={(e:ChangeEvent<HTMLInputElement>) => setAdditionalFields((f) => ({...f, [key]: {...field, value: e.target.value}}))} label='Value'/></Grid>
                            <Grid item xs={2}><IconButtonPop responsive={false} title={"Delete"} onClick={() => setAdditionalFields((f) => Object.fromEntries(Object.entries(f).filter(([k]) => k !== key)))}><RemoveCircleOutline/></IconButtonPop></Grid>
                        </>
                    );
                })}
            </Grid>
            {user.userGroup === "admin" && <Button onClick={() => setAdditionalFields((f) => ({...f, [`${f.length}_${getRandomNumber(0, 1000)}`]: {}}))}>Add field</Button>}
        </Stack>
    );
}
