import {Icon, List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from "@mui/material";
import FadeInBox from "../../../Components/FadeInBox";
import {DecorativeText, GradientText} from "../../../Util/styledComponents";
import {PlacementReviewDetails, convertDate} from "placementt-core";
import IconButtonPop from "../../../Components/IconButtonPop";
import {ArrowForward, CheckCircleOutline} from "@mui/icons-material";
import {useEffect, useState} from "react";
import UploadProviderDetails from "./UploadProviderDetails";
import UploadProviderInsurance from "./UploadProviderInsurance";
import UploadInstituteForms from "./UploadInstituteForms";
import UploadPlacementQuestions from "./UploadPlacementQuestions";

type Params = {
    visible: boolean
    uid: string,
    pId: string,
    placementKey: string,
    onComplete: (outcome: "submitted"|"reject"|"completeProviderReview") => any,
    placement: PlacementReviewDetails
}

export default function ProviderReviewStudentPlacement({visible, uid, pId, placementKey, onComplete, placement}:Params) {
    const [page, setPage] = useState<"overview"|"insurance"|"riskAssessment"|"dbsCheck"|"forms"|"details"|"questions">("overview");
    const [completedSections, setCompletedSections] = useState<{details?: boolean, eli?: boolean, forms?: boolean, questions?: boolean, riskAssessment?: boolean, dbsCheck?: boolean}>({});
    const [formData, setFormData] = useState(placement);

    useEffect(() => {
        setFormData((prev) => ({...placement, ...prev}));

        setCompletedSections(Object.fromEntries(placement.requiredSections.map((section) => [section, Boolean(placement.providerCompleted && placement.providerCompleted.includes(section))])));
    }, [placement]);

    const onCompleteSection = (section: "details"|"eli"|"forms"|"questions"|"riskAssessment"|"dbsCheck", data?: {[key:string]: unknown}) => {
        setFormData((c) => ({...c, ...data}));

        if (section === "details" && data) {
            const detailData = data as {completedSections?: string[]};
            console.log("DET", detailData.completedSections);
            setCompletedSections((p) => ({...p, ...(detailData.completedSections ? Object.fromEntries(detailData?.completedSections.map((v) => [v, Boolean(v)])) : [])}));
        } else {
            setCompletedSections((p) => ({...p, [section]: true}));
        }
        if (section === "questions") {
            setPage("overview");
            onComplete(data?.mapConsent ? "completeProviderReview" : "submitted");
            return;
        }
        if (section === "riskAssessment" || section === "dbsCheck") return;
        setPage("overview");
    };

    return (
        <>
            <FadeInBox sx={{textAlign: "center"}} card visible={visible && page === "overview"} cardTitle={<GradientText variant={"h4"}>Review placement</GradientText>}>
                <Typography variant='h5'><DecorativeText color={"#2b66bf"}>{placement.studentForename} {placement.studentSurname}</DecorativeText>{placement.instituteName && <> from <DecorativeText color={"#2fbbab"}>{placement.instituteName}</DecorativeText></>}</Typography>
                <Typography variant="h6">{convertDate(placement.startDate, "visual") as string} - {convertDate(placement.endDate, "visual") as string}</Typography>
                <Typography>Complete the following details to verify this placement.</Typography>
                <Typography>We can save these details for you, so you don't have to repeat yourself.</Typography>
                <Typography variant='h6' width={"100%"} textAlign={"start"}>To do:</Typography>
                <List sx={{width: "100%"}}>
                    {Object.keys(completedSections).includes("details") && <ListItem divider>
                        <ListItemText primary={"Review your details"} />
                        <ListItemSecondaryAction>
                            {completedSections?.details ? <Icon color='success' sx={{marginRight: "8px"}}><CheckCircleOutline/></Icon> :
                                <IconButtonPop onClick={() => setPage("details")} responsive={false} title={"Complete step"}>
                                    <ArrowForward />
                                </IconButtonPop>}
                        </ListItemSecondaryAction>
                    </ListItem>}
                    {placement.requireELI && Object.keys(completedSections).includes("eli") && <ListItem divider>
                        <ListItemText primary={"Submit employer's liability insurance"} />
                        <ListItemSecondaryAction>
                            {completedSections?.eli ? <Icon color='success' sx={{marginRight: "8px"}}><CheckCircleOutline/></Icon> :
                                <IconButtonPop disabled={!completedSections?.details} responsive={false} onClick={() => setPage("insurance")} title={"Complete step"}>
                                    <ArrowForward />
                                </IconButtonPop>}
                        </ListItemSecondaryAction>
                    </ListItem>}
                    {false && placement.requireRA && Object.keys(completedSections).includes("riskAssessment") && <ListItem divider>
                        <ListItemText primary={"Submit a risk assessment"} />
                        <ListItemSecondaryAction>
                            {completedSections?.riskAssessment ? <Icon color='success' sx={{marginRight: "8px"}}><CheckCircleOutline/></Icon> :
                                <IconButtonPop disabled={!completedSections?.details} responsive={false} onClick={() => setPage("riskAssessment")} title={"Complete step"}>
                                    <ArrowForward />
                                </IconButtonPop>}
                        </ListItemSecondaryAction>
                    </ListItem>}
                    {Object.keys(completedSections).includes("forms") && (placement.currentStage?.forms || placement.currentStage?.files) && <ListItem divider>
                        <ListItemText primary={"Complete institute forms"} />
                        <ListItemSecondaryAction>
                            {completedSections?.forms ? <Icon color='success' sx={{marginRight: "8px"}}><CheckCircleOutline/></Icon> :
                                <IconButtonPop disabled={!completedSections?.details} responsive={false} onClick={() => setPage("forms")} title={"Complete step"}>
                                    <ArrowForward />
                                </IconButtonPop>}
                        </ListItemSecondaryAction>
                    </ListItem>}
                    {Object.keys(completedSections).includes("questions") && <ListItem divider>
                        <ListItemText primary={"Provide placement details"} />
                        <ListItemSecondaryAction>
                            {completedSections?.questions ? <Icon color='success' sx={{marginRight: "8px"}}><CheckCircleOutline/></Icon> :
                                <IconButtonPop disabled={!completedSections?.details || (placement.requiredSections.includes("eli") && !completedSections.eli) || (placement.requiredSections.includes("forms") && !completedSections.forms)} responsive={false} onClick={() => setPage("questions")} title={"Complete step"}>
                                    <ArrowForward />
                                </IconButtonPop>}
                        </ListItemSecondaryAction>
                    </ListItem>}
                </List>
                <Typography color={"gray"} sx={{cursor: "pointer"}} onClick={() => onComplete("reject")}>Not your placement?</Typography>
            </FadeInBox>
            <UploadProviderDetails {...{uid, pId, placementKey}} placement={formData} visible={page === "details"} back={() => setPage("overview")} onComplete={(e) => onCompleteSection("details", e)}/>
            <UploadProviderInsurance {...{pId}} providerId={formData.providerId} visible={page === "insurance"} back={() => setPage("overview")} onComplete={() => onCompleteSection("eli")}/>
            <UploadInstituteForms {...{uid, pId, placementKey}} placement={formData} visible={page === "forms"} back={() => setPage("overview")} onComplete={(e) => onCompleteSection("forms", e)}/>
            <UploadPlacementQuestions {...{uid, pId, completedSections, onCompleteSection}} placement={formData} visible={page === "questions"} back={() => setPage("overview")} onComplete={(e) => onCompleteSection("questions", e)}/>
        </>
    );
}
