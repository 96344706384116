import {Button, Card, CardContent, CardHeader, Grid, Stack, Typography} from "@mui/material";
import {ButtonPropsColorOverrides} from "@mui/material/Button/Button";
import {OverridableStringUnion} from "@mui/types";
import {ReactNode} from "react";


type CardParams = {
    title: string,
    primaryColor: string,
    summary: ReactNode,
    secondaryContent?: ReactNode,
    itemWidth?: number,
    button?: {
        label: string,
        onClick: () => void,
        color?: OverridableStringUnion<"inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning", ButtonPropsColorOverrides>,
    }
};

type Params = {
    title?: string,
    items?: CardParams[],
    itemWidth?: number,
    titleColour?: string
};

export default function CardSection({title, items, titleColour="primary", itemWidth=Math.min(Math.ceil(12 / (items?.length || 1)), 4)}: Params) {
    return (
        <Stack>
            {title && <Typography textAlign={"start"} color={titleColour} variant="h5">{title}</Typography>}
            <Grid container maxWidth={"1000px"}>
                {items?.map((item) => <CardItem {...{...item, itemWidth}}/>)}
            </Grid>
        </Stack>
    );
}

function CardItem({title, primaryColor, button, summary, itemWidth, secondaryContent}: CardParams) {
    return (
        <Grid item xs={12} md={itemWidth}>
            <Card sx={primaryColor ? {borderLeft: `5px solid ${primaryColor}`} : undefined}>
                <CardHeader title={title} sx={{zIndex: 5, color: primaryColor || undefined}}/>
                <CardContent>
                    <Stack>
                        <Typography>{summary}</Typography>
                        {secondaryContent}
                        {button && <Button onClick={button.onClick} variant="contained" color={button.color}>Learn more</Button>}
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    );
}
