import {INSTITUTE_COLOUR} from "placementt-core";
import InfoPageHeader from "../../Components/Public/InfoPageHeader";
import GatsbyBenchmarks from "placementt-core/lib/images/GatsbyBenchmarks";
import {List, ListItem, ListItemIcon, ListItemText, Typography, Box} from "@mui/material";
import TwoColumnSection from "../../Components/Public/TwoColumnSection";
import ThreeColumnSection from "../../Components/Public/ThreeColumnSection/ThreeColumnSection";
import IconGridList from "../../Components/Public/IconGridList";
import {AccountTreeRounded, Analytics, AnalyticsRounded, AssignmentTurnedInRounded, BadgeRounded, CheckCircleRounded, DocumentScannerRounded, GroupsRounded, LightbulbRounded, PeopleRounded, SchoolRounded, ShieldRounded, SmsRounded} from "@mui/icons-material";
import CardSection from "../../Components/Public/CardSection";
import PublicTemplate from "../../Components/Public/PublicTemplate";
import Page from "../../Components/Page";
import EpsomEwell from "../../Images/TestimonialsLogos/EpsomEwell.webp";
import HallCrossAcademy from "../../Images/TestimonialsLogos/HallCrossAcademy.webp";
import KestevenGrantham from "../../Images/TestimonialsLogos/KestevenGrantham.webp";


export default function InstituteInfo() {
    return (
        <Page
            metaTitle="Placementt | Institute"
            metaDesc="A platform for schools, colleges
            and universities to seamlessly manage work experience. Manage
            students and applicant work experience through a customisable workflow, track progress and communicate easily with your students."
        >
            <InfoPageHeader
                title="Placementt - Institute"
                subtext="Manage your entire placement programme seamlessly. Whether you are a secondary school, college or university, Placementt will support you with a suite of robust, hands-free tools."
                background={INSTITUTE_COLOUR}
                image={<GatsbyBenchmarks/>}
                scaleBackground
            />
            <PublicTemplate>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <IconGridList
                        title="Core features to streamline your placements, from secondary school to university"
                        titleColour={INSTITUTE_COLOUR}
                        items={[
                            {
                                title: "Customisable placement workflow",
                                icon: <AccountTreeRounded/>,
                            },
                            {
                                title: "Comprehensive user analytics",
                                icon: <Analytics/>,
                            },
                            {
                                title: "Robust communication tools",
                                icon: <SmsRounded/>,
                            },
                            {
                                title: "Simple, secure user management",
                                icon: <PeopleRounded/>,
                            },
                        ]}
                    />
                </Box>
                <TwoColumnSection
                    title="Bring out the best in your students"
                    titleColor={INSTITUTE_COLOUR}
                    sectionOne="Our platform doesn't just reduce your administrative workload, it supports students directly by building up comprehensive profiles of work placements. This means that your students have access to placements that will maximise their career prospects."
                    sectionTwo="Placementt will provide you a strong foundation for your placement programme, allowing you to easily support students and maximise their development. "
                />
                <Testimonials color={INSTITUTE_COLOUR}/>
                <CardSection
                    title="Your complete placement platform"
                    titleColour={INSTITUTE_COLOUR}
                    items={[
                        {
                            title: "For schools",
                            primaryColor: INSTITUTE_COLOUR,
                            summary: <><Typography fontWeight={"bold"} color={INSTITUTE_COLOUR} display={"inline"}>Manage your work experience programme</Typography> with ease. Our placement management platform will reduce admin and give you time to spend on your students.</>,
                            secondaryContent:
                                <List>
                                    <ListItem>
                                        <ListItemIcon><AccountTreeRounded/></ListItemIcon>
                                        <ListItemText primary="Customisable workflow"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><DocumentScannerRounded/></ListItemIcon>
                                        <ListItemText primary="Automatic insurance and risk assessment acquisition"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><SmsRounded/></ListItemIcon>
                                        <ListItemText primary="Robust communication tools"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><AnalyticsRounded/></ListItemIcon>
                                        <ListItemText primary="Basic placement analytics"/>
                                    </ListItem>
                                </List>,
                        },
                        {
                            title: "For colleges",
                            primaryColor: INSTITUTE_COLOUR,
                            summary: <><Typography fontWeight={"bold"} color={INSTITUTE_COLOUR} display={"inline"}>Maximise student options</Typography> with robust skills development support and advanced analytics.</>,
                            secondaryContent:
                            <List>
                                <ListItem>
                                    <ListItemIcon><CheckCircleRounded/></ListItemIcon>
                                    <ListItemText primary="All school features"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><LightbulbRounded/></ListItemIcon>
                                    <ListItemText primary="Skills development"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><AssignmentTurnedInRounded/></ListItemIcon>
                                    <ListItemText primary="Verified placement portfolio"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><GroupsRounded/></ListItemIcon>
                                    <ListItemText primary="Cohort-wide advanced analytics"/>
                                </ListItem>
                            </List>,
                        },
                        {
                            title: "For universities",
                            primaryColor: INSTITUTE_COLOUR,
                            summary: <><Typography fontWeight={"bold"} color={INSTITUTE_COLOUR} display={"inline"}>Develop exceptional young professionals</Typography> with an advanced, secure platform. Expose your students to vibrant opportunities in any sector.</>,
                            secondaryContent:
                            <List>
                                <ListItem>
                                    <ListItemIcon><CheckCircleRounded/></ListItemIcon>
                                    <ListItemText primary="All college features"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><SchoolRounded/></ListItemIcon>
                                    <ListItemText primary="Personal student analytics"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><ShieldRounded/></ListItemIcon>
                                    <ListItemText primary="Secure SAML authentication"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><BadgeRounded/></ListItemIcon>
                                    <ListItemText primary="Business headhunting"/>
                                </ListItem>
                            </List>,
                        },
                    ]}
                />
            </PublicTemplate>
        </Page>
    );
}


export function Testimonials({color}:{color: string}) {
    return (
        <ThreeColumnSection
            title="Our Testimonials"
            titleColor={color}
            sectionOne={{
                color: "#8b0000",
                image: HallCrossAcademy,
                imageTitle: "Hall Cross Academy",
                text: <Box>
                    <Typography>
                    "The platform has really made communication between the placement, students and school so much more efficient, reducing time spent on administration. We look forward to continuing to work with Placementt in the future to further develop our work experience programme."
                    </Typography>
                    <Typography mt={2} textAlign={"end"} color={"#8b0000"} fontWeight={"bold"}>Mrs D Stokes</Typography>
                    <Typography textAlign={"end"} color={"#8b0000"} fontWeight={"bold"}>Sixth Form Year Manager</Typography>
                </Box>,
            }}
            sectionTwo={{
                color: "#006400",
                image: EpsomEwell,
                imageTitle: "Epsom and Ewell High School",
                text: <Box>
                    <Typography>
                    "Placementt… have been thorough in their approach to making the journey for recording work experience as smooth as possible. They have been on hand to answer any questions… or adapt the process to make things easier for us. The best thing about this product is that the culture of the team behind it is all about making things as efficient as possible for us as users; we will certainly be working with them in the future!"
                    </Typography>
                    <Typography mt={2} textAlign={"end"} color={"#006400"} fontWeight={"bold"}>Miss H Cobbold</Typography>
                    <Typography textAlign={"end"} color={"#006400"} fontWeight={"bold"}>Assistant Headteacher</Typography>
                </Box>,
            }}
            sectionThree={{
                color: "#0080FE",
                image: KestevenGrantham,
                imageTitle: "Kesteven and Grantham Girls' School",
                text: <Box>
                    <Typography>
                    “Placementt has really simplified and streamlined the administration process related to work experience for us, so is an invaluable tool. We are also assured that it will help us develop a comprehensive database of employers who can accommodate our students in future. Most impressive was how reactive the team at Placementt were to our queries.”
                    </Typography>
                    <Typography mt={2} textAlign={"end"} color={"#0080FE"} fontWeight={"bold"}>Assistant Headteacher</Typography>
                </Box>,
            }}
        />
    );
}
