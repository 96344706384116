/* eslint-disable no-irregular-whitespace */
import {MouseEvent, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import isHotkey from "is-hotkey";
import {Editable, withReact, useSlate, Slate, ReactEditor} from "slate-react";
import {
    BaseEditor,
    Descendant,
    Editor,
    Transforms,
    createEditor,
} from "slate";
import {withHistory} from "slate-history";
import {Stack, styled, Link, Box, Grid, Typography} from "@mui/material";
import {Add, AddLink, CheckCircleRounded, FormatBold, FormatItalic, FormatUnderlined, SaveRounded} from "@mui/icons-material";
import IconButtonPop from "./IconButtonPop";
import Preloader from "./Preloader";
import {Popup} from "./Popup";
import Form from "./Form";
import InputGroup from "./FormComponents/InputGroup";
import {camelCaseToNormal, ERRORBACKGROUNDCOLOR, ERRORTEXTCOLOR, PRIMARY_COLOUR} from "placementt-core";
import Card from "./Card";


const HOTKEYS = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+`": "code",
};

const paramsRegex = new RegExp(/\{\{\w+\}\}/, "g");

type Params = {
    initialValue?: Descendant[],
    onChange?: ((value: Descendant[]) => void),
    onSave?: (text: Descendant[]) => Promise<void>,
    readOnly?: boolean,
    name?: string,
    params: string[] // New prop for the list of items,
    button?: {text: string, link: string, required?: boolean}
}

export default function SlateWithParamsEditor({
    initialValue = defaultText,
    button,
    /* onChange, */
    onSave,
    readOnly,
    name,
    params,
}: Params) {
    const renderElement = useCallback((props: any) => {
        return (<Element {...props} />);
    }, []);
    const renderLeaf = useCallback((props: any) => <Leaf {...props} {...{button, params}}/>, []);
    const editor = useMemo(() => withHistory(withReact(createEditor())), []);
    const [textContent, setTextContent] = useState<Descendant[]>(initialValue);
    const [saving, setSaving] = useState<"saving" | "unsaved" | "saved" | undefined>();
    const [linkPopupOpen, setLinkPopupOpen] = useState(false);

    useEffect(() => {
        if (initialValue === textContent) return;
        setTextContent(initialValue);
        Transforms.delete(editor, {
            at: {
                anchor: Editor.start(editor, []),
                focus: Editor.end(editor, []),
            },
        });

        // Removes empty node
        Transforms.removeNodes(editor, {
            at: [0],
        });

        // Insert array of children nodes
        Transforms.insertNodes(editor, initialValue);
    }, [initialValue]);

    useEffect(() => {
        if (saving === "unsaved") {
            window.onbeforeunload = function() {
                return "All unsaved data will be lost. Are you sure?";
            };
            return;
        }
        window.onbeforeunload = null;
    }, [saving]);

    const handleInsertLink = (editor: ReactEditor, text: string, url: string) => {
        let fUrl = url;
        if (!fUrl.includes("https://") && !fUrl.includes("http://")) {
            fUrl = "https://" + fUrl;
        }
        try {
            new URL(fUrl);
        } catch (_) {
            throw new Error("Not formatted correctly.");
        }
        setLinkPopupOpen(false);
        insertLink(editor, text, fUrl); // will be implemented later
    };

    const handleInsertItem = (item: string) => {
        Transforms.insertNodes(editor, {
            text: `{{${item}}}`,
        });
    };

    const checkForParams = (content: Descendant[]) => {
        // console.log("INPUT", content);
        const output = content.map((item) => {
            const children = (item as {children: unknown}).children as {text: string, [key: string]: unknown}[];

            let newChildren = children.flatMap((child, index) => {
                if ((/\{\{\w+\}\}/).test(child.text)) {
                    const text = splitText(child.text);
                    if (text.length === 1) {
                        const ret: any[] = [
                            {
                                ...child,
                                text: text[0],
                                param: true,
                            }];
                        if (!children[index+1]) {
                            const newChild = {...child};
                            delete newChild.param;
                            ret.push({
                                ...newChild,
                                text: "​", // Adds a zero width space, this string is not empty!
                            });
                        }
                        return ret;
                    }
                    return text.map((string) => {
                        if ((/\{\{\w+\}\}/).test(string)) {
                            return {
                                ...child,
                                text: string,
                                param: true,
                            };
                        }
                        return {
                            ...child,
                            text: string,
                        };
                    });
                }
                const newChild = {...child};
                delete newChild.param;
                return newChild;
            });

            newChildren = newChildren.map((child, i) => {
                if (child?.param) return child;
                const next = newChildren?.[i+1] as {text: string, param?: boolean};
                const prev = newChildren?.[i-1] as {text: string, param?: boolean};

                return {
                    ...child,
                    text: (next?.param && !child.text.endsWith("​")) ? `${child.text}​` : (prev?.param && !child.text.startsWith("​")) ? `​${child.text}` : child.text,
                };
            });

            return {
                ...item,
                children: newChildren,
            };
        });
        // console.log("OUTPUT", output);
        return output;
    };

    function splitText(input: string) {
        // Create a RegExp object for matching and splitting

        // Find all matches
        const matches = [...input.matchAll(paramsRegex)];

        // Split the input string by the regex pattern
        const parts = input.split(paramsRegex);

        // Combine matches and parts
        const result = [];
        let i = 0; // Index for parts
        for (const match of matches) {
            if (i < parts.length) {
                result.push(parts[i]); // Push the part before the match
            }
            result.push(match[0]); // Push the match itself
            i++;
        }
        // Push any remaining part after the last match
        if (i < parts.length) {
            result.push(parts[i]);
        }

        return result.filter((a) => a);
    }
    const setEditorContent = (newContent: Descendant[]) => {
        // Save the current cursor position (selection)
        const {selection} = editor;
        const savedSelection = selection ? {anchor: {...selection.anchor}, focus: {...selection.focus}} : null;

        // Move cursor to the start of the document and clear content
        Transforms.select(editor, Editor.start(editor, []));
        editor.children.map(() => {
            Transforms.delete(editor, {at: [0]});
        });

        // Insert the new content
        // console.log("NEW CONTENT", newContent);
        Transforms.insertNodes(editor, newContent);

        // Try to restore the previous cursor position
        if (savedSelection) {
            try {
                const anchorPath = savedSelection.anchor.path;
                const focusPath = savedSelection.focus.path;

                // If the previous cursor position is within the bounds of the new content, restore it
                if (Editor.hasPath(editor, anchorPath) && Editor.hasPath(editor, focusPath)) {
                    Transforms.select(editor, savedSelection);
                }
            } catch (error) {
                console.log("Couldn't restore the cursor to the previous position:", error);
            }
        }
    };

    // console.log("TEXT CONTENT", textContent);
    return (
        <Grid container spacing={2}>
            <Card grid xs={8} sx={{height: "100%"}} title={"Email content"}>
                <Box height={"100%"} paddingBottom={"60px"}>
                    {name && <input name={name} hidden readOnly value={JSON.stringify(textContent)} />}
                    <Slate editor={editor} value={textContent} onChange={(v) => {
                        setSaving("unsaved");

                        // console.log("FORMATTING", v);
                        const formattedText = checkForParams(v);

                        // console.log("FORMATTED", formattedText);

                        // console.log("UPDATING");

                        setTextContent(formattedText);
                        // onChange && onChange(formattedText);

                        if (JSON.stringify(formattedText) === JSON.stringify(v) || JSON.stringify(formattedText) === JSON.stringify(textContent)) return;

                        setEditorContent(formattedText as Descendant[]);
                    }}>
                        <Editable
                            {...{renderElement, renderLeaf}}
                            onBlur={() => {
                                if (saving !== "unsaved" || !onSave) return;
                                setSaving("saving");
                                onSave(textContent).then(() => setSaving("saved"));
                            }}
                            readOnly={readOnly}
                            placeholder={readOnly ? "Nothing to see here..." : "Start typing…"}
                            spellCheck
                            style={{overflowY: "auto", height: "100%", overflowX: "hidden", maxHeight: "max-content", border: "1px solid lightgrey", borderRadius: "5px", paddingLeft: 5}}
                            onKeyDown={(event) => {
                                for (const hotkey in HOTKEYS) {
                                    if (isHotkey(hotkey, event)) {
                                        event.preventDefault();
                                        const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS];
                                        toggleMark(editor, mark);
                                    }
                                }
                            }}
                        />
                        <Stack direction={"row"}>
                            <Toolbar>
                                <MarkButton format="bold" icon={<FormatBold />} />
                                <MarkButton format="italic" icon={<FormatItalic />} />
                                <MarkButton format="underline" icon={<FormatUnderlined />} />
                                <IconButtonPop sx={{marginTop: "-16px"}} title="Add link" onClick={() => setLinkPopupOpen(true)}><AddLink /></IconButtonPop>
                            </Toolbar>
                            {saving === "unsaved" && onSave && <IconButtonPop onClick={() => {
                                setSaving("saving");
                                onSave(textContent).then(() => setSaving("saved"));
                            }} title={"Save"}><SaveRounded color="primary" /></IconButtonPop>}
                            {saving === "saving" && <Preloader visible />}
                            {saving === "saved" && <IconButtonPop responsive={false} title="Saved"><CheckCircleRounded color="success" /></IconButtonPop>}
                        </Stack>
                    </Slate>
                    <Popup cardSx={{width: "400px", maxWidth: "90vw"}} open={linkPopupOpen} title={"Insert web link"} onClose={() => setLinkPopupOpen(false)}>
                        <Form functionType="sync" submitText="Insert" onSubmit={(e) => handleInsertLink(editor, (e as { text: string, url: string }).text, (e as { text: string, url: string }).url)}>
                            <InputGroup label="Link text" name="text" required placeholder="Click link to view." />
                            <InputGroup label="Link url" name="url" required placeholder="https://" />
                        </Form>
                    </Popup>
                </Box>
            </Card>
            <Card grid xs={4} title={"Personalisations"}>
                <Typography variant="subtitle2" sx={{opacity: 0.7}}>Add these into your email and we will replace with corresponding information from the placement.</Typography>
                <Stack spacing={1} padding={2}>
                    {[button, ...params].map((param) => (
                        <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            key={typeof param === "object" ? "button" : param}
                            onClick={() => handleInsertItem(typeof param === "object" ? "button" : param as string)}
                            sx={{
                                "cursor": "pointer",
                                "padding": 0,
                                "paddingLeft": 1,
                                "border": `${typeof param === "object" ? "3" : "1"}px solid ${typeof param === "object" ? PRIMARY_COLOUR : "lightgrey"}`,
                                "borderRadius": 2,
                                "&:hover": {
                                    backgroundColor: `${PRIMARY_COLOUR}50`,
                                },
                                "transition": "all 250ms ease-in-out",
                            }}>
                            <Typography sx={{fontWeight: typeof param === "object" ? "bold" : undefined}} color={typeof param === "object" ? PRIMARY_COLOUR : undefined}>{camelCaseToNormal(typeof param === "object" ? "button" : param as string)}</Typography>
                            <IconButtonPop responsive={false} onClick={(e) => {
                                e.stopPropagation();
                                handleInsertItem(typeof param === "object" ? "button" : param as string);
                            }} title="Insert"><Add color={typeof param === "object" ? "primary" : undefined}/></IconButtonPop>
                        </Stack>
                    ))}
                </Stack>
            </Card>
        </Grid>
    );
}

const insertLink = (editor: ReactEditor, text: string, url: string | null) => {
    if (!url) return;

    const link = {
        type: "link",
        href: url,
        children: [{text}],
    };

    ReactEditor.focus(editor);

    // Insert the new link node at the bottom of the Editor when selection
    // is falsey
    Transforms.insertNodes(editor, link);

    const para = {type: "paragraph", children: [{text: ""}]};
    Transforms.insertNodes(editor, para);
};

const toggleMark = (editor: BaseEditor, format: string) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

const isMarkActive = (editor: BaseEditor, format: string) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format as keyof typeof marks] === true : false;
};

const Element = ({attributes, element, children}: { attributes: { [key: string]: unknown }, element: { [key: string]: unknown }, children: ReactNode }) => {
    if (element.type === "link") {
        return (
            <Link target="_blank" rel="noreferrer" href={element.href as string}>
                {children}
            </Link>
        );
    }

    return (
        <p {...attributes} style={{lineHeight: 1.6}}>
            {children}
        </p>
    );
};

const Leaf = ({attributes, children, leaf, params, button}: { attributes: { [key: string]: unknown }, children: ReactNode, leaf: { [key: string]: unknown}, params?: string[], button?: {text: string, link: string, required?: boolean}}) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }


    if (leaf.param) {
        const paramName = (leaf.text as string).substring(2, (leaf.text as string).length - 2);
        children = paramName === "button" ? <Box
            component={"span"}
            contentEditable={false}
            sx={{
                fontSize: "0.875rem",
                display: "block",
                lineHeight: 1.75,
                minWidth: "64px",
                color: "rgb(121, 32, 245)",
                width: "max-content",
                height: "max-content",
                borderRadius: "4px",
                transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "rgba(121, 32, 245, 0.5)",
                borderImage: "initial",
                padding: "5px 10px",
            }}
        >{button?.text}<span style={{position: "absolute", opacity: 0}}>{children}</span></Box> : <Box
            component={"span"}
            {...attributes}
            sx={{
                display: "inline-block",
                backgroundColor: params && params.includes(paramName) ? PRIMARY_COLOUR : ERRORBACKGROUNDCOLOR,
                color: `${params && params.includes(paramName) ? "white" : ERRORTEXTCOLOR} !important`,
                borderRadius: 2,
                lineHeight: 1.2,
                padding: "2px 6px",
                margin: "0 2px",
            }}
        >
            {children}
        </Box>;
    }


    return <span {...attributes}>{children}</span>;
};

const MarkButton = ({format, icon}: { format: string, icon: ReactNode }) => {
    const editor = useSlate();
    return (
        <SlateButton
            active={isMarkActive(editor, format)}
            onMouseDown={(event: MouseEvent) => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
        >
            {icon}
        </SlateButton>
    );
};

const defaultText = [
    {
        type: "paragraph",
        children: [
            {
                text: "",
            },
        ],
    },
];

type SlateButtonProps = {
    active?: boolean,
    reversed?: boolean,
}

export const SlateButton = styled("span")<SlateButtonProps>`
    cursor: pointer;
    color: ${(props) => props.reversed ? props.active ? "white" : "#aaa" : props.active ? "black" : "#ccc"};
`;

export const Toolbar = styled("div")`
    padding-top: 10px;
    bottom: 0;
    flex: 1;

    & > * {
        margin-left: 5px;
        margin-right: 5px;
    }
`;
