import InfoPageHeader from "../../Components/Public/InfoPageHeader";
import {STUDENT_COLOUR} from "placementt-core";
import PublicTemplate from "../../Components/Public/PublicTemplate";
import TwoColumnSection from "../../Components/Public/TwoColumnSection";
import IconGridList from "../../Components/Public/IconGridList";
import {Analytics, CampaignRounded, LocationCityRounded, TextSnippetRounded} from "@mui/icons-material";
import Page from "../../Components/Page";

export default function StudentInfo() {
    return (
        <Page
            metaTitle="Placementt | Student"
            metaDesc="For students, by students: Placementt - Student allows you to find and manage work experience that progresses your career."
        >
            <InfoPageHeader
                title="Placementt - Student"
                subtext="Find placements, track progress and develop your skills. Built for students by students, our platform allows you to build the skills you need to enter your ideal career."
                background={STUDENT_COLOUR}
                scaleBackground
            />
            <PublicTemplate>
                <IconGridList
                    title="Take control of your career with a platform that helps shape your dream career."
                    titleColour={STUDENT_COLOUR}
                    items={[
                        {
                            title: "Find placements across a UK-wide database",
                            icon: <LocationCityRounded/>,
                        },
                        {
                            title: "Build your skills and track your progress",
                            icon: <Analytics/>,
                        },
                        {
                            title: "Advtertise yourself to employers",
                            icon: <CampaignRounded/>,
                        },
                        {
                            title: "Get improved support on your placements",
                            icon: <TextSnippetRounded/>,
                        },
                    ]}
                />
                <TwoColumnSection
                    title="Build your skills and boost your employability with quality work experience tailored to you."
                    titleColor={STUDENT_COLOUR}
                    sectionOne="Nobody likes going on a placement just to make cups of tea. Work experience is an integral part of your career development, and we're here to make sure you get the most out of it."
                    sectionTwo="Placementt allows your to search for high quality placements tailored to you, from year 10 work experience to university placements. We help you keep track of your placements, build your skills and bolster your CV so you can work anywhere."
                />
            </PublicTemplate>
        </Page>
    );
}
