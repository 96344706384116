import {ToggleButtonGroup} from "@mui/material";
import {ReactNode, useEffect, useState} from "react";


type Params = {
    defaultValue: string|number|undefined,
    name: string,
    children: ReactNode,
    disabled?: boolean
}

export default function ControlledToggleGroup({defaultValue, name, children, disabled}:Params) {
    const [value, setValue] = useState<string|number|undefined>();

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return (
        <>
            <input readOnly hidden {...{name, value}}/>
            <ToggleButtonGroup
                color="primary"
                {...{value, disabled}}
                exclusive
                onChange={(e, n) => n && setValue(n)}
                aria-label={name}>
                {children}
            </ToggleButtonGroup>
        </>

    );
}
